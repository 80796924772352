import React, { createContext, useState, useEffect } from 'react';

// Create a CartContext to store the cart count and cart items
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartCount, setCartCount] = useState(0);
  const [navDetails, setNavDetails] = useState([]);
  const [searchResultData, setSearchResultData] = useState({});
  const [deliveredAddress, setDeliveredAddress] = useState([]);
  const [attributesList, setAttributeList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [shortBy, setNewShortBy] = useState('product_name');
  const [productString, setproductFilterString] = useState('');
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [cartItems, setCartItems] = useState(() => {
    // Get cart items from localStorage if they exist
    const storedItems = localStorage.getItem('cartItems');
    return storedItems ? JSON.parse(storedItems) : [];
  });

  useEffect(() => {
    setCartCount(cartItems?.length);
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const addToCartCount = (product) => {
    const productWithQuantity = { ...product, quantity: product.quantity || 1 };
    const existingProduct = cartItems.find(item => item.id === productWithQuantity.id);

    if (existingProduct) {
      setCartItems(cartItems.map(item =>
        item.id === productWithQuantity.id ? { ...item, quantity: item.quantity + productWithQuantity.quantity } : item
      ));
    } else {
      setCartItems([...cartItems, productWithQuantity]);
    }
  };

  const removeFromCart = (productId) => {
    const updatedCartItems = cartItems.filter(item => item.id !== productId);
    setCartItems(updatedCartItems);
  };

  const clearCart = () => {
    setCartItems([]);
    setCartCount(0);
    localStorage.removeItem('cartItems');
    localStorage.removeItem('cartCount');
  };

  const searchResultProduct = (products) => {
    // localStorage.setItem('searchResultData', JSON.stringify(products))
    setSearchResultData(products)
  }

  const sideNavigationDetails = (data) => {
    setNavDetails(data);
  }

  const setDeliveryAddress = (data) => {
    setDeliveredAddress(data?.[0])

  }

  const getAttributeList = (data) => {
    setAttributeList(data?.AttributesList);
  }
  const setPageList = (value) => {
    setPage(value);
  }
  const setProductLimit = (value) => {
    setLimit(value);
  }
  const setShortBy = (value) => {
    setNewShortBy(value);
  }
  const setFilters = (value) => {
    setSelectedFilters(value);
  }
  const getFilterString = (value) => {
    setproductFilterString(value);
  }

  return (
    <CartContext.Provider value={{ page, setPageList, setProductLimit, limit, setShortBy, shortBy, setFilters, selectedFilters, getFilterString, productString, cartCount, cartItems, addToCartCount, removeFromCart, clearCart, searchResultProduct, searchResultData, sideNavigationDetails, navDetails, setDeliveryAddress, deliveredAddress, getAttributeList, attributesList }}>
      {children}
    </CartContext.Provider>
  );
};