// const apiURL = process.env.LOCAL_DEVELOPMENT_URL || 'https://davenport.local:8452/api'
const apiURL = process.env.LOCAL_DEVELOPMENT_URL || 'https://admin.davenport-burgess.com/api'
const backendURL = process.env.PUBLIC_BACKEND_URL || 'https://admin.davenport-burgess.com'
const url = process.env.PUBLIC_FRONT_URL || 'https://www.davenport-burgess.com'
const s3BucketImagePath = 'https://davenport-liveimage.s3.amazonaws.com'

const pathObj = {
  baseURL: apiURL,
  frontUrl: url,
  backendURL:backendURL,
  s3ImagePath:s3BucketImagePath,

  // worldpay data
  worldpayInstId: '1012569', 
  worldpayPaymentMode: '0', // 100 for demo and 0 for live
  worldpayPaymentUrl: 'https://secure.worldpay.com/wcc/purchase',
  worldpayPassword: 'D@venport-Burgess_47',

  /* API URLs */
  logIn: '/login',
  logOut: '/logout',
  forgotPassword: '/forgot_password',
  resetPassword: '/reset-password',
  getHeaderInfo: '/headerInfo',
  getFooterInfo: '/footerDetails',
  getHeaderBannerInfo: '/bannerDetails',
  getMegaMenu: '/megaMenus',
  getTopCategories: '/topCategories',
  pageDetail: '/pageDetail',
  accountApplication: '/saveApplicationForm',
  getProductListing: '/productListing',
  getProductDetails: '/productDetail',
  getCategoryDetails: '/getCategoryList',
  getNewsList: '/getNewsList',
  newsCategories : '/newsCategories ',
  newsDetail : '/newsDetail ',
  contactFormSubmit: '/saveContactForm',
  getAlternativeRelatedProducts: '/getRelatedProducts',
  addCart: '/AddCart',
  getCartDetails: '/getCartItems',
  deleteIndividualItem: '/deleteCartItem',
  getCheckoutDetails: '/orderCartItems',
  getRecentPurchsedItems: '/getRecentPurchsedItems',
  saveOrder: '/saveOrder',
  brainTreeCheckout: '/braintreeCheckout',  
  worldPayCheckout: '/create-payment-session',
  brainTreeToken: '/braintreeToken',
  saveRating: '/saveRating',
  searchResult: '/searchProducts',
  saveFavoriteItem: '/saveFavoriteItem',
  getFavouriteItems: '/getFavouriteItems',
  deleteFavouriteItem: '/deleteFavouriteItem',
  changePassword: '/changePassword',
  getCountries: '/getCountries',
  getBillingAccountDetails: '/getBillingAccountDetails',
  saveNewAddress: '/saveNewAddress',
  addAmendAccount: '/addAmendAccount',
  updateEmail: '/updateEmail',
  getDeliveryOptions: '/getDeliveryOptions',
  getOrderHistory : '/getOrderHistory',
  quickCartSearch : '/quickCartSearch',
  getProductPriceBreakdown : '/getProductPriceBreakdown',
  getNewProducts : '/getNewProducts',
  getUserAccountBalance : '/getUserAccountBalance',
  getOrderItems : '/getOrderItems',
  addToAccount : '/addToAccount',
  SaveOutstandingInvoices : '/SaveOutstandingInvoices',
  worldpayCallback : '/payment/callback',
  fetchOrderData : '/thankyou',
}
export default pathObj
