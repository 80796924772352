import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import MyAccountSideBar from './MyAccountSideBar';
import { CommonService } from '../../services/common';
import { decryptData, errorHandling, UpdatedDecimalDigits } from '../../helpers/common';
import pathObj from '../../services/Config';
import debounce from 'lodash.debounce';
import getSymbolFromCurrency from 'currency-symbol-map';
import toast from 'react-hot-toast';
import BraintreePaymentModal from './BraintreePaymentModal';

const AccountBalance = () => {
  const userInfo = window.localStorage.getItem('userData');
  const userD = decryptData(userInfo);
  const compData = JSON.parse(userD);
  const customerId = compData?.customer_id;
  const currencyName = compData?.Currency;
  const currencySymbol = getSymbolFromCurrency(currencyName);
  const [loading, setLoading] = useState(false);
  const [accountBalance, setAccountBalance] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const selectedInvoiceId = selectedInvoices.join(', ')
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [selectedInvoicesAmount, setSelectedInvoicesAmount] = useState(0);
  const [errorText, setErrorText] = useState('Balance not found.');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const [specificAmount, setSpecificAmount] = useState(0);

  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    if (specificAmount <= 0 && selectedInvoicesAmount <= 0) {
      toast.error('Please enter a positive amount to proceed to payment');
    } else {
      openModal();
    }
  };


  const fetchAccountBalance = async () => {
    setLoading(true);
    const url = `${pathObj.baseURL}${pathObj.getUserAccountBalance}`;
    const payload = {
      customer_id: customerId,
    };
    try {
      const response = await CommonService.getAllWithBodyWithToken(url, payload);
      if (response.success === true) {
        setAccountBalance(response?.data);
      } else {
        setErrorText(response?.message);
      }
    } catch (error) {
      setErrorText(error?.response?.message);
      errorHandling(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (item) => {
    const isSelected = selectedInvoices.includes(item.id);
    let updatedSelectedInvoices;

    // Toggle selection of the checkbox
    if (isSelected) {
      updatedSelectedInvoices = selectedInvoices.filter(id => id !== item.id);
    } else {
      updatedSelectedInvoices = [...selectedInvoices, item.id];
    }

    setSelectedInvoices(updatedSelectedInvoices);

    // Recalculate the total debit and credit values based on updated selections
    let newTotalDebit = 0;
    let newTotalCredit = 0;
    accountBalance.forEach(inv => {
      if (updatedSelectedInvoices.includes(inv.id)) {
        const debitValue = parseFloat(inv.debit) || 0;  // Convert debit string to number
        const creditValue = parseFloat(inv.credit) || 0;  // Convert credit string to number
        newTotalDebit += debitValue;
        newTotalCredit += creditValue;
      }
    });

    setTotalDebit(newTotalDebit);
    setTotalCredit(newTotalCredit);

    // Calculate the amount and show the negative value if debit is smaller than credit
    const calculatedAmount = newTotalDebit - newTotalCredit;

    setSelectedInvoicesAmount(calculatedAmount); // Now this can show a negative value
  };




  const debouncedFetchAccountBalance = debounce(fetchAccountBalance, 300);

  useEffect(() => {
    debouncedFetchAccountBalance();
  }, [customerId]);

  return (
    <>
      {loading && (<div className='loaderOuter'><div className="loader"></div></div>)}
      <div className="cetogry_listing">
        <div className="container">
          <div className="clinner d-flex flex-wrap align-items-start">
            <MyAccountSideBar />

            <div className="common_right_block">
              <div className="contact_details">
                <h1>Account Balance / Pay Bill</h1>

                <div className="aged_details">
                  <h4>Account Balance</h4>
                  <p>Aged Balance Details of your Account</p>
                  <ul>
                    <li>4 Months+ <br /> {currencySymbol}0.00</li>
                    <li>3 Months+ <br /> {currencySymbol}0.00</li>
                    <li>2 Months+ <br /> {currencySymbol}0.00</li>
                    <li>1 Months+ <br /> {currencySymbol}0.00</li>
                    <li>Current <br /> {currencySymbol}0.00</li>
                    <li>Account Balance <br /> {currencySymbol}0.00</li>
                  </ul>
                  <div className="oda">Overdue Amount - {currencySymbol}0.00</div>

                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-check custom_check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="specificAmount"
                        {...register('specificAmount')}
                        disabled={selectedInvoices.length > 0} // Disable if invoices are selected
                        onChange={() => setSpecificAmount(0)} // Reset specificAmount if unchecked
                      />
                      <label className="form-check-label" htmlFor="specificAmount">
                        Pay a Specific Amount : {currencySymbol}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={specificAmount || ''} // Bind to specificAmount state
                        onChange={(e) => setSpecificAmount(parseFloat(e.target.value) || 0)}
                      />
                    </div>

                    <div className="form-check custom_check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="selectedInvoices"
                        {...register('selectedInvoices')}
                        checked={selectedInvoices.length > 0} // Check if any invoices are selected
                        onChange={() => {
                          if (document.getElementById('specificAmount').checked) {
                            document.getElementById('specificAmount').checked = false;
                            setSpecificAmount(0); // Clear specificAmount when switching to invoices
                          }
                        }}
                      />
                      <label className="form-check-label" htmlFor="selectedInvoices">
                        Pay Selected invoices : {currencySymbol}
                      </label>
                      <input
                        type="text"
                        value={selectedInvoicesAmount.toFixed(2)} // Allow user to change this value
                        className="form-control"
                        onChange={(e) => {
                          const value = parseFloat(e.target.value);
                          if (!isNaN(value) && value >= 0) {
                            setSelectedInvoicesAmount(value); // Update the amount based on user input
                          }
                        }}
                        {...register('selectedInvoicesAmount', {
                          required: 'Amount is required',
                          pattern: {
                            value: /^[0-9]+(\.[0-9]{1,2})?$/,
                            message: 'Invalid amount format'
                          }
                        })}
                      />
                      {errors.selectedInvoicesAmount && <div className="invalid-feedback">{errors.selectedInvoicesAmount.message}</div>}

                      <button
                        type="button"
                        className="button5"
                        style={{ marginLeft: '10px' }}
                        onClick={onSubmit}
                      >
                        Pay Now
                      </button>

                      {/* Braintree Payment Modal */}
                      <BraintreePaymentModal
                        show={modalIsOpen}
                        hide={closeModal}
                        selectedInvoices={selectedInvoiceId}
                        selectedInvoicesAmount={specificAmount > 0 ? specificAmount : selectedInvoicesAmount}
                        customerId={customerId}
                        reset={reset}
                        setSelectedInvoices={setSelectedInvoices}
                        setTotalDebit={setTotalDebit}
                        setSelectedInvoicesAmount={setSelectedInvoicesAmount}
                        setTotalCredit={setTotalCredit}
                      />
                    </div>
                  </form>

                  <div className="pleasenote">Please Note: Payment discount is not allowed when paying by card</div>
                </div>

                <div className="outstanding_invoices">
                  <h3>Outstanding Invoices/Credits</h3>
                  <div className="common_table table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th>Inv.Date</th>
                          <th>Invoice No.</th>
                          <th>Details</th>
                          <th>Ref.No.</th>
                          <th>Due Date</th>
                          <th>Debit</th>
                          <th>Credit</th>
                          <th>Balance</th>
                          <th style={{ textAlign: 'center' }}>Select</th>
                        </tr>
                      </thead>
                      <tbody>
                        {accountBalance.map((item) => (
                          <tr key={item.id}>
                            <td>{new Date(item.invoice_date).toLocaleDateString()}</td>
                            <td>{item.invoice_number}</td>
                            <td>{item.details}</td>
                            <td>{item.refrence_number || 'N/A'}</td>
                            <td>{new Date(item.due_date).toLocaleDateString()}</td>
                            <td>{currencySymbol}{item?.debit ? UpdatedDecimalDigits(item?.debit) : ''}</td>
                            <td>{currencySymbol}{item?.credit ? UpdatedDecimalDigits(item?.credit) : 'N/A'}</td>
                            <td>{UpdatedDecimalDigits(item?.balance)}</td>
                            <td style={{ textAlign: 'center' }}>
                              <input
                                type="checkbox"
                                onChange={() => handleCheckboxChange(item)}
                                checked={selectedInvoices.includes(item.id)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default AccountBalance;
