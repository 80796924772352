import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CommonService } from '../../../src/services/common';
import pathObj from '../../../src/services/Config';
import { useAuth } from '../../hooks/useAuth';
import MegaMenu from './MegaMenu';
import { CartContext } from '../../context/cartContext';
import MobileMegaMenu from './MobileMegaMenu';
import { decryptData } from '../../helpers/common';
const Header = () => {
  const [headerInfo, setHeaderInfo] = useState(null);
  const hasFetched = useRef(false);
  const token = localStorage.getItem("Token");
  const { signOut } = useAuth()
  const { cartCount } = useContext(CartContext); // Access cart count from context
  const [cartItemCount , setCartItemCount] = useState(cartCount);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate()
  const userInfo = window.localStorage.getItem('userData');  
  const userD = decryptData(userInfo);  
  const compData = JSON.parse(userD);
 

  useEffect(() => {
    if(compData?.first_time_login == 0){
      navigate(`/reset-password/${compData?.reset_token}?email=${compData?.email}`);
    }
  }, []);
  
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    try {
      const searchText = searchQuery
      const formattedSearchText =searchText?.replace(/\//g, '__');
      navigate(`/search/${formattedSearchText}`)
      window.location.reload();
    } catch (error) {
      console.error("Error during search:", error);
    }
  };

  useEffect(() => {
    const fetchHeaderInfo = async () => {
      try {
        const [headerData] = await Promise.all([
          CommonService.getListing(`${pathObj.baseURL}${pathObj.getHeaderInfo}`),
        ]);
        window.localStorage.setItem('favicon', headerData?.data?.favicon);
        setHeaderInfo(headerData?.data);
      } catch (error) {
        console.error("Error fetching header info:", error);
      }
    };

    if (!hasFetched.current) {
      fetchHeaderInfo();
      hasFetched.current = true;
    }
  }, []);
        
        

  const handleLogout = async () => {
    signOut()
  };

    useEffect(() => {
    window.addEventListener("cartUpdate", () => {
      const updateCartCount = window.localStorage.getItem("cartItems")
      const countItem = JSON.parse(updateCartCount)
      setCartItemCount(countItem?.length)
    });
  }, []);

  return (
    <>
      <div className="header">
        <div className="top_bar">
          <div className="container">
            <ul>
              <li>{headerInfo?.firstText}</li>
              <li>{headerInfo?.secondText}</li>
              <li><a href="/technical_support">Technical Support</a> | <a href="/sales">Talk To Sales</a></li>
            </ul>
          </div>

        </div>
        <div className="container">
          <div className="header_inner d-flex flex-wrap align-items-center justify-content-between">
            
            {token && (
                <div className='afterLogin_menu mobile'>
                  <a href="/">
                    Home
                  </a> | <a href={token ? "/quick-order" : "/login"}>Quick Orders</a> |  <a href={token ? "/favourite-products" : "/login"}>Favourites</a> | <a href={token ? "/account-details" : "/login"}>My Account</a> {/* | <a href={token ? "/account-balance" : "/login"}>Pay Account</a> */} </div>)}
          
            <div className="logo">
              <a href="/">
                <img src={headerInfo?.logo} alt="" />
              </a>
            </div>

            <div className="header_right">
              {token && (
                <div className='afterLogin_menu'>
                  <a href="/">
                    Home
                  </a> | <a href={token ? "/quick-order" : "/login"}>Quick Orders</a> |  <a href={token ? "/favourite-products" : "/login"}>Favourites</a> | <a href={token ? "/account-details" : "/login"}>My Account</a> {/*| <a href={token ? "/account-balance" : "/login"}>Pay Account</a>*/} </div>)}

              <ul>
                <li className='search_form'>
                  <div className="searchingform show">
                    <form onSubmit={handleSearchSubmit}>
                      <input
                        type="search"
                        placeholder="Search our products"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && !searchQuery) {
                            e.preventDefault(); // Prevent form submission if searchQuery is empty
                          }
                        }}
                      />
                    </form>
                  </div>
                  <Link to="#" className="search_icon">
                    <div className="icon" onClick={(e) => {
                      if (searchQuery) handleSearchSubmit(e); // Only call handleSearchSubmit if searchQuery is not empty
                    }}>
                      <img src={`${pathObj?.s3ImagePath}/search-icon.svg`} alt="img" />
                    </div>
                  </Link>
                </li>
                <li>
                  <a href={`tel:${headerInfo?.contact}`}>
                    <div className="icon"><img src={`${pathObj?.s3ImagePath}/call-icon.svg`} alt="img" /></div>
                    <div className="text">{headerInfo?.contact}</div>
                  </a>
                </li>
                {!token ? (
                  <li>
                    <a href="/login">
                      <div className="icon"><img src={`${pathObj?.s3ImagePath}/user-icon.svg`} alt="img" /></div>
                      <div className="text">Login</div>
                    </a>
                  </li>
                ) : (
                  <li>
                    <Link to="#" onClick={handleLogout}>
                      <div className="icon"><img src={`${pathObj?.s3ImagePath}/logout.svg`} alt="img" /></div>
                      <div className="text">Logout</div>
                    </Link>
                  </li>
                )}
                <li>
                  <a href={token ? "/cart" : "/login"}>
                    <div className="icon"><img src={`${pathObj?.s3ImagePath}/cart-icon.svg`} alt="img" /></div>
                    <div className="text">My Cart </div>
                    <div className="cart_count">{cartItemCount || cartCount}</div>
                  </a>
                </li>
                <li className="navbar_toggler">
                  <nav>
                    <input type="checkbox" id="menu" name="menu" class="m-menu__checkbox" />
                    <label class="m-menu__toggle" for="menu">
                      <a className='d-flex'>
                        <span></span>
                        <span></span>
                        <span></span>
                      </a>
                    </label>
                    <label class="m-menu__overlay" for="menu"></label>
                    <MobileMegaMenu />
                  </nav>
                </li>
              </ul>

            </div>
          </div>
        </div>
        <MegaMenu />
      </div>
    </>
  );
}

export default Header;
