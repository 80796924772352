import React, { useEffect, useState } from 'react';
import pathObj from '../../services/Config';
import MyAccountSideBar from '../../components/MyAccount/MyAccountSideBar';
import { CommonService } from '../../services/common';
import { checkImage, decryptData, errorHandling, UpdatedDecimalDigits } from '../../helpers/common';
import toast from 'react-hot-toast';
import debounce from 'lodash.debounce';
import moment from 'moment'; // For date formatting
import getSymbolFromCurrency from 'currency-symbol-map';

const OrderHistory = () => {
  const userInfo = window.localStorage.getItem('userData');
  const userD = decryptData(userInfo);
  const compData = JSON.parse(userD);
  const customerId = compData?.customer_id;
  const currencyName = compData?.Currency
  const currencySymbol = getSymbolFromCurrency(currencyName);
  const [orderedData, setOrderedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorText, setErrorText] = useState('');
  const [orderItems, setOrderItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null); // State for the selected order
  const [imageStatus, setImageStatus] = useState({});
  const [orderedFilterData, setOrderedFilterData] = useState([]);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState('');
  const [selectedOrderDate, setSelectedOrderDate] = useState('');

  const fetchOrderedHistory = async () => {
    setLoading(true);
    const url = `${pathObj.baseURL}${pathObj.getOrderHistory}`; // Update with your API endpoint
    const payload = {
      customer_id: customerId,
      order_date: selectedOrderDate,
      order_status: selectedOrderStatus,
    };
  
    try {
      const response = await CommonService.getAllWithBodyWithToken(url, payload);
      if (response.success) {
        setOrderedData(response?.data || []);  // Set data if response is successful
        setOrderedFilterData(response?.orderStatusArr || []);
      } else {
        setOrderedData([]); // Clear the data if the response is unsuccessful
        setErrorText(response.message);
        toast.error(response.message);
      }
    } catch (error) {
      setOrderedData([]); // Clear the data if there's an error
      setErrorText(error?.response?.data?.message || 'An error occurred');
      errorHandling(error);
    } finally {
      setLoading(false);
    }
  };
  

  const fetchOrderItems = async (invoiceNumber) => {
    // setLoading(true);
    const url = `${pathObj.baseURL}${pathObj.getOrderItems}`; // Update with your API endpoint
    const payload = { DocEntry: invoiceNumber, };

    try {
      const response = await CommonService.getAllWithBodyWithToken(url, payload);
      if (response.success) {
        setOrderItems(response?.data || []); // Set fetched order items
        setLoading(false);
        setIsModalOpen(true); // Open modal once order items are fetched
      } else {
        toast.error(response.message);
        setLoading(false);
      }
    } catch (error) {
      setErrorText(error?.response?.data?.message || 'An error occurred');
      setLoading(false);
      errorHandling(error);
    }
  };

  const debouncedFetchOrderedHistory = debounce(fetchOrderedHistory, 300);

  useEffect(() => {
    debouncedFetchOrderedHistory();
  }, [customerId, selectedOrderStatus, selectedOrderDate]);

  const handleOrderStatusChange = (event) => {
    setSelectedOrderStatus(event.target.value);
  };

  const handleOrderDateChange = (event) => {
    setSelectedOrderDate(event.target.value);
  };


  // Handle click to open modal and fetch order items
  const handleOrderClick = (order) => {
    setSelectedOrder(order); // Set the selected order for the modal
    fetchOrderItems(order?.invoice_number); // Fetch order items
  };
  useEffect(() => {
    const loadImages = async () => {
      const status = { ...imageStatus }; // Copy the current image status
      for (const product of orderItems) {
        const pictureCheck = await checkImage(product?.picture);
        const vectorPictureCheck = await checkImage(product?.vectorPicture);

        // Check if the picture URL works, if not check vectorPicture, if both fail use the no-image.jpg
        if (pictureCheck?.status === "ok") {
          status[product?.item_code] = pictureCheck?.path;
        } else if (vectorPictureCheck?.status === "ok") {
          status[product?.item_code] = vectorPictureCheck?.path;
        } else {
          status[product?.item_code] = `${pathObj?.s3ImagePath}/no-image.jpg`;
        }

        // Update image status for the current product as soon as it's ready
        setImageStatus((prevStatus) => ({ ...prevStatus, [product?.item_code]: status[product?.item_code] }));
      }
    };

    if (orderItems?.length > 0) {
      loadImages();
    }
  }, [orderItems]);


  // Modal component for displaying order items
  const OrderItemsModal = () => (
    <div className={`modal ${isModalOpen ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: isModalOpen ? 'block' : 'none' }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Order Items for Invoice {selectedOrder?.invoice_number}</h5>
          </div>
          <div className="modal-body" style={{padding:0}}>
            {orderItems.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Item Code</th>
                    <th>Item Name</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {orderItems.map((item, index) => {
                    return(
                    <tr key={index}>
                      <td>
                        {/* {!imageStatus[item?.item_code] ? (
                                    <div className="loader"></div> // Show loader until image status is resolved
                                  ) : ( */}
                        <a href="#">
                          <img
                           src={imageStatus[item?.item_code] || item?.picture || item?.vectorPicture || `${pathObj?.s3ImagePath}/no-image.jpg`}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = `${pathObj?.s3ImagePath}/no-image.jpg`;
                            }}
                            alt={item.ItemName} /></a>
                         {/* )}  */}
                      </td>
                      <td>{item.item_code}</td>
                      <td>{item.product_name}</td>
                      <td>{item.quantity}</td>
                      <td>{currencySymbol}{item.price_per_unit}</td>
                      <td>{currencySymbol}{item.total_price}</td>
                    </tr>
                  )})}
                </tbody>
              </table>
            ) : (
              <p>No items found for this order.</p>
            )}
          </div>
          <div className="modal-footer">
            <button type="button" className="button2" onClick={() => {setIsModalOpen(false) 
              setImageStatus({})
              setOrderItems([])
            }}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="cetogry_listing">
        <div className="container">
          <div className="clinner d-flex flex-wrap align-items-start">
            <MyAccountSideBar />

            <div className="common_right_block">
              <div className="contact_details position-relative">
                <h1>Order History</h1>

                {/* Filters (if applicable) */}
                <div className="ohFilter common_form">
                  <div className="date_filter">
                    <input type="date" className="form-control" onChange={handleOrderDateChange} />
                  </div>
                  <div className="order_status">
                    <select className="form-control form-select" onChange={handleOrderStatusChange}>
                      <option value="">Order Status</option>
                      {Object.entries(orderedFilterData).map(([key, value]) => (
                        <option key={key} value={key}>{value}</option>
                      ))}
                    </select>
                  </div>
                </div>

                {/* Order History Table */}
                <div className="pt-4 mt-md-3">
                  <div className="common_table table-responsive" style={{height:'500px'}}>
                    <table >
                      <thead style={{ position:'sticky', top: 0 , background:'#F4F5FF', padding:'5px'}}>
                        <tr className="text-center">
                          <th>Order No.</th>
                          <th>Order Date</th>
                          <th>Order Delivery Date</th>
                          <th>Customer Reference</th>
                          <th>Payment Reference</th>
                          <th>Order Total</th>
                          <th>Order Total (INCVAT)</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      {loading ? (
                        <div className="loader productListLoader"></div>
                      ) : (
                        <tbody>
                          {orderedData.length > 0 ? (
                            orderedData.map((order) => (
                              <tr key={order.order_id}  className="text-center">
                                <td onClick={() => handleOrderClick(order)} style={{ cursor: 'pointer', color: '#0e1fe3' }}>
                                  {order?.invoice_number}
                                </td>
                                <td style={{ width: '150px' }}>
                                  {moment(order?.order_date).format('DD MMM, YYYY')}
                                </td>
                                <td className="text-center" style={{ width: '150px' }}>
                                  {moment(order?.order_delivery_date).format('DD MMM, YYYY')}
                                </td>
                                <td className="text-center">{order.customer_reference}</td>
                                <td className="text-center">{order.payment_reference}</td>
                                <td className="text-center">{currencySymbol}{UpdatedDecimalDigits(order.order_total)}</td>
                                <td className="text-center">{currencySymbol}{UpdatedDecimalDigits(order.order_total_incVat)}</td>
                                <td className="text-center">{order.status}</td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="9" className="text-center">
                                {errorText && <div className="error-message">{errorText}</div>}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Order Items Modal */}
      {isModalOpen && <OrderItemsModal />}
    </>
  );
};

export default OrderHistory;
