import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import pathObj from "../../services/Config";
import { CommonService } from "../../services/common";
import { checkImage, decryptData, errorHandling, formattedName , UpdatedDecimalDigits} from "../../helpers/common";
import toast from "react-hot-toast";
import Pagination from 'react-js-pagination';
import { Helmet } from "react-helmet";
import debounce from 'lodash.debounce';
// Add context to manage cart count across components
import { CartContext } from "../../context/cartContext";
import getSymbolFromCurrency from "currency-symbol-map";

const NewProduct = () => {
  const { addToCartCount, setPageList,page,productString } = useContext(CartContext);
  const { category, subCategory, child } = useParams();
  const [productData, setProductData] = useState([]);
  const [imageStatus, setImageStatus] = useState({});
  const [quantities, setQuantities] = useState({});
  const [priceDropdownVisible, setPriceDropdownVisible] = useState({});
  const [priceBreakDown, setPriceBreakDown] = useState('');
  const [priceBreak, setPriceBreak] = useState('');
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = useState(10); // Default limit is 10
  const [pageNo, setPageNo] = useState(1); // Default page is 1
  const [listTotal, setListTotal] = useState(0);
  const [shortBy, setNewShortBy] = useState('product_name'); // Default limit is 10
  const [errorText, setErrorText] = useState('');
  const [attributesList, setAttributeList] = useState([]);
  const userInfo = window.localStorage.getItem('userData');
  const userD = decryptData(userInfo);
  const compData = JSON.parse(userD);
  const currencyName = compData?.Currency
  const currencySymbol = getSymbolFromCurrency(currencyName);
  const customerId = compData?.customer_id;
  const token = localStorage.getItem("Token");
  const productFilter = productString;
  const navigate = useNavigate();
  const [wishlistStatus, setWishlistStatus] = useState({});
  const [selectedFilters, setSelectedFilters] = useState([]);
  const childName = child ? formattedName(child) : null;
  const payTermsGrp = compData?.PayTermsGrpCode;


  const handleRatingChange = async (itemCode, rating) => {
    if (rating && itemCode) {
      const url = `${pathObj.baseURL}/saveRating`; // Update with your API endpoint
      const payload = {
        product_id: itemCode,
        customer_id: customerId,
        rating: rating,
      };
      try {
        const response = await CommonService.getAllWithBodyWithToken(url, payload);
        if (response.success) {
          toast.success(response?.message || "Rating submitted successfully!");
          setTimeout(() => {
            fetchProductData();
          }, 300);
        } else {
          toast.error(response.message || "Failed to submit rating.");
        }
      } catch (error) {
        console.error("Error submitting rating:", error);
        errorHandling(error);
      }
    };
  }

  const handleLimitChange = (event) => {
    const newLimit = parseInt(event.target.value);
    setLimit(newLimit);
    setPageList(1); // Reset page to 1 when limit changes
    fetchProductData(1, newLimit); // Ensure the new limit is passed when fetching data
  };

  const handleShortBy = (event) => {
    const selectedValue = event.target.value;
    setNewShortBy(selectedValue);
    setPageList(1); // Reset to page 1 when sort order changes
    fetchProductData(1, limit, selectedValue); // Fetch data with the new sorting option
  };


  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber)
    setPageList(pageNumber);
    fetchProductData(pageNumber, limit, shortBy); // Fetch products for the selected page
  };

  const handleFilterChange = (filterLabel, filterValue, isChecked) => {
    setSelectedFilters((prevFilters) => {
      const existingFilter = prevFilters.find(filter => filter.filter_label === filterLabel);

      if (existingFilter) {
        // Convert the comma-separated string back to a Set
        const filterValuesSet = new Set(existingFilter.filter_label_data.split(','));

        if (isChecked) {
          filterValuesSet.add(filterValue); // Add new value when checked
        } else {
          filterValuesSet.delete(filterValue); // Remove value when unchecked
        }

        const updatedFilterData = [...filterValuesSet].join(',');

        if (updatedFilterData) {
          // If there are still values, update the filter
          setPageNo(1)
          return prevFilters?.map(filter =>
            filter.filter_label === filterLabel
              ? { ...filter, filter_label_data: updatedFilterData }
              : filter
          );
        } else {
          // If no values left, remove the entire filter entry
          return prevFilters.filter(filter => filter.filter_label !== filterLabel);
        }
      } else if (isChecked) {
        // If the filter doesn't exist and checkbox is checked, add a new entry
        return [...prevFilters, { filter_label: filterLabel, filter_label_data: filterValue }];
      }

      return prevFilters;
    });
  };

  const fetchProductData = async (pageNumber = 1, pageLimit = limit, short_by = shortBy) => {
    setLoading(true);
    try {
      // The selectedFilters is now already in the required format
      const formattedFilters = selectedFilters;
      const Currency = compData?.Currency;
      const PriceListNum = compData?.PriceListNum ? compData?.PriceListNum : '';
      const customer_id = customerId;
      const pageList = page ? `&page=${pageNumber}` : '';
      // const pageList = page ? `&page=${pageNumber}` : '';
      const pageLimits = limit ? `&limit=${pageLimit}` : '';
      const shorting = shortBy ? `&short_by=${short_by}` : '';
      const productFilterString = productFilter
      const url = `${pathObj.baseURL}${pathObj.getNewProducts}?${pageList}${pageLimits}${shorting}`;
      const payload = { Currency, PriceListNum, customer_id, filter_by: formattedFilters, productFilterString };
      const data = await CommonService.getAllWithBodyWithToken(url, payload);
      setProductData(data?.data?.ProductList);
      setAttributeList(data?.data?.AttributesList);
      setListTotal(data?.data?.TotalProducts);
      localStorage.setItem('categoriesName', data?.data?.category)
    } catch (error) {
      if (error?.response?.data?.success === false) {
        setErrorText(error?.response?.data?.message);
        setProductData([]);
        setListTotal(0);
      }
      setLoading(false);
      errorHandling(error);
    } finally {
      setLoading(false);
    }
  };

  // Debounced version of fetchProductData
  const debouncedFetchProductData = debounce(fetchProductData, 300); // Adjust the delay as needed
  useEffect(() => {    
    if(selectedFilters.length > 0){
      setPageNo(1)
      debouncedFetchProductData(1, limit, shortBy);
    }else{
      debouncedFetchProductData(page, limit, shortBy);
    }
  }, [category, subCategory, child, page, limit, shortBy, selectedFilters]);

  // useEffect(() => {
  //   // if(selectedFilters.length > 0){
  //     setPageNo(1)
  //     debouncedFetchProductData(1, limit, shortBy);
  //   // }    
  // }, [selectedFilters]);

  const loadImages = useCallback(async () => {
    let status = { ...imageStatus }; // Create a copy of current image status

    const imagePromises = productData.map(async (product) => {
      const pictureCheck = await checkImage(product?.Picture);
      const vectorPictureCheck = await checkImage(product?.vectorPicture);

      // Update the status object for each product
      if (pictureCheck?.status === "ok") {
        status[product?.ItemCode] = pictureCheck?.path;
      } else if (vectorPictureCheck?.status === "ok") {
        status[product?.ItemCode] = vectorPictureCheck?.path;
      } else {
        status[product?.ItemCode] = `${pathObj?.s3ImagePath}/no-image.jpg`;
      }
    });
    await Promise.all(imagePromises);
    // Update the image status state once with the complete status object
    setImageStatus((prevStatus) => ({ ...prevStatus, ...status }));
  }, [productData, pathObj?.s3ImagePath, imageStatus]);


  useEffect(() => {
    if (productData?.length > 0) {
      loadImages();
    }
  }, [productData, loadImages]);

  // Fetch price breakdown when the plus icon is clicked
  const handleQuantityChange = (itemCode, type, customQuantity = null) => {
    setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      let currentQuantity = newQuantities[itemCode] || 1;
      currentQuantity = parseInt(currentQuantity);

      // If customQuantity is passed (from price breakdown), set it directly
      if (customQuantity !== null) {
        currentQuantity = parseInt(customQuantity);
      } else {
        if (type === "plus") {
          currentQuantity += 1;
          // Only call fetchProductPriceBreakdown if price dropdown is not visible yet
          if (!priceDropdownVisible[itemCode]) {
            debouncedFetchProductPriceBreakdown(itemCode); // Debounced API call
          }
        } else if (type === "minus" && currentQuantity > 1) {
          currentQuantity -= 1;
        }
      }

      // Update the quantity for the product
      newQuantities[itemCode] = currentQuantity;

      // Always hide the dropdown initially when modifying the quantity
      setPriceDropdownVisible((prevVisible) => ({
        ...prevVisible,
        [itemCode]: false,
      }));

      return newQuantities;
    });
  };

  const togglePriceDropdown = (itemCode) => {
    // setPriceDropdownVisible((prevVisible) => ({
    //   ...prevVisible,
    //   [itemCode]: !prevVisible[itemCode],
    // }));

    // Optionally, fetch the price breakdown if making it visible
    if (!priceDropdownVisible[itemCode]) {
      debouncedFetchProductPriceBreakdown(itemCode);
    }
  };
  // Fetch product price breakdown from the separate API
  const fetchProductPriceBreakdown = async (ItemCode) => {
    try {
      const Currency = compData?.Currency;
      const PriceListNum = compData?.PriceListNum;
      const customer_id = customerId;
      const url = `${pathObj.baseURL}${pathObj.getProductPriceBreakdown}?ItemCode=${ItemCode}`;
      const payload = {
        Currency,
        PriceListNum,
        customer_id,
      };

      const data = await CommonService.getAllWithBodyWithToken(url, payload);
      const pricesBreakdown = data?.data?.PricesBreakdown;

      // Show the price dropdown only if there's a valid price breakdown
      setPriceDropdownVisible((prevVisible) => ({
        ...prevVisible,
        [ItemCode]: pricesBreakdown?.length > 0, // Only show dropdown if breakdown exists
      }));

      // Update price breakdown data
      setPriceBreak((prevBreaks) => ({
        ...prevBreaks,
        [ItemCode]: pricesBreakdown || [],
      }));
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching price breakdown:", error);
      // Ensure the dropdown is hidden if an error occurs
      setPriceDropdownVisible((prevVisible) => ({
        ...prevVisible,
        [ItemCode]: false,
      }));
    }
  };

  const debouncedFetchProductPriceBreakdown = debounce(fetchProductPriceBreakdown, 300);

  const handlePriceBreakdownClick = (itemCode, quantity, pId) => {
    setPriceBreakDown(pId)
    // Set the quantity to the selected breakdown quantity
    handleQuantityChange(itemCode, null, quantity);
  };

  const handleProductDetailsClick = (product) => {
    localStorage.setItem('productType', product?.ProductType);
    const formattedItemCode = product?.ItemCode.replace(/\//g, '__'); // Replace slashes with hyphens
    navigate(`/products/${formattedItemCode}`);
  };

  const handleAddToCart = async (product) => {
    if (product?.QuantityOnStock > 0 || (product?.QuantityOnStock <= 0 && payTermsGrp != 17)) {
      const quantity = quantities[product?.ItemCode] || 1;
      const priceBDownIdToUse = priceBreakDown;
      const product_price_id = product?.ProductPrice?.[0]?.id ? product?.ProductPrice?.[0]?.id : '';
      const url = `${pathObj.baseURL}${pathObj.addCart}`;
      const payload = {
        customer_id: customerId,
        product_id: product?.id,
        quantity,
        price_break_down_id: priceBDownIdToUse,
        product_price_id,
        product_itemcode: product?.ItemCode,
        customer_cardcode: compData?.CardCode,
      };
      try {
        const response = await CommonService.getAllWithBodyWithToken(url, payload);
        if (response.success) {
          toast.success(response.message || `${product?.ItemCode} added to cart  successfully.!`);
          addToCartCount(product);
        } else {
          toast.error(response.message || "Something went wrong!");
        }
      } catch (error) {
        console.error("Error adding product to cart:", error);
        errorHandling(error);
      }
    } else {
      toast.error("Item Currently not available!");
    }
  };

  const handleWishlistToggle = async (productItemCode) => {
    const currentStatus = wishlistStatus[productItemCode] || false; // Get current status of the product
    const url = `${pathObj.baseURL}/saveFavoriteItem`; // Update with your API endpoint
    const payload = {
      customer_id: customerId,
      product_id: productItemCode,
      status: currentStatus ? 0 : 1, // Toggle wishlist status
    };

    try {
      const response = await CommonService.getAllWithBodyWithToken(url, payload);
      if (response.success) {
        // Update the wishlist status for the specific product
        setWishlistStatus((prevStatus) => ({
          ...prevStatus,
          [productItemCode]: !currentStatus, // Toggle the wishlist status
        }));
        toast.success(response?.message || "Wishlist updated successfully!");
        setTimeout(() => {
          fetchProductData();
        }, 300);
      } else {
        toast.error(response.message || "Failed to update wishlist.");
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
      toast.error("An error occurred while updating the wishlist.");
      errorHandling(error);
    }
  };

  const handleProductGroupDetailsClick = (product) => {
    localStorage.setItem('productType', product?.ProductType);
    const formattedItemCode = product?.ItemCode.replace(/\//g, '__'); // Replace slashes with hyphens
    navigate(`/group-products/${formattedItemCode}`);
  }

  const handleClearAll = () => {
    setSelectedFilters([]); // Clear the filters
    fetchProductData(1, limit, shortBy); // Call the product data with no filters
  };

  return (
    <>
      <Helmet>
        <title>{childName || "Default Product Name"} | Davenport Burgess</title>
        <meta name="description" content="Product page" />
      </Helmet>
      <div className="product_listing" style={{ minHeight: '100vh' }}>
        <div className="container">
          <div className="clinner d-flex flex-wrap align-items-start pt-4" >
            <div className="product_sidebar">
              <div className="pstitle d-flex flex-wrap align-items-center justify-content-between">
                <h3>Filters</h3>
                {attributesList?.length > 0 &&
                  <>
                    <a href="#" onClick={handleClearAll}>Clear All</a>
                  </>
                }
              </div>
              <div className="accordion" id="accordionExample">
                {attributesList && attributesList.map((attribute, index) => (
                  <div className="accordion-item" key={index}>
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#filter-${index}`}
                        aria-expanded="false"
                      >
                        {attribute?.filter_label}
                      </button>
                    </h2>
                    <div
                      id={`filter-${index}`}
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body"
                        style={{
                          maxHeight: attribute?.filter_label_data?.length > 10 ? '200px' : 'auto',
                          overflowY: attribute?.filter_label_data?.length > 10 ? 'auto' : 'visible',
                        }}>
                        {attribute?.filter_label_data?.map((filterOption, idx) => {
                          const isChecked = selectedFilters?.find(
                            filter => filter?.filter_label === attribute?.filter_label && filter?.filter_label_data?.includes(filterOption)
                          );

                          return (
                            <div className="form-check custom_check" key={idx}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value={filterOption}
                                id={`filter-option-${index}-${idx}`}
                                onChange={(e) => handleFilterChange(attribute?.filter_label, filterOption, e.target.checked)} // Pass checked status
                                checked={!!isChecked} // Determine if the checkbox is checked
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`filter-option-${index}-${idx}`}
                              >
                                {filterOption}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="prd_block" >
              <div className="prd_toolbar" >
                <h1>{childName}</h1>
                <div className="tbSorter">
                  <div className="toolbar_sorter">
                    <label className="sorter_label">Sort By</label>
                    <select className="form-select" value={shortBy} onChange={handleShortBy}>
                      <option value="product_name">Product Name</option>
                      <option value="price_min_to_max">Price: Low to High</option>
                      <option value="price_max_to_min">Price: High to Low</option>
                    </select>

                  </div>
                  <div className="toolbar_sorter">
                    <label className="sorter_label">Show</label>
                    <select className="form-select" value={limit} onChange={handleLimitChange}>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                      <option value="30">30</option>
                    </select>
                    <div className="perpage">per page</div>
                  </div>
                </div>
              </div>
              {loading ? (
                <div className="loader productListLoader"></div> // Display loader when loading
              ) : (
                <>
                  <div className="prd_grid">
                    <ul>
                      {productData?.length > 0 ? (
                        productData?.map((product, index) => {
                          return (
                            <li key={index}>
                              <div className="productThumb">
                                {/* <Link to={`/products/${formattedItemCode}`}> */}
                                {!imageStatus[product?.ItemCode] ? (
                                  <div className="loader"></div> // Show loader until image status is resolved
                                ) : (
                                  <img
                                    src={imageStatus[product?.ItemCode] || product?.Picture || product?.vectorPicture || `${pathObj?.s3ImagePath}/no-image.jpg`}
                                    onError={(e) => {
                                      e.target.onerror = null; // Prevent infinite loop
                                      e.target.src = `${pathObj?.s3ImagePath}/no-image.jpg`; // Fallback image
                                    }}
                                    alt={product?.U_V33_WebProductName}
                                    onClick={() =>
                                      product?.ProductType === "single"
                                        ? handleProductDetailsClick(product)
                                        : handleProductGroupDetailsClick(product)
                                    }
                                  />
                                )}
                                {/* </Link> */}
                              </div>
                              <div className="productContent" style={{ minHeight: '220px' }}>
                                <div className="instock_row">
                                  {product?.ProductType === "single" &&
                                    <>
                                      {product?.QuantityOnStock ? (
                                        <div className="instock">
                                          <img
                                            src={`${pathObj?.s3ImagePath}/instock-icon.svg`}
                                            alt="In Stock"
                                          />{" "}
                                          In Stock
                                        </div>
                                      ) : (
                                        <div className="outofstock">
                                          <img
                                            src={`${pathObj?.s3ImagePath}/out-stock.svg`}
                                            alt="Currently not available"
                                          />{" "}
                                          Currently not available
                                        </div>
                                      )}
                                      {token && (
                                        <div className="wishlist">
                                          <img
                                            src={`${pathObj?.s3ImagePath}/wishlist-icon${wishlistStatus[product?.id] ? '-red' : ''}.svg`} // Check status from wishlistStatus state
                                            alt="Wishlist Icon"
                                            onClick={() => handleWishlistToggle(product?.id)}
                                            style={{ cursor: 'pointer' }}
                                          />
                                        </div>
                                      )}
                                    </>
                                  }
                                </div>
                                {(token && product?.ProductType === "single") && (
                                  <>
                                    <div className="prd_rating">
                                      {[1, 2, 3, 4, 5]?.map((rating) => {
                                        const isFullStar = rating <= Math.floor(product?.rating); // Check for full stars
                                        const isHalfStar = rating > Math.floor(product?.rating) && rating < Math.ceil(product?.rating); // Check for half star
                                        return (
                                          <img
                                            key={rating}
                                            src={isFullStar ? `${pathObj?.s3ImagePath}/rating-icon.svg` : isHalfStar ? `${pathObj?.s3ImagePath}/half-rating-icon.svg` : `${pathObj?.s3ImagePath}/empty-rating-icon.svg`}
                                            alt={`${rating} stars`}
                                            onClick={() => {
                                              handleRatingChange(product?.id, rating);
                                            }}
                                          />
                                        );
                                      })}
                                    </div>
                                    <div className="prd_price">
                                      <span className="old_price"></span>
                                      <span className="special_price">
                                        {currencySymbol}{product?.Csprice > 0 ? product?.Csprice : product?.MinProductPrice ? product?.MinProductPrice : product?.MainProductPrice}
                                      </span>
                                      { product?.GbpPriceBreakdown > 0 || product?.EuroPriceBreakdown > 0 ? <div className="priceBadge">Click to see price break</div> : ''}
                                    </div>
                                  </>
                                )}
                                {product?.ProductType === "single" ? (
                                  <>
                                    <h4>{product?.ItemCode}</h4>
                                    <p
                                      onClick={() => handleProductDetailsClick(product)}
                                      className="productNameStyle"
                                      title={product?.U_V33_WebProductName} // Show full name on hover
                                    >
                                      {product?.U_V33_WebProductName}
                                    </p>
                                  </>
                                ) : (
                                  <>
                                    {/* <h4>{product?.ItemCode}</h4> */}
                                    <p
                                      onClick={() => handleProductGroupDetailsClick(product)}
                                      className="productNameStyle"
                                      title={`${product?.U_V33_WebProductName}`} // Show full name on hover
                                    >
                                      {product?.U_V33_WebProductName}
                                    </p>
                                  </>
                                )}
                                {token && product?.ProductType === "single" ? (
                                  <>
                                    <div className="add_qty">
                                      <div className="qty_plusminus" 
                                           onClick={() => togglePriceDropdown(product?.ItemCode)}
                                        style={{ cursor: "pointer" }}>
                                        <button
                                          type="button"
                                          data-type="minus"
                                          onClick={() => handleQuantityChange(product?.ItemCode, "minus")}
                                        >
                                          <img
                                            src={`${pathObj?.s3ImagePath}/minus-icon.svg`}
                                            alt=""
                                          />
                                        </button>
                                        <input
                                          type="text"
                                          value={quantities[product?.ItemCode] || 1}
                                          onClick={() => togglePriceDropdown(product?.ItemCode)}
                                          readOnly
                                        />
                                        <button
                                          type="button"
                                          data-type="plus"
                                          onClick={() => handleQuantityChange(product?.ItemCode, "plus")}
                                        >
                                          <img
                                            src={`${pathObj?.s3ImagePath}/plus-icon.svg`}
                                            alt=""
                                          />
                                        </button>
                                      </div>
                                      <div className="add_btn">
                                        <a
                                          href="#"
                                          className="button2"
                                          onClick={() => handleAddToCart(product)} // Call the function on click
                                        >
                                          Add
                                        </a>
                                      </div>
                                    </div>

                                    <div
                                      className="price_dropdow"
                                      style={{
                                        display: priceDropdownVisible[product?.ItemCode] && priceBreak[product?.ItemCode]?.length > 0 ? "block" : "none",
                                      }}
                                    >

                                      <div className="more_qty">
                                        {loading ? (
                                          <div className="loader"></div>
                                        ) : (
                                          priceBreak[product?.ItemCode]?.map((priceBreakdown, index) => {
                                            return (
                                              <div
                                                className="more_qty_row"
                                                key={priceBreakdown.id}
                                                onClick={() => handlePriceBreakdownClick(product?.ItemCode, priceBreakdown?.Quantity, priceBreakdown?.price_break_down_id)}
                                                style={{ cursor: "pointer" }}
                                              >
                                                <label>{priceBreakdown?.Quantity} qty</label>
                                                <span>{currencySymbol}{UpdatedDecimalDigits(priceBreakdown?.SpecialPrice)}</span>
                                              </div>
                                            )
                                          })
                                        )}
                                      </div>
                                    </div>

                                  </>
                                ) : (
                                  <>
                                    {product?.ProductType === "single" ? (
                                      <Link to="/login">
                                        <input className="button4" type="submit" value=" Login to see price" />
                                      </Link>
                                    ) : (
                                      (() => {
                                        const formattedItemCode = product?.ItemCode?.replace(/\//g, '__');
                                        return (
                                          <div class="view_variations_btn">
                                            <Link className="button4" to={`/group-products/${formattedItemCode}`} >
                                              View Variations
                                            </Link>
                                          </div>
                                        );
                                      })()
                                    )}
                                  </>

                                )}
                              </div>
                            </li>
                          );
                        })
                      ) : (
                        <div className="notFound_record">
                          <h4
                            className="not_found_category">
                            {errorText && errorText}
                          </h4>
                        </div>
                      )}
                    </ul>

                  </div>
                  <div className="pagination_box">
                    {(listTotal > limit) && (
                      <Pagination
                        activePage={pageNo}
                        itemsCountPerPage={limit}
                        totalItemsCount={listTotal}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    )}
                  </div>
                </>
              )}

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewProduct;
