import React, { useEffect, useRef, useState } from 'react';
import { CommonService } from '../../services/common';
import pathObj from '../../services/Config';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { errorHandling } from '../../helpers/common';

const CarouselSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
    };

    const [bannerInfo, setBannerInfo] = useState([]);
    const hasFetched = useRef(false);

    useEffect(() => {
        const fetchBannerInfo = async () => {
            if (hasFetched.current) return;

            try {
                const data = await CommonService.getListing(`${pathObj.baseURL}${pathObj.getHeaderBannerInfo}`);
                setBannerInfo(data?.data || []);
                hasFetched.current = true;
            } catch (error) {
                console.error("Error fetching banner info:", error);
                errorHandling(error);
            }
        };
        if (!hasFetched.current) {
            fetchBannerInfo();
            hasFetched.current = true;
        }
    }, []);

    // Duplicate items if there's only one to ensure the slider functions properly
    const itemsToShow = bannerInfo.length === 1 ? [...bannerInfo, ...bannerInfo] : bannerInfo;

    if (bannerInfo.length === 0) {
        return false
    }

    return (
        <>
        {bannerInfo?.length > 0 &&
                <div className="banner">
                    <Slider {...settings}>
                        {itemsToShow.map((item, index) => (
                            <div id="banner">
                                <div className='bannerBgImg' style={{ backgroundImage: `url(${item?.image})` }}>
                                    <div className="container">
                                        <div className="item">
                                            <div className="banner_content">
                                                <h4>{item?.title}</h4>
                                                <h2>{item?.subTitle}</h2>
                                                <a href={item?.buttonSlug} className="button1">{item?.buttonText}</a>
                                            </div>
                                            {/* <div className="banner_img">
                                                <img src={item?.image} alt={item?.title} />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider >
                </div>
    }
    </>
    );
}

export default CarouselSlider;
