import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { CommonService } from '../../../src/services/common';
import pathObj from '../../../src/services/Config';
import { CartContext } from "../../context/cartContext";
import { decryptData, errorHandling } from '../../helpers/common';
import { AuthContext } from '../../context/AuthContext';
const Footer = () => {
  const [footerInfo, setFooterInfo] = useState(null);
  const FooterAddress = footerInfo?.FooterAddress
  const NewsTechnicals = footerInfo?.NewsTechnicals
  const UserfulLinks = footerInfo?.UserfulLinks
  const LinksDocuments = footerInfo?.LinksDocuments
  const {sideNavigationDetails} = useContext(CartContext);
  const {user} = useContext(AuthContext);
  const hasFetched = useRef(false);
  const userInfo = window.localStorage.getItem('userData');
  const userD = decryptData(userInfo);
  const compData = JSON.parse(userD);
  const customerId = compData?.customer_id;
  const Currency = compData?.Currency;
  const PriceListNum = compData?.PriceListNum;
  const { addToCartCount } = useContext(CartContext);
  const category = localStorage.getItem('categoriesName');

  useEffect(() => {
    const fetchFooterInfo = async () => {
      try {
        const data = await CommonService.getListing(`${pathObj.baseURL}${pathObj.getFooterInfo}`);
        setFooterInfo(data?.data);
        sideNavigationDetails(data?.data)
      } catch (error) {
        console.error("Error fetching footer info:", error);
      }
    };
    if (!hasFetched.current) {
      fetchFooterInfo();
      hasFetched.current = true;
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const fetchCartData = async () => {
    try {
      const customer_id = customerId;
      const urlCart = `${pathObj.baseURL}${pathObj.getCartDetails}`;
      const payloadCart = { customer_id, category, Currency, PriceListNum };

      // Fetch cart details
      const cartData = await CommonService.getAllWithBodyWithToken(urlCart, payloadCart);
      if (cartData?.data) {
        localStorage.setItem('cartItems', JSON.stringify(cartData?.data));
        window.dispatchEvent(new Event("cartUpdate"));
      }
    } catch (error) {
      console.error("Error fetching page data:", error);
      errorHandling(error);
    }
  };

  useEffect(()=>{
    if(user?.token){
      fetchCartData()
    }
  },[user?.token])



  return (
    <>
      <div className="footer">

        <div className="container">

          <div className="footer_row">

            <div className="footer_bx">

              <h3>Contact Us</h3>

              <div className="footer_address">

                <div className='address'>
                  {FooterAddress?.address1}<br />
                  {FooterAddress?.address2}<br />
                  {FooterAddress?.city}<br />
                  {FooterAddress?.state}<br />
                  {FooterAddress?.zipcode}
                </div><br />

                <p><img src={`${pathObj?.s3ImagePath}/call-icon.svg`} /> <Link to={`tel:${FooterAddress?.fax}`} >{FooterAddress?.fax}</Link></p>
                <p><img src={`${pathObj?.s3ImagePath}/call-icon.svg`} /> <Link to={`tel:${FooterAddress?.phone}`}>{FooterAddress?.phone}</Link></p>
                <p><img src={`${pathObj?.s3ImagePath}/envelope-icon.svg`} alt="Envelope Icon" /><Link to={`mailto:${FooterAddress?.mail}`}>{FooterAddress?.mail}</Link>
                </p>
              </div>

              <div className="footer_social_icons">
                <h3>Follow Us</h3>
                <Link to={FooterAddress?.facebook_url}><img src={`${pathObj?.s3ImagePath}/fb-icon.svg`} /></Link>
                <Link to={FooterAddress?.instagram_url}><img src={`${pathObj?.s3ImagePath}/instagram-icon.svg`} /></Link>
                <Link to={FooterAddress?.linkedin_url}><img src={`${pathObj?.s3ImagePath}/linkdin-icon.svg`} /></Link>
              </div>
            </div>

            <div className="footer_bx">
              <h3>Useful Information</h3>
              <ul>
                {UserfulLinks && UserfulLinks?.map((item, index) => (
                  <li key={index}><Link to={`/${item?.page_type}`} onClick={scrollToTop}>{item?.title}</Link></li>
                ))}
              </ul>
            </div>

            <div className="footer_bx">

              <h3>News & Technical</h3>

              <ul>

                {NewsTechnicals && NewsTechnicals?.map((item, index) => (
                  <li key={index}>
                    <Link
                      to={item?.page_type === 'Company_News' || item?.page_type === 'exhibitions'
                        ? `/news/${item?.page_type}`
                        : `/${item?.page_type}`}
                      onClick={scrollToTop}>
                      {item?.title}
                    </Link>
                  </li>
                ))}

              </ul>

            </div>

            <div className="footer_bx">

              <h3>Links & Documents</h3>

              <ul>
              <li>
                  <Link to='/account_application' onClick={scrollToTop}>Account Application</Link>
                </li>{LinksDocuments && LinksDocuments?.map((item, index) => (
                  <li key={index}><Link to={`/${item?.page_type}`} onClick={scrollToTop}>{item?.title}</Link></li>
                ))}
              </ul>

            </div>

            <div className="footer_bx">

              <h3>Business Hours</h3>

              <div className="office_time">

                <div className="daytime">
                  <div className="day">Mon</div>
                  <div className="time">9am - 5pm</div>
                </div>
                <div className="daytime">
                  <div className="day">Tue</div>
                  <div className="time">9am - 5pm</div>
                </div>
                <div className="daytime">
                  <div className="day">Wed</div>
                  <div className="time">9am - 5pm</div>
                </div>
                <div className="daytime">
                  <div className="day">Thu</div>
                  <div className="time">9am - 5pm</div>
                </div>
                <div className="daytime">
                  <div className="day">Fri</div>
                  <div className="time">9am - 5pm</div>
                </div>
                <div className="daytime">
                  <div className="day">Sat</div>
                  <div className="time">closed</div>
                </div>
                <div className="daytime">
                  <div className="day">Sun</div>
                  <div className="time">closed</div>
                </div>

              </div>

            </div>

          </div>

          <div className="copyright">© {new Date().getFullYear()} H Davenport & Sons Ltd. All rights reserved. Registered in England & Wales No. 1026710</div>

        </div>

      </div>
    </>

  )
}

export default Footer
