import { BrowserRouter } from "react-router-dom";
import './App.css';
import PublicRoute from "./routers/PublicRoute";
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/assets/css/custom.css";
import "../src/assets/css/menu.css";
import AuthProvider from "./context/AuthContext";
import { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";
function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Header />
        <Helmet>
          <title>Davenport Burgess</title>
        </Helmet>
        <section className="middle_wrapper">
          <PublicRoute />
        </section>
        <Footer />
        <Toaster position="top-center" reverseOrder={false} toastOptions={{ duration: 5000 }} />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
