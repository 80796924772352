import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommonService } from '../services/common';
import pathObj from '../services/Config';
import { errorMessage } from '../helpers/common';

const LoopFobs = () => {
  const [pageData, setPageData] = useState(null);
  const [lastPageType, setLastPageType] = useState(null);
const pageType = 'about_us'
  useEffect(() => {
    if (!pageType) {
      console.error("Error: pageType is undefined.");
      return;
    }

    if (pageType !== lastPageType) {
      const fetchData = async () => {
        const formData = new FormData();
        formData.append('page_type', pageType);

        try {
          const data = await CommonService.getAllWithBodyFile(`${pathObj.baseURL}${pathObj.pageDetail}`, formData);
          setPageData(data?.data);
          setLastPageType(pageType);
        } catch (error) {
          errorMessage(error);
        }
      };

      fetchData();
    }
  }, [pageType, lastPageType]);

  // Sidebar items mapping
  const sidebarItems = [
    { title: 'Delivery', link: '/delivery' },
    { title: 'About Us', link: '/about_us' },
    { title: 'Our History', link: '/our_history' },
    { title: 'Contact Us', link: '/contact_us', subItems: [
      { title: 'Contact Details', link: '#' },
      { title: 'How to find us', link: '#' },
    ] },
    { title: 'Accessibility', link: '/accessibility' },
    { title: 'Privacy Policy', link: '/privacy_policy' },
    { title: 'Cookies Policy', link: '/cookies_policy' },
    { title: 'Terms & Conditions', link: '/terms_conditions' },
  ];

  return (
    <>
      {pageData ? (
          <div className="cetogry_listing">
            <div className="container">
              <div className="clinner d-flex flex-wrap align-items-start">
                <div className="clSidebar common_sidebar">
                  <ul>
                    {sidebarItems.map((item, index) => (
                      <li key={index}>
                        <a href={item.link} className={pageData[0]?.title === item.title ? 'active' : ''}>
                          {item.title}
                          {item.subItems && (
                            <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M18.38 1.35102C18.38 1.04574 18.2877 0.759549 18.118 0.542041C17.7698 0.095576 17.2042 0.095576 16.8559 0.542041L10.3428 8.89131C10.1136 9.18514 9.78911 9.35304 9.44976 9.35304C9.11042 9.35304 8.78595 9.18514 8.55674 8.89131L2.04362 0.542041C1.87097 0.320716 1.64176 0.206238 1.41255 0.206238C1.18335 0.206238 0.954136 0.3169 0.781485 0.542041C0.608833 0.767181 0.519531 1.04574 0.519531 1.35102C0.519531 1.65629 0.61181 1.94249 0.781485 2.16L7.2946 10.5093C7.86911 11.2457 8.63711 11.6541 9.44976 11.6541C10.2624 11.6541 11.0304 11.2457 11.6049 10.5093L18.118 2.16C18.2877 1.94249 18.38 1.65629 18.38 1.35102Z" fill="currentColor" />
                            </svg>
                          )}
                        </a>
                        {item.subItems && (
                          <ul>
                            {item.subItems.map((subItem, subIndex) => (
                              <li key={subIndex}>
                                <a href={subItem.link} className={pageData[0]?.title === subItem.title ? 'active' : ''}>
                                  {subItem.title}
                                </a>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="common_right_block">
                  <div className="common_content">
                    <h1>Loop Fobs</h1>
                    <p dangerouslySetInnerHTML={{ __html: pageData[0]?.content}}></p>
                    {/* More content 
                    <h3>{pageData[0]?.meta_title}</h3>
                    <p>By the mid 1970's, H. Davenport & Sons Ltd had outgrown the extended workshop in Victoria Road...</p>
                    More content */}
                  </div>
                </div>
              </div>
            </div>
          </div>
      ) : null}
    </>
  );
};

export default LoopFobs
