import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import pathObj from '../../../src/services/Config';
import { useAuth } from '../../hooks/useAuth';
const Login = () => {
    const [loading, setLoading] = useState(false);
    const [rememberMe, setRememberMe] = useState(false)

    // ** Hooks
    const auth = useAuth()

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = data => {
        setLoading(true)
        const { email, password } = data
        auth.signIn({ email, password, rememberMe }, (resp) => {
            if (resp?.response?.status !== '') {
                setLoading(false)
            }
        });

    };

    return (
        <>
            <div className="login_register">
                <div className="container">
                    <div className="lrinner d-flex flex-wrap">
                        <div className="login_img"><img src={`${pathObj?.s3ImagePath}/login-img.jpg`} alt="Login" /></div>
                        <div className="login_form">
                            <h1>Login</h1>
                            <div className="common_form">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form_group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Email"
                                            {...register('email', { required: true })}
                                        />
                                        {errors.email && <span style={{ color: '#eb3434' }}>Email is required</span>}
                                    </div>
                                    <div className="form_group">
                                        <input
                                            className="form-control"
                                            type="password"
                                            placeholder="Password"
                                            {...register('password', { required: true })}
                                        />
                                        {errors.password && <span style={{ color: '#eb3434' }}>Password is required</span>}
                                    </div>
                                    <div className="fplink text-end"><Link to="/forgot-password">Forgot password?</Link></div>
                                    <div className="login_btn">
                                        <button className={`button2 ${loading ? 'disabled' : ''}`} type="submit" disabled={loading}>
                                            {loading ? ('Loading...') : ('Login')}
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div className="login_bottom_content pad_t30">
                                <h3>Request a new web account</h3>
                                <p>Click 'Request New Account' to set up a new web account.</p>
                                <div className="pt-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="flexCheckDefault" />
                                        <label className="form-check-label" htmlFor="flexCheckDefault">
                                            I already have a trading account with Davenport Burgess
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="flexCheckChecked" defaultChecked />
                                        <label className="form-check-label" htmlFor="flexCheckChecked">
                                            I am new to Davenport Burgess
                                        </label>
                                    </div>
                                </div>
                                <div className="pad_t30"><Link className="button1" to="/register-account">Request New Account</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
