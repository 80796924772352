import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const ThankYouPage = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const message = location.state?.message || 'Your Payment is processed and order has been successfully submitted.';

    const handleViewOrder = () => {
        navigate('/order-history')
    }

    return (
        <div className="thankYou_bx pad_t80 pad_b75">
            <div className='container'>
                <div className="d-flex justify-content-center">
                    <div className="card col-xl-5 col-lg-8 col-md-10 col-sm-10 bg-white shadow-lg p-5">
                        <div className="mb-4 text-center" style={{ color: 'green' }}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-success" width="75" height="75"
                                fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                <path
                                    d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                            </svg>
                        </div>
                        <div className="text-center">
                            <h1>Thank You !</h1>
                            <p className='mb-4 fs-6'>{message}</p>
                            <button className="button2" onClick={handleViewOrder} >View Order</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ThankYouPage