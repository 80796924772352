import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom"; // import useNavigate
import pathObj from '../../services/Config';
import { CommonService } from '../../services/common';
import NewsSideBar from "./NewsSideBar";
import { monthMapping } from "../../helpers/common";
import { Helmet } from "react-helmet";

const NewsDetails = () => {
  const { type, newsId } = useParams();
  const navigate = useNavigate(); // useNavigate to programmatically navigate
  const hasFetched = useRef(false);
  const [newsDetails, setNewsDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [newsSideBar, setNewsSideBar] = useState([]);
  const monthMapData = monthMapping();

  // Fetch sidebar data and handle default sidebar click based on type
  useEffect(() => {
    const fetchSideBarData = async () => {
      try {
        const response = await CommonService.getListing(`${pathObj.baseURL}${pathObj?.newsCategories}`);
        if (response?.data) {
          setNewsSideBar(response.data);
        }
      } catch (error) {
        console.error("Error fetching sidebar data:", error);
      }
    };

    const handleInitialSidebarClick = () => {
      switch (type) {
        case "Company_News":
          handleSidebarClick("");
          break;
        case "exhibitions":
          handleSidebarClick(2);
          break;
        case "all-news":
          handleSidebarClick("all");
          break;
        default:
          handleSidebarClick(type);
          break;
      }
    };

    fetchSideBarData();
    handleInitialSidebarClick();
  }, [type]);

  const handleSidebarClick = async (id, isArchive = false) => {
    try {
      let url;

      if (isArchive) {
        url = buildArchiveUrl(id);
      } else {
        url = id === "all"
          ? `${pathObj.baseURL}${pathObj?.getNewsList}?type=`
          : `${pathObj.baseURL}${pathObj?.getNewsList}?type=${id}`;
      }

      const response = await CommonService.getListing(url);
      if (response?.success) {
        const data = response.data;
      }
    } catch (error) {
      console.error("Error fetching page data:", error);
    }
  };

  const buildArchiveUrl = (id) => {
    if (id.includes('-')) {
      const [year, monthName] = id.split('-');
      const monthMap = monthMapData;
      const month = monthMap[monthName];
      return `${pathObj.baseURL}${pathObj?.getNewsList}?year=${year}&month=${month}`;
    }
    return `${pathObj.baseURL}${pathObj?.getNewsList}?year=${id}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const url = `${pathObj.baseURL}${pathObj.newsDetail.trim()}?id=${newsId}`;
        const response = await CommonService.getListing(url);
        if (response?.data) {
          setNewsDetails(response.data);
        }
      } catch (error) {
        console.error("Error fetching news details:", error);
        if (error?.response?.status === 404) {
          navigate('/news/all-news');
        }
      } finally {
        setLoading(false);
      }
    };

    if (!hasFetched.current) {
      fetchData();
      hasFetched.current = true;
    }
  }, [newsId]);

  // Effect to check for mismatch between `type` and `newsDetails.page_type`
  useEffect(() => {
    if (type === "all-news") {
      return; // Do not navigate if type is "all-news"
    }

    if (newsDetails?.page_type && type && newsDetails?.page_type !== type) {
      navigate('/news/all-news');
    }
  }, [type, newsDetails?.page_type, navigate]);

  return (
    <>
      <Helmet>
        <title>{newsDetails?.meta_title}</title>
        <meta name="description" content={newsDetails?.meta_description} />
      </Helmet>
      <div className="cetogry_listing">
        <div className="container">
          <div className="clinner d-flex flex-wrap align-items-start">
            <NewsSideBar
              newsSideBar={newsSideBar}
              handleSidebarClick={handleSidebarClick}
              type={type}
              newsId={newsId}
            />
            {loading ? (
              <div className="loader"></div>
            ) : (
              <div className="common_right_block">
                <div className="common_content">
                  <h1>{newsDetails?.title}</h1>
                  <p><em>{new Date(newsDetails?.publish_date).toLocaleDateString()}</em></p>
                  <p dangerouslySetInnerHTML={{ __html: newsDetails?.content }}></p>
                  <p>&nbsp;</p>
                  <p>&nbsp;</p>
                  {newsDetails && <p><a href="#top">&nbsp;Back to the top</a></p>}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsDetails;
