import React, { useContext, useEffect, useState } from 'react';
import pathObj from '../../services/Config';
import { checkImage, decryptData, errorHandling, UpdatedDecimalDigits } from '../../helpers/common';
import { CommonService } from '../../services/common';
import toast from 'react-hot-toast';
import { CartContext } from '../../context/cartContext';
import { Link } from 'react-router-dom';
import getSymbolFromCurrency from 'currency-symbol-map';

const QuickSearch = ({ fetchCartData }) => {
    const userInfo = window.localStorage.getItem('userData');
    const userD = decryptData(userInfo);
    const compData = JSON.parse(userD);
    const customerId = compData?.customer_id;
    const currencyName = compData?.Currency
    const currencySymbol = getSymbolFromCurrency(currencyName);
    const [imageStatus, setImageStatus] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [quickSearchProduct, setQuickSearchProduct] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [priceDropdownVisible, setPriceDropdownVisible] = useState({});
    const [priceBreakDown, setPriceBreakDown] = useState('');
    const [NoResultFound, setNoResultFound] = useState('');
    const { addToCartCount } = useContext(CartContext);
    const [selectedPrices, setSelectedPrices] = useState({});
    const payTermsGrp = compData?.PayTermsGrpCode;

    const handleQuantityChange = (itemCode, type, customQuantity = null) => {
        setQuantities((prevQuantities) => {
            const updatedQuantities = { ...prevQuantities };
            let currentQuantity = updatedQuantities[itemCode] || quickSearchProduct.find(item => item.ItemCode === itemCode)?.quantity || 1;

            if (customQuantity !== null) {
                currentQuantity = parseInt(customQuantity, 10);
            } else {
                if (type === 'plus') {
                    currentQuantity += 1;
                } else if (type === 'minus' && currentQuantity > 1) {
                    currentQuantity -= 1;
                }
            }

            updatedQuantities[itemCode] = currentQuantity;

            setPriceDropdownVisible((prevVisible) => ({
                ...prevVisible,
                [itemCode]: currentQuantity > 1
            }));

            return updatedQuantities;
        });
    };

    const togglePriceDropdown = (itemCode) => {
        if (!priceDropdownVisible[itemCode]) {
            setPriceDropdownVisible((prevVisible) => ({
                ...prevVisible,
                [itemCode]: !prevVisible[itemCode],
            }));
        }
    };

    const handlePriceBreakdownClick = (itemCode, quantity, priceBreakDownId, specialPrice) => {
        setPriceBreakDown(priceBreakDownId);
        setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [itemCode]: quantity, // Update the quantity for this product
        }));
        setSelectedPrices((prevPrices) => ({
            ...prevPrices,
            [itemCode]: specialPrice, // Store the special price for the selected quantity
        }));
    };


    const handleQuickSearchChange = (e) => setSearchQuery(e.target.value);

    const handleQuickSearchSubmit = async (e) => {
        e.preventDefault();
        try {
            const formattedSearchQuery = searchQuery?.replace(/\//g, '__')
            const payload = {
                itemCode: formattedSearchQuery,
                customer_id: customerId,
            };

            const data = await CommonService.getAllWithBodyWithToken(`${pathObj.baseURL}${pathObj.quickCartSearch}`, payload);
            if (data?.success) {
                setQuickSearchProduct(data?.data?.products);
            } else {
                setQuickSearchProduct([]);
                setNoResultFound(data?.response?.data?.message)
            }
        } catch (error) {
            setNoResultFound(error?.response?.data?.message)
            setQuickSearchProduct([]);
            errorHandling(error);
        }
    };


    useEffect(() => {
        const loadImages = async () => {
            const status = { ...imageStatus }; // Copy the current image status
            for (const product of quickSearchProduct) {
                const pictureCheck = await checkImage(product?.Picture);
                const vectorPictureCheck = await checkImage(product?.vectorPicture);

                // Check if the picture URL works, if not check vectorPicture, if both fail use the no-image.jpg
                if (pictureCheck?.status === "ok") {
                    status[product?.ItemCode] = pictureCheck?.path;
                } else if (vectorPictureCheck?.status === "ok") {
                    status[product?.ItemCode] = vectorPictureCheck?.path;
                } else {
                    status[product?.ItemCode] = `${pathObj?.s3ImagePath}/no-image.jpg`;
                }

                // Update image status for the current product as soon as it's ready
                setImageStatus((prevStatus) => ({ ...prevStatus, [product?.ItemCode]: status[product?.ItemCode] }));
            }
        };

        if (quickSearchProduct?.length > 0) {
            loadImages();
        }
    }, [quickSearchProduct]);

    const handleAddToCart = async (product) => {
        if (product?.QuantityOnStock > 0 || (product?.QuantityOnStock <= 0 && payTermsGrp != 17)) {
            const quantity = quantities[product?.ItemCode] || 1;
            const priceBDownIdToUse = priceBreakDown || ''; // Use the selected price breakdown ID
            const product_price_id = product?.ProductPrice?.[0]?.id || '';
            const url = `${pathObj.baseURL}${pathObj.addCart}`;
            const payload = {
                customer_id: customerId,
                product_id: product?.id,
                quantity,
                price_break_down_id: priceBDownIdToUse,
                product_price_id,
                product_itemcode: product?.ItemCode,
                customer_cardcode: compData?.CardCode,
            };

            try {
                const response = await CommonService.getAllWithBodyWithToken(url, payload);
                if (response.success) {
                    toast.success(response.message || `${product?.ItemCode} added to cart successfully!`);
                    addToCartCount(product);
                    fetchCartData();

                    // Reset the search query and clear the product list
                    setSearchQuery('');
                    setQuickSearchProduct([]);
                    setNoResultFound('')
                } else {
                    toast.error(response.message || "Something went wrong!");
                }
            } catch (error) {
                console.error("Error adding product to cart:", error);
                errorHandling(error);
            }
        } else {
            toast.error("Item Currently not available!");
        }
    };


    const subtotal = quickSearchProduct.reduce((acc, item) => {
        const quantity = quantities[item.ItemCode] || 1;
        const price = selectedPrices[item.ItemCode] || item.MainProductPrice; // Use the selected special price or default to the main price
        return acc + (price * quantity);
    }, 0);


    return (
        <div className="cart_left quick_cart_tbl">
            <div className='addPrdTitle'>Add Product</div>
            <div className="cart_tbl">
                <table className="table">
                    <thead>
                        <tr>
                            <th className="product_thumbnail">Add Product</th>
                            <th className="product_description">Description</th>
                            <th className="product_quantity text-center">Quantity</th>
                            <th className="product_price">Price</th>
                            <th className="product_subtotal text-end">Subtotal</th>
                            {/* <th className="product_remove text-end">&nbsp;</th> */}

                        </tr>
                    </thead>
                    <tbody>
                        <div className='QuickSearchItem'>
                            <div className="Quicksearchingform show">
                                <form onSubmit={handleQuickSearchSubmit}>
                                    <input
                                        type="search"
                                        placeholder="Search products"
                                        value={searchQuery}
                                        onChange={handleQuickSearchChange}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && !searchQuery) {
                                                e.preventDefault(); // Prevent form submission if searchQuery is empty
                                            }
                                        }}
                                    />
                                </form>
                            </div>
                            <Link to="#" className="quick_search_icon">
                                <div className="icon" onClick={(e) => {
                                    if (searchQuery) handleQuickSearchSubmit(e);
                                }}>
                                    <img src={`${pathObj?.s3ImagePath}/search-icon.svg`} alt="img" /></div>
                            </Link>

                        </div>
                        {quickSearchProduct.length > 0 ? (
                            quickSearchProduct.map((item, index) => (
                                <tr key={index}>
                                    <td className="product_thumbnail" data-title="">

                                    </td>
                                    <td className="product_description" data-title="Description">
                                        {/* Product image */}
                                        {item?.QuantityOnStock && (
                                            <a href={`/products/${item?.ItemCode}`} target="_blank" tabIndex="-1">
                                                <img src={imageStatus[item?.ItemCode] || item?.Picture || item?.vectorPicture || `${pathObj?.s3ImagePath}/no-image.jpg`} alt={item?.U_V33_WebProductName} className="icon" style={{ width: '25px' }}
                                                    onError={(e) => {
                                                        e.target.onerror = null; // Prevent looping
                                                        e.target.src = `${pathObj?.s3ImagePath}/no-image.jpg`; // Fallback image
                                                    }} />
                                            </a>
                                        )}

                                        {/* Product name */}
                                        &nbsp;{item?.U_V33_WebProductName}
                                    </td>

                                    <td className="product_quantity text-center" data-title="Quantity">
                                        <div className="cart_qty" style={{ width: '100px' }}>
                                            <div className="qty_plusminus" 
                                            onClick={() => togglePriceDropdown(item?.ItemCode)}
                                                style={{ cursor: "pointer" }}>
                                                <button type="button" onClick={() => handleQuantityChange(item.ItemCode, 'minus')}>
                                                    <img src={`${pathObj.s3ImagePath}/minus-icon.svg`} alt="minus" />
                                                </button>
                                                <input
                                                    type="text"
                                                    style={{ width: '40px' }}
                                                    value={quantities[item?.ItemCode] || 1}
                                                    onChange={(e) => handleQuantityChange(item?.ItemCode, "input", e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === '-' || e.key === 'e') {
                                                            e.preventDefault(); // Prevent entering minus, plus, and exponential (e) symbols
                                                        }
                                                    }}
                                                />
                                                {/* <input style={{ width: '35px' }} type="text" value={quantities[item.ItemCode] || 1} readOnly /> */}
                                                <button type="button" onClick={() => handleQuantityChange(item.ItemCode, 'plus')}>
                                                    <img src={`${pathObj.s3ImagePath}/plus-icon.svg`} alt="plus" />
                                                </button>
                                            </div>
                                        </div>
                                        {priceDropdownVisible[item?.ItemCode] && item?.PricesBreakdown?.length > 0 && (
                                            <div className="price_dropdown">
                                                <div className="more_qty">
                                                    {item.PricesBreakdown.map((priceBreakdown, index) => (
                                                        <div
                                                            key={priceBreakdown.id}
                                                            className="more_qty_row"
                                                            onClick={() => handlePriceBreakdownClick(item?.ItemCode, priceBreakdown.Quantity, priceBreakdown.id, priceBreakdown.SpecialPrice)}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            <label>{priceBreakdown?.Quantity} qty</label>
                                                            <span>{currencySymbol}{UpdatedDecimalDigits(priceBreakdown?.SpecialPrice)}</span>
                                                        </div>
                                                    ))}

                                                </div>
                                            </div>
                                        )}
                                    </td>
                                    <td className='product_price' data-title="Price">
                                        {currencySymbol}{(UpdatedDecimalDigits(selectedPrices[item?.ItemCode]
                                            ? UpdatedDecimalDigits(selectedPrices[item?.ItemCode])
                                            : UpdatedDecimalDigits(item?.MainProductPrice || 0)
                                        ))}
                                    </td>
                                    <td className="product_subtotal text-end" data-title="Subtotal">
                                        {currencySymbol}{typeof subtotal === 'number' ? UpdatedDecimalDigits(subtotal) : '0.0000'}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                {NoResultFound &&
                                    <td colSpan="7" className="text-center quick_cart_prd_notFound" data-title="Description" dangerouslySetInnerHTML={{ __html: `${NoResultFound} <a href="/search/${searchQuery}">(click here to search)</a>` }} />
                                }
                            </tr>
                        )
                        }
                    </tbody>
                </table>
                <div className="quick_button">
                    {quickSearchProduct.length > 0 && (
                        <button className="button2" onClick={() => handleAddToCart(quickSearchProduct?.[0])}>
                            Add
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default QuickSearch;
