import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CommonService } from '../services/common';
import pathObj from '../services/Config';
import { errorMessage } from '../helpers/common';
import NotFound from './Notfound';
import { Helmet } from 'react-helmet';
import { CartContext } from '../context/cartContext';

const CommonLayoutUsefulInfo = () => {
  const { pageType } = useParams();
  const [pageData, setPageData] = useState([]);
  const [lastPageType, setLastPageType] = useState(null);
  const [loading, setLoading] = useState(false);
  const { navDetails } = useContext(CartContext);
  const NewsTechnicals = navDetails?.NewsTechnicals
  const UserfulLinks = navDetails?.UserfulLinks
  const LinksDocuments = navDetails?.LinksDocuments

  useEffect(() => {
    if (!pageType) {
      console.error("Error: pageType is undefined.");
      return;
    }

    if (pageType !== lastPageType) {
      const fetchData = async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append('page_type', pageType);

        try {
          const data = await CommonService.getAllWithBodyFile(`${pathObj.baseURL}${pathObj.pageDetail}`, formData);
          setPageData(data?.data);
          setLastPageType(pageType);
        } catch (error) {
          errorMessage(error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [pageType, lastPageType]);

  const getSidebarData = () => {
    // Check if pageType exists in usefulLinkData
    const link1 = UserfulLinks?.find((item) => item?.page_type === pageType);
    // const link2 = NewsTechnicals?.find((item) => item?.page_type === pageType);
    const link3 = LinksDocuments?.find((item) => item?.page_type === pageType);

    if (link1) {
      return UserfulLinks; // Return UserfulLinks if the pageType matches usefulLinkData
      // } else if (link2) {
      //   return NewsTechnicals; 
    } else if (link3) {
      return LinksDocuments; // Return LinksDocuments for specific pageTypes
    }

    return []; // Return empty array as default
  };

  const sidebarData = getSidebarData();

  return (
    <>
      {loading ? (<div className="loader"></div>) : (
        <>
          {pageData ? (
            <>
              <Helmet>
                <title>{pageData[0]?.meta_title || "Default Title"}</title>
                <meta name="description" content={pageData[0]?.meta_description || "Default Description"} />
              </Helmet>
              <div className="cetogry_listing">
                <div className="container">
                  <div className="clinner d-flex flex-wrap align-items-start">
                    <div className="clSidebar common_sidebar">
                      <ul>
                      <li><a className="active">{pageType.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}</a></li>
                        {LinksDocuments?.find((item) => item?.page_type === pageType) && <li><a href={`/account_application`}>Account Application</a></li>}
                        {/* {UserfulLinks?.find((item) => item?.page_type === pageType) && <li><a href={`/loop-fobs`}>Loop Fobs</a></li>} */}
                        {sidebarData?.map((item, index) => {
                          return (
                            <>
                            <li key={index}>
                              <a href={`/${item?.page_type}`} className={pageData[0]?.title === item?.title ? 'active' : ''}>
                                {item?.title}
                                {item?.subItems && <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M18.38 1.35102C18.38 1.04574 18.2877 0.759549 18.118 0.542041C17.7698 0.095576 17.2042 0.095576 16.8559 0.542041L10.3428 8.89131C10.1136 9.18514 9.78911 9.35304 9.44976 9.35304C9.11042 9.35304 8.78595 9.18514 8.55674 8.89131L2.04362 0.542041C1.87097 0.320716 1.64176 0.206238 1.41255 0.206238C1.18335 0.206238 0.954136 0.3169 0.781485 0.542041C0.608833 0.767181 0.519531 1.04574 0.519531 1.35102C0.519531 1.65629 0.61181 1.94249 0.781485 2.16L7.2946 10.5093C7.86911 11.2457 8.63711 11.6541 9.44976 11.6541C10.2624 11.6541 11.0304 11.2457 11.6049 10.5093L18.118 2.16C18.2877 1.94249 18.38 1.65629 18.38 1.35102Z" fill="currentColor" />
                                </svg>}
                              </a>
                              {item.subItems && (
                                <ul>
                                  {item.subItems.map((subItem, subIndex) => (
                                    <li key={subIndex}>
                                      <a href={subItem?.link} className={pageData[0]?.title === subItem?.title ? 'active' : ''}>
                                        {subItem?.title}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                            </>
                          )
                        })}
                      </ul>
                    </div>

                    <div className="common_right_block">
                      <div className="common_content">
                        <h1>{pageData[0]?.title}</h1>
                        <p dangerouslySetInnerHTML={{ __html: pageData[0]?.content }}></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : <NotFound />
          }
        </>
      )}
    </>
  );
};

export default CommonLayoutUsefulInfo;

