import React, { useContext, useEffect, useState, useRef, useCallback } from 'react'
import pathObj from '../../services/Config';
import { checkImage, decryptData, errorHandling, UpdatedDecimalDigits } from "../../helpers/common";
import { CommonService } from '../../services/common';
import { Link, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import debounce from 'lodash.debounce';
import { CartContext } from "../../context/cartContext";
import getSymbolFromCurrency from 'currency-symbol-map';
const RecentpurchaseItem = ({ fetchCartData }) => {
  const [recentPurchasesData, setRecentPurchasesData] = useState([]);
  const userInfo = window.localStorage.getItem('userData');
  const userD = decryptData(userInfo);
  const compData = JSON.parse(userD);
  const customerId = compData?.customer_id;
  const currencyName = compData?.Currency
  const currencySymbol = getSymbolFromCurrency(currencyName);
  const [priceDropdownVisible, setPriceDropdownVisible] = useState({});
  const [quantities, setQuantities] = useState({});
  const [priceBreakDown, setPriceBreakDown] = useState('');
  const [imageStatus, setImageStatus] = useState({});
  const [errorMessage, setErrorMessage] = useState('Products not found');
  const [wishlistStatus, setWishlistStatus] = useState({});
  const navigate = useNavigate();
  const { addToCartCount } = useContext(CartContext);
  const token = localStorage.getItem("Token");
  const [loading, setLoading] = useState(true);
  const payTermsGrp = compData?.PayTermsGrpCode;
  const fetchPurchaseItem = async () => {
    setLoading(true);
    try {
      const customer_id = customerId;
      const urlRecentPurchases = `${pathObj.baseURL}${pathObj.getRecentPurchsedItems}`; // New URL for recent purchases
      const payloadRecentPurchases = { customer_id }; // Payload for recent purchases (no category)
      // Fetch recent purchased items
      const recentPurchasesData = await CommonService.getAllWithBodyWithToken(urlRecentPurchases, payloadRecentPurchases);
      setRecentPurchasesData(recentPurchasesData?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMessage(error?.response?.data?.message)
      errorHandling(error);
    }
  };

  const debouncedFetchPurchaseItem = debounce(fetchPurchaseItem, 300); // Adjust the delay as needed

  useEffect(() => {
    debouncedFetchPurchaseItem(customerId);
    // Dependency array includes all variables that should trigger the API call
  }, [customerId]);

  const loadImages = useCallback(async () => {
    let status = { ...imageStatus }; // Create a copy of current image status

    const imagePromises = recentPurchasesData.map(async (product) => {
      const pictureCheck = await checkImage(product?.Picture);
      const vectorPictureCheck = await checkImage(product?.vectorPicture);

      // Update the status object for each product
      if (pictureCheck?.status === "ok") {
        status[product?.ItemCode] = pictureCheck?.path;
      } else if (vectorPictureCheck?.status === "ok") {
        status[product?.ItemCode] = vectorPictureCheck?.path;
      } else {
        status[product?.ItemCode] = `${pathObj?.s3ImagePath}/no-image.jpg`;
      }
    });
    await Promise.all(imagePromises);
    // Update the image status state once with the complete status object
    setImageStatus((prevStatus) => ({ ...prevStatus, ...status }));
  }, [recentPurchasesData, pathObj?.s3ImagePath, imageStatus]);

  const debouncedFetchLoadImages = debounce(loadImages, 300); // Adjust the delay as needed
  useEffect(() => {
    if (recentPurchasesData?.length > 0) {
      debouncedFetchLoadImages();
    }
  }, [recentPurchasesData]);

  const handleQuantityChange = (itemCode, type, customQuantity = null) => {
    setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      let currentQuantity = newQuantities[itemCode] || 1;
      currentQuantity = parseInt(currentQuantity)

      // If customQuantity is passed (from price breakdown), set it directly
      if (customQuantity !== null) {
        currentQuantity = parseInt(customQuantity);
      } else {
        currentQuantity = parseInt(currentQuantity)
        if (type === "plus") {
          currentQuantity += 1;
        } else if (type === "minus" && currentQuantity > 1) {
          currentQuantity -= 1;
        }
      }

      // Update the quantity for the product
      newQuantities[itemCode] = currentQuantity;

      // Show price dropdown if the quantity is updated manually
      if (customQuantity === null) {
        setPriceDropdownVisible((prevVisible) => ({
          ...prevVisible,
          [itemCode]: currentQuantity > 1,
        }));
      }

      return newQuantities;
    });
  };

  const togglePriceDropdown = (itemCode) => {
    if (!priceDropdownVisible[itemCode]) {
      setPriceDropdownVisible((prevVisible) => ({
        ...prevVisible,
        [itemCode]: !prevVisible[itemCode],
      }));
    }
  };

  const handlePriceBreakdownClick = (itemCode, quantity, priceBreakDownId) => {
    setPriceBreakDown(priceBreakDownId)
    handleQuantityChange(itemCode, null, quantity);
  };

  const handleProductDetailsClick = (ItemCode) => {
    const formattedItemCode = ItemCode.replace(/\//g, '__'); // Replace slashes with hyphens
    navigate(`/products/${formattedItemCode}`);
  };

  const handleWishlistToggle = async (productItemCode) => {
    const currentStatus = wishlistStatus[productItemCode] || false; // Get current status of the product
    const url = `${pathObj.baseURL}/saveFavoriteItem`; // Update with your API endpoint
    const payload = {
      customer_id: customerId,
      product_id: productItemCode,
      status: currentStatus ? 0 : 1, // Toggle wishlist status
    };

    try {
      const response = await CommonService.getAllWithBodyWithToken(url, payload);
      if (response.success) {
        // Update the wishlist status for the specific product
        setWishlistStatus((prevStatus) => ({
          ...prevStatus,
          [productItemCode]: !currentStatus, // Toggle the wishlist status
        }));
        toast.success(response?.message || "Wishlist updated successfully!");
        setTimeout(() => {
          fetchPurchaseItem();
        }, 300);
      } else {
        toast.error(response.message || "Failed to update wishlist.");
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
      toast.error("An error occurred while updating the wishlist.");
      errorHandling(error);
    }
  };

  const handleRatingChange = async (itemCode, rating) => {
    if (rating && itemCode) {
      const url = `${pathObj.baseURL}/saveRating`; // Update with your API endpoint
      const payload = {
        product_id: itemCode,
        customer_id: customerId,
        rating: rating,
      };
      try {
        const response = await CommonService.getAllWithBodyWithToken(url, payload);
        if (response.success) {
          toast.success(response?.message || "Rating submitted successfully!");
          setTimeout(() => {
            fetchPurchaseItem();
          }, 300);
        } else {
          toast.error(response.message || "Failed to submit rating.");
        }
      } catch (error) {
        console.error("Error submitting rating:", error);
      }
    };
  }

  const handleAddToCart = async (product) => {
    if (product?.QuantityOnStock > 0 || (product?.QuantityOnStock <= 0 && payTermsGrp != 17)) {
      const quantity = quantities[product?.ItemCode] || 1;
      // const priceBDownIdToUse = product?.PricesBreakdown?.length > 0 ? priceBreakDown : '';
      const product_price_id = product?.ProductPrice?.[0]?.id ? product?.ProductPrice?.[0]?.id : '';
      const url = `${pathObj.baseURL}${pathObj.addCart}`;
      const payload = {
        customer_id: customerId,
        product_id: product?.id,
        quantity,
        // price_break_down_id: priceBDownIdToUse,
        product_price_id,
        product_itemcode: product?.ItemCode,
        customer_cardcode: compData?.CardCode,
      };
      try {
        const response = await CommonService.getAllWithBodyWithToken(url, payload);
        if (response.success) {
          toast.success(response.message || `${product?.ItemCode} added to cart  successfully.!`);
          // Increment cart count by the quantity added
          // setCartCount(prevCount => prevCount + quantity);
          // updateCartCount((prevCount) => prevCount + cartCount);
          addToCartCount(product);
          fetchCartData();
        } else {
          toast.error(response.message || "Something went wrong!");
        }
      } catch (error) {
        console.error("Error adding product to cart:", error);
      }
    } else {
      toast.error("Item Currently not available!");
    }
  };


  return (
    <>
      <div className="cart_recent_items">
        <h2>Recently Bought Items</h2>
        {loading ? (
          <div className="loader"></div>
        ) : (
          <>
            {recentPurchasesData.length > 0 && (
              <div className="prd_grid">
                <div className="recent">

                  <ul>
                    {recentPurchasesData.length > 0 ? (
                      recentPurchasesData.slice(0, 6).map((product, index) => (
                        <li key={index}>
                          <div className="productThumb">
                            {!imageStatus[product?.ItemCode] ? (
                              <div className="loader"></div> // Show loader until image status is resolved
                            ) : (
                              <a href="#">
                                <img
                                  // src={ imageStatus[product?.ItemCode] || `${pathObj?.s3ImagePath}/no-image.jpg` }
                                  src={imageStatus[product?.ItemCode] || product?.Picture || `${pathObj?.s3ImagePath}/no-image.jpg`}
                                  onError={(e) => {
                                    e.target.onerror = null; // Prevent looping
                                    e.target.src = `${pathObj?.s3ImagePath}/no-image.jpg`; // Fallback image
                                  }}
                                  alt={product?.U_V33_WebProductName}
                                  onClick={() => handleProductDetailsClick(product?.ItemCode)}
                                />
                              </a>
                            )}
                          </div>
                          <div className="instock_row">
                            {product?.QuantityOnStock ? (
                              <div className="instock">
                                <img src={`${pathObj?.s3ImagePath}/instock-icon.svg`} alt="In Stock" /> In Stock
                              </div>
                            ) : (
                              <div className="outofstock">
                                <img src={`${pathObj?.s3ImagePath}/out-stock.svg`} alt="Currently not available" /> Currently not available
                              </div>
                            )}
                            <div className="wishlist">
                              <img
                                src={`${pathObj?.s3ImagePath}/wishlist-icon${wishlistStatus[product?.id] ? '-red' : ''}.svg`} // Check status from wishlistStatus state
                                alt="Wishlist Icon"
                                onClick={() => handleWishlistToggle(product?.id)}
                                style={{ cursor: 'pointer' }}
                              />
                            </div>
                          </div>
                          <div className="prd_rating">
                            {[1, 2, 3, 4, 5].map((rating) => {
                              const isFullStar = rating <= Math.floor(product?.rating); // Check for full stars
                              const isHalfStar = rating > Math.floor(product?.rating) && rating < Math.ceil(product?.rating); // Check for half star
                              return (
                                <img
                                  key={rating}
                                  src={isFullStar ? `${pathObj?.s3ImagePath}/rating-icon.svg` : isHalfStar ? `${pathObj?.s3ImagePath}/half-rating-icon.svg` : `${pathObj?.s3ImagePath}/empty-rating-icon.svg`}
                                  alt={`${rating} stars`}
                                  onClick={() => {
                                    handleRatingChange(product?.id, rating);
                                  }}
                                />
                              );
                            })}
                          </div>
                          <div className="prd_price">
                            <span className="old_price">
                              {/* {currencySymbol}{product?.MainProductPrice} */}
                            </span>
                            <span className="special_price">
                              {currencySymbol}{product?.Csprice > 0 ? product?.Csprice : product?.MinProductPrice ? product?.MinProductPrice : product?.MainProductPrice}
                            </span>
                            { product?.GbpPriceBreakdown > 0 || product?.EuroPriceBreakdown > 0 ? <div className="priceBadge">Click to see price break</div> : ''}
                          </div>
                          <h4>{product?.ItemCode}</h4>
                          <p onClick={() => handleProductDetailsClick(product?.ItemCode)}
                            style={{ cursor: "pointer", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "150px", display: "inline-block" }}
                            title={product?.U_V33_WebProductName}
                          >{product?.U_V33_WebProductName}</p>

                          {token ? (
                            <>
                              <div className="add_qty">
                                <div className="qty_plusminus" 
                                onClick={() => togglePriceDropdown(product?.ItemCode)}
                                  style={{ cursor: "pointer" }}>
                                  <button
                                    type="button"
                                    data-type="minus"
                                    onClick={() =>
                                      handleQuantityChange(product.ItemCode, "minus")
                                    }
                                  >
                                    <img src={`${pathObj?.s3ImagePath}/minus-icon.svg`} alt="" />
                                  </button>
                                  <input
                                    type="text"
                                    style={{ width: '40px' }}
                                    value={quantities[product?.ItemCode] || 1}
                                    onChange={(e) => handleQuantityChange(product?.ItemCode, "input", e.target.value)}
                                    onKeyDown={(e) => {
                                      if (e.key === '-' || e.key === 'e') {
                                        e.preventDefault(); // Prevent entering minus, plus, and exponential (e) symbols
                                      }
                                    }}
                                  />

                                  {/* <input
                                    type="text"
                                    value={quantities[product?.ItemCode] || 1}
                                    readOnly
                                  /> */}
                                  <button
                                    type="button"
                                    data-type="plus"
                                    onClick={() =>
                                      handleQuantityChange(product?.ItemCode, "plus")
                                    }
                                  >
                                    <img src={`${pathObj?.s3ImagePath}/plus-icon.svg`} alt="" />
                                  </button>
                                </div>
                                <div className="add_btn">
                                  <a
                                    // href="#"
                                    className="button2"
                                    onClick={() => handleAddToCart(product)}
                                  >
                                    Add
                                  </a>
                                </div>
                              </div>
                              <div className="price_dropdow" style={{ display: priceDropdownVisible[product?.ItemCode] && product?.PricesBreakdown?.length > 0 ? "block" : "none", }} >
                                <div className="more_qty">
                                  {product?.PricesBreakdown?.map((priceBreakdown, index) => {
                                    return (
                                      <div
                                        className="more_qty_row"
                                        key={priceBreakdown.id}
                                        onClick={() => handlePriceBreakdownClick(product?.ItemCode, priceBreakdown?.Quantity, priceBreakdown?.SpecialPrice)}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <label>
                                          {priceBreakdown?.Quantity}qty
                                        </label>
                                        <span>
                                          {currencySymbol}{UpdatedDecimalDigits(priceBreakdown?.SpecialPrice)}
                                        </span>
                                      </div>
                                    )
                                  })}
                                </div>
                              </div>
                            </>
                          ) : (
                            <Link to="/login">
                              <input className="button4" type="submit" value=" Login to see price" />
                            </Link>
                          )}
                        </li>
                      ))
                    ) : (
                      <h5 className="recent_product" style={{ textAlign: "center", paddingTop: "1rem" }}>
                        {errorMessage}
                      </h5>
                    )
                    }
                  </ul>
                </div>
              </div>
            )}
          </>
        )}
      </div>

    </>
  )
}

export default RecentpurchaseItem
