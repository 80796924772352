import React, { useState } from "react";
import { Link } from "react-router-dom";


const NewsSideBar = ({ newsSideBar, handleSidebarClick, type,selectedMonth }) => {
  const [openYear, setOpenYear] = useState(null);
  const handleClick = (item) => {
    if (item?.slug && item?.id) {
      handleSidebarClick(item?.id);
    } else {
      setOpenYear(openYear === item.year ? null : item.year);
      handleSidebarClick(item.year, true);
    }
  };
  

  return (
    <div className="clSidebar common_sidebar">
      <ul>
        <li><a className="active">Filter by</a></li>
        {newsSideBar?.filter_by?.map((item) => (
          <li key={item.id}>
            <Link
              to={`/news/${item?.id}`}
              onClick={() => handleClick(item)}
              className={type == item.id ? "active" : ""}
            >
              {item.title}
            </Link>
          </li>
        ))}
        <li>
          <Link
            to="/news/all-news"
            onClick={() => handleSidebarClick("all")}
            className={(type === "all-news" || type ===  "company-news") ? "active" : ""}
          >
            All Categories
          </Link>
        </li>
        <li><a className="active">News Archive</a></li>
        {Object.entries(newsSideBar?.news_archive || {}).map(([year, months]) => (
          <li key={year}>
            <Link
              to={`/news/${year}`}
              className={type == year ? "active" : ""}
              onClick={() => handleClick({ year })}
            >
              {year}
            </Link>
            {openYear === year && (
              <ul className="submenu">
                {months.map((month) => {
                  return (
                  <li key={month}>
                    <Link
                       to={`/news/${year}/${month}`}
                      onClick={() => handleSidebarClick(`${year}-${month}`, true)}
                      className={ selectedMonth === month ? "active" : ""}
                    >
                      {month}
                    </Link>
                  </li>
                )})}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NewsSideBar;
