import React from 'react'
import { Helmet } from 'react-helmet';

const ContactDetails = () => {

    // Sidebar items mapping
    const sidebarItems = [
      { title: 'Delivery', link: '/delivery' },
      { title: 'About Us', link: '/about_us' },
      { title: 'Our History', link: '/our_history' },
      {
        title: 'Contact Us', link: '/contact_us', subItems: [
          { title: 'Contact Details', link: '/contact_details' },
          { title: 'How to find us', link: '#' },
        ]
      },
      { title: 'Accessibility', link: '/accessibility' },
      { title: 'Privacy Policy', link: '/privacy_policy' },
      { title: 'Cookies Policy', link: '/cookies_policy' },
      { title: 'Terms & Conditions', link: '/terms_conditions' },
    ];
    
  return (
    <>
     <Helmet>
        <title>Contact Details</title>
        <meta name="description" content="Contact details" />
      </Helmet>
      <div class="cetogry_listing">

        <div class="container">

          <div class="clinner d-flex flex-wrap align-items-start">

          <div className="clSidebar common_sidebar">
              <ul>
                {sidebarItems.map((item, index) => (
                  <li key={index}>
                    <a href={item.link} className={item.title ? '' : ''}>
                      {/* <a href={item.link} className={pageData[0]?.title === item.title ? 'active' : ''}> */}
                      {item.title}
                      {item.subItems && (
                        <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M18.38 1.35102C18.38 1.04574 18.2877 0.759549 18.118 0.542041C17.7698 0.095576 17.2042 0.095576 16.8559 0.542041L10.3428 8.89131C10.1136 9.18514 9.78911 9.35304 9.44976 9.35304C9.11042 9.35304 8.78595 9.18514 8.55674 8.89131L2.04362 0.542041C1.87097 0.320716 1.64176 0.206238 1.41255 0.206238C1.18335 0.206238 0.954136 0.3169 0.781485 0.542041C0.608833 0.767181 0.519531 1.04574 0.519531 1.35102C0.519531 1.65629 0.61181 1.94249 0.781485 2.16L7.2946 10.5093C7.86911 11.2457 8.63711 11.6541 9.44976 11.6541C10.2624 11.6541 11.0304 11.2457 11.6049 10.5093L18.118 2.16C18.2877 1.94249 18.38 1.65629 18.38 1.35102Z" fill="currentColor" />
                        </svg>
                      )}
                    </a>
                    {item.subItems && (
                      <ul>
                        {item.subItems.map((subItem, subIndex) => (
                          <li key={subIndex}>
                            {/* <a href={subItem.link} className={subItem.link == "/contact_us" ? 'active' : ''}> */}
                            <a href={subItem.link} className={item?.title === "Contact Us" ? 'active' : ''}>
                              {subItem.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>

            <div class="common_right_block">

              <div class="contact_details">

                <h1>Contact Details</h1>

                <div class="d-flex flex-wrap">

                  <div class="cdleft">

                    <h4>Limited Company Details</h4>
                    <p><strong>Davenport-Burgess</strong> <br /> Davenport House <br /> 47 Wednesfield Road <br />Willenhall <br />West Midlands <br />WV13 1AL <br />/United Kingdom</p>

                    <div class="cdCall">
                      <a href="#">
                        <div class="icon"><img src="images/call-icon2.svg" /></div>
                        <div class="text">01902 366 448</div>
                      </a>
                    </div>
                    <div class="cdCall">
                      <a href="#">
                        <div class="icon"><img src="images/fax-icon.svg" /></div>
                        <div class="text">01902 366 448</div>
                      </a>
                    </div>


                    <div class="cdright">

                      <div class="sales_team">

                        <h4>Sales Team</h4>

                        <div class="cdCall">
                          <a href="#">
                            <div class="icon"><img src="images/call-icon2.svg" /></div>
                            <div class="text">
                              <span>01902 366 448</span>  - then press option 1
                            </div>
                          </a>
                        </div>
                        <div class="cdCall">
                          <a href="#">
                            <div class="icon"><img src="images/envelope-icon2.svg" /></div>
                            <div class="text">sales@davenport-burgess.com</div>
                          </a>
                        </div>

                      </div>
                      <div class="sales_team">

                        <h4>Technical Support Team</h4>

                        <div class="cdCall">
                          <a href="#">
                            <div class="icon"><img src="images/call-icon2.svg" /></div>
                            <div class="text">
                              <span>01902 366 448</span>  - then press option 4
                            </div>
                          </a>
                        </div>
                        <div class="cdCall">
                          <a href="#">
                            <div class="icon"><img src="images/envelope-icon2.svg" /></div>
                            <div class="text">technical@davenport-burgess.com</div>
                          </a>
                        </div>

                      </div>
                      <div class="sales_team">

                        <h4>Accounts</h4>

                        <div class="cdCall">
                          <a href="#">
                            <div class="icon"><img src="images/call-icon2.svg" /></div>
                            <div class="text">
                              <span>01902 366 448</span>  - then press option 1
                            </div>
                          </a>
                        </div>
                        <div class="cdCall">
                          <a href="#">
                            <div class="icon"><img src="images/envelope-icon2.svg" /></div>
                            <div class="text">accounts@davenport-burgess.com</div>
                          </a>
                        </div>

                      </div>
                      <div class="sales_team">

                        <h4>Purchasing Team</h4>

                        <div class="cdCall">
                          <a href="#">
                            <div class="icon"><img src="images/call-icon2.svg" /></div>
                            <div class="text">
                              <span>01902 366 448</span>  - then press option 4
                            </div>
                          </a>
                        </div>
                        <div class="cdCall">
                          <a href="#">
                            <div class="icon"><img src="images/envelope-icon2.svg" /></div>
                            <div class="text">purchasing@davenport-burgess.com</div>
                          </a>
                        </div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default ContactDetails
