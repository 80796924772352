import React from 'react'
import pathObj from '../services/Config'

const Personalisedkeys = () => {
  return (
    <>
        <div className="cetogry_listing">

          <div className="container">

            <div className="clinner d-flex flex-wrap align-items-start">

              <div className="clSidebar common_sidebar">

                <ul>
                  <li><a className="active"> Filter by </a></li>
                  <li><a href="#">Latest News</a></li>
                  <li><a href="#">Trade Shows</a></li>
                  <li><a href="#">All Categories</a></li>
                  <li><a className="active">News Archive</a></li>
                  <li><a href="#">2024</a></li>
                  <li><a href="#">2023</a>
                    <ul>
                      <li><a href="#">March 2023 (10)</a></li>
                      <li><a href="#">Aprail 2023 (5)</a></li>
                    </ul>
                  </li>
                  <li><a href="#">2022</a></li>
                </ul>

              </div>

              <div className="common_right_block">

                <div className="common_content">

                  <h1>PERSONALISED KEYS</h1>
                  <p><em>01/03/2023</em></p>
                  <div className="mt-1"><img src={`${pathObj?.s3ImagePath}/PK-Image.jpg`} /></div>
                  <h3 style={{color: "#363B73"}}>Our personalised 'coloured' keys service enables you to add your business logo and details in colour on popular key blanks and head types.</h3>
                  <p>QUICK LINKS:  <a href="#">Features</a> | <a href="#">Minimum Order Quantities</a> | <a href="#">Blanks Included</a> | <a href="#">Order Keys</a></p>
                  <div className="mt-4"><img src= {`${pathObj?.s3ImagePath}/personalised-keys-banner.jpg`} /></div>

                  <h3 style={{color: "#FEB41D"}}>Your customers take their keys everywhere, <br /> make sure your name is on them!</h3>

                  <h4>Personalised Keys Features</h4>
                  <ul>
                    <li>Keeps your name on your customer’s keys</li>
                    <li>Choose your own colours and design</li>
                    <li>Option 1: your business details on both sides</li>
                    <li>Option 2: your business details on side 1 & bright coloured reverse for key identification</li>
                    <li>High quality colour, finish & durable</li>
                    <li>Available on HD & Genuine blanks</li>
                    <li>Other blanks available on request (subject to an extended lead time)</li>
                  </ul>

                  <h4>Minimum Order Quantity per head type</h4>
                  <ul>
                    <li>Round Head blanks: 250 (x50 per blank variant)</li>
                    <li>Yale Head blanks: 100 (x20 per blank variant)</li>
                    <li>Square Head blanks: 100 (x20 per blank variant)</li>
                  </ul>

                  <h4>Standard Key Blanks included in head type</h4>
                  <ul>
                    <li>Round Head blanks: 1A, 16JL, 25B, UL1, UL2, ASEC1, ASEC2, ER6, CLF1, U6P-112A</li>
                    <li>Yale Head blanks: YL-CYL-KBA, YL-CYL-KBB, YA91, YAX1, MAG1</li>
                    <li>Squire Head blank: ESMP5</li>
                  </ul>

                  <h3 style={{color: '#FEB41D'}}>TO ORDER YOUR PERSONALISED KEY BLANKS</h3>
                  <p><strong>Please contact the office for an Order Form on <a href="tel:01902 366448">01902 366448</a>
                    or email <a href="mailto:sales@davenport-burgess.com">sales@davenport-burgess.com</a></strong></p>
                  <p><strong>Alternatively, contact your local <a href="#">Area Sales Manager.</a></strong></p>

                </div>

              </div>

            </div>

          </div>

        </div>

    </>
  )
}

export default Personalisedkeys
