import React, { useEffect, useRef, useState } from 'react';
import { CommonService } from '../../../src/services/common';
import pathObj from '../../../src/services/Config';

const MobileMegaMenu = () => {
  const [megaMenu, setMegaMenu] = useState([]);
  const [activeMenu, setActiveMenu] = useState([]);
  const [menuTitle, setMenuTitle] = useState('MENU');
  const hasFetched = useRef(false);

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const [megaMenuData] = await Promise.all([
          CommonService.getListing(`${pathObj.baseURL}${pathObj.getMegaMenu}`),
        ]);
        setMegaMenu(megaMenuData?.data || []);
      } catch (error) {
        console.error('Error fetching header info:', error);
      }
    };

    if (!hasFetched.current) {
      fetchMenu();
      hasFetched.current = true;
    }
  }, []);

  const getCurrentMenuItems = () => {
    if (activeMenu?.length === 0) return megaMenu;
    const lastMenuItem = activeMenu[activeMenu.length - 1];
    return lastMenuItem?.SubCategoriesChilds || lastMenuItem?.SubCategories || [];
  };

  const currentItems = getCurrentMenuItems();


  const hasChildren = (item) => {
    return (
      (Array?.isArray(item?.SubCategories) && item?.SubCategories?.length > 0) ||
      (Array?.isArray(item?.SubCategoriesChilds) && item?.SubCategoriesChilds?.length > 0)
    );
  };

  const handleWithoutChildClick = (item) => {
    localStorage.setItem('productFilterString', item?.product_filter_string)
    if (item?.slug === "new-products") { 
      window.location.href = `/` + item?.slug
    } else if (item?.slug === "offers") {
      window.location.href = `/categories/` + item?.slug
    } else if (item?.slug === "new-top-categories") {
      window.location.href = `/categories/` + item?.slug
    } else {
      window.location.href = `/products/` + item?.product_filter_string.replace(/\|/g, '/').replace(/ /g, '-').toLowerCase()
    }
}

  return (
    <div className="m-menu">
      <div className="m-menu__header">
        <label className="m-menu__toggle" htmlFor="menu">
          <svg
            width="35"
            height="35"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#000000"
            strokeWidth="2"
            strokeLinecap="butt"
            strokeLinejoin="arcs"
          >
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
          </svg>
        </label>
        <span>{menuTitle}</span>
      </div>
      <ul>
        {currentItems?.map((item) => {
          return (
            <li key={item?.id}>
              {hasChildren(item) ? (
                <>
                  <label className="a-label__chevron" htmlFor={item?.id}>
                    {item?.Name}
                  </label>
                  <input type="checkbox" id={item?.id} name={item?.Name} className="m-menu__checkbox" />
                  <div className="m-menu">
                    <div className="m-menu__header">
                      <label className="m-menu__toggle" htmlFor={item?.id}>
                        <svg
                          width="35"
                          height="35"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#000000"
                          strokeWidth="2"
                          strokeLinecap="butt"
                          strokeLinejoin="arcs"
                        >
                          <path d="M19 12H6M12 5l-7 7 7 7" />
                        </svg>
                      </label>
                      <span>{item?.Name}</span>
                    </div>
                    <ul>
                      {item?.SubCategories?.map((subItem) => {
                        return (
                          <li key={subItem?.id}>
                            {(Array.isArray(subItem?.SubCategoriesChilds) && subItem?.SubCategoriesChilds?.length > 0) ? (
                              <>
                                <label className="a-label__chevron" htmlFor={subItem?.id}>
                                  {subItem?.Name}
                                </label>
                                <input
                                  type="checkbox"
                                  id={subItem?.id}
                                  name={subItem?.Name}
                                  className="m-menu__checkbox"
                                />
                                <div className="m-menu">
                                  <div className="m-menu__header">
                                    <label className="m-menu__toggle" htmlFor={subItem?.id}>
                                      <svg
                                        width="35"
                                        height="35"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="#000000"
                                        strokeWidth="2"
                                        strokeLinecap="butt"
                                        strokeLinejoin="arcs"
                                      >
                                        <path d="M19 12H6M12 5l-7 7 7 7" />
                                      </svg>
                                    </label>
                                    <span>{subItem?.Name}</span>
                                  </div>
                                  <ul>
                                    <li>
                                      <div className="menu_products_inner">
                                        {subItem?.SubCategoriesChilds?.map((childItem) => (
                                          <div className="mpb" key={childItem?.id}>
                                            <figure style={{ cursor: 'pointer' }}>
                                              <a
                                                href={`/products/${childItem?.product_filter_string
                                                  .replace(/\|/g, '/')
                                                  .replace(/ /g, '-')
                                                  .toLowerCase()}`}
                                                  onClick={() => localStorage.setItem('productFilterString', childItem?.product_filter_string)}
                                              >
                                                <img
                                                  src={
                                                    childItem?.image
                                                      ? childItem.image
                                                      : `${pathObj?.s3ImagePath}/no-image.jpg`
                                                  }
                                                  alt={childItem?.Name}
                                                />
                                              </a>
                                            </figure>
                                            <div className="mpname">
                                              <a
                                                href={`/products/${childItem?.product_filter_string
                                                  .replace(/\|/g, '/')
                                                  .replace(/ /g, '-')
                                                  .toLowerCase()}`}
                                                  onClick={() => localStorage.setItem('productFilterString', childItem?.product_filter_string)}
                                              >
                                                {childItem?.Name}
                                              </a>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </li>
                                  </ul>

                                </div>
                              </>
                            ) : (
                              <label onClick={() => handleWithoutChildClick(subItem)} >
                                {subItem?.Name}
                              </label>
                            )}
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </>
              ) : (
                <label onClick={() => handleWithoutChildClick(item)}>
                  {item?.Name}
                </label>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  );
};

export default MobileMegaMenu;
