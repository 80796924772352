import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Config from '../../services/Config';
import toast from "react-hot-toast";
import { Helmet } from 'react-helmet';
import { CartContext } from '../../context/cartContext';
import { errorHandling } from '../../helpers/common';
import { CommonService } from '../../services/common';
import pathObj from '../../services/Config';
import { useLocation } from 'react-router-dom';
const ContactForm = () => {
  const location = useLocation();
  const pathCheck = location?.pathname;
  const [loading, setLoading] = useState(false);
  const { navDetails } = useContext(CartContext);
  const UserfulLinks = navDetails?.UserfulLinks
  const LinksDocuments = navDetails?.LinksDocuments
  const pageType = 'Contact_Us'

  const [defaultValue, setDefaultValue] = useState('');
  const [defaultValueTitle, setDefaultValueTitle] = useState('Contact Form');

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();
  
  useEffect(() => {
    if (pathCheck === '/technical_support') {
      setDefaultValue('technical_support');
      setDefaultValueTitle('Technical Support');
      setValue('department', 'technical_support'); // Set the default value in the form
    } else if (pathCheck === '/sales') {
      setDefaultValue('sales');
      setDefaultValueTitle('Talk To Sales');
      setValue('department', 'sales'); // Set the default value in the form
    } else {
      setDefaultValue('');
      setDefaultValueTitle('Contact Form');
      setValue('department', ''); // Reset the default value
    }
  }, [pathCheck, setValue]);
  


  const onSubmit = async (data) => {
    setLoading(true);
    try {
      // const response = await axios.post(`${Config.baseURL}${Config.contactFormSubmit}`, data);
      const response = await CommonService.getAllWithBodyWithToken(`${pathObj.baseURL}${pathObj.contactFormSubmit}`, data);
      toast.success(response?.message || 'Contact form submitted successfully');
      reset(); // Reset the form on successful submission
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false)
      errorHandling(error);
    } finally {
      setLoading(false)
    }
  };

  const getSidebarData = () => {
    // Check if pageType exists in usefulLinkData
    const link1 = UserfulLinks?.find((item) => item?.page_type === pageType);
    // const link2 = NewsTechnicals?.find((item) => item?.page_type === pageType);
    const link3 = LinksDocuments?.find((item) => item?.page_type === pageType);

    if (link1) {
      return UserfulLinks; // Return UserfulLinks if the pageType matches usefulLinkData
      // } else if (link2) {
      //   return NewsTechnicals; 
    } else if (link3) {
      return LinksDocuments; // Return LinksDocuments for specific pageTypes
    }

    return []; // Return empty array as default
  };

  const sidebarData = getSidebarData();
  return (
    <>
      <Helmet>
        <title>Contact Form</title>
        <meta name="description" content="Contact form" />
      </Helmet>
      <div className="cetogry_listing">
        <div className="container">
          <div className="clinner d-flex flex-wrap align-items-start">
            <div className="clSidebar common_sidebar">
              <ul>
                <li><a className="active">{pageType.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase())}</a></li>
                {sidebarData.map((item, index) => (
                  <li key={index}>
                    <a href={`/${item?.page_type}`} className={item.page_type === "Contact_Us" ? 'active' : ''}>
                      {/* <a href={item.link} className={pageData[0]?.title === item.title ? 'active' : ''}> */}
                      {item.title}
                      {item.subItems && (
                        <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M18.38 1.35102C18.38 1.04574 18.2877 0.759549 18.118 0.542041C17.7698 0.095576 17.2042 0.095576 16.8559 0.542041L10.3428 8.89131C10.1136 9.18514 9.78911 9.35304 9.44976 9.35304C9.11042 9.35304 8.78595 9.18514 8.55674 8.89131L2.04362 0.542041C1.87097 0.320716 1.64176 0.206238 1.41255 0.206238C1.18335 0.206238 0.954136 0.3169 0.781485 0.542041C0.608833 0.767181 0.519531 1.04574 0.519531 1.35102C0.519531 1.65629 0.61181 1.94249 0.781485 2.16L7.2946 10.5093C7.86911 11.2457 8.63711 11.6541 9.44976 11.6541C10.2624 11.6541 11.0304 11.2457 11.6049 10.5093L18.118 2.16C18.2877 1.94249 18.38 1.65629 18.38 1.35102Z" fill="currentColor" />
                        </svg>
                      )}
                    </a>
                    {item.subItems && (
                      <ul>
                        {item.subItems.map((subItem, subIndex) => (
                          <li key={subIndex}>
                            {/* <a href={subItem.link} className={subItem.link == "/contact_us" ? 'active' : ''}> */}
                            <a href={subItem.link} className={item?.page_type === "Contact Us" ? 'active' : ''}>
                              {subItem.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="common_right_block">
              <div className="contact_details">
                <h1>{defaultValueTitle}</h1>
                <p>We are always happy to answer any questions you may have.</p>
                <div className="common_form pt-2">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row gx-6">
                      <div className="col-lg-6">
                        <div className="form_group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Name*"
                            {...register('name', { required: 'Name is required' })}
                          />
                          {errors.name && <span style={{ color: 'red' }}>{errors.name.message}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Company Name"
                            {...register('company_name')}
                          />
                          {/* {errors.company_name && <span>{errors.company_name.message}</span>} */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Phone"
                            {...register('phone')}
                          />
                          {/* {errors.phone && <span>{errors.phone.message}</span>} */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Mobile"
                            {...register('mobile')}
                          />
                          {/* {errors.mobile && <span>{errors.mobile.message}</span>} */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Email*"
                            {...register('email', {
                              required: 'Email is required',
                              pattern: {
                                value: /^\S+@\S+$/i,
                                message: 'Entered value does not match email format',
                              },
                            })}
                          />
                          {errors.email && <span style={{ color: 'red' }}>{errors.email.message}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Reference"
                            {...register('reference')}
                          />
                          {/* {errors.reference && <span>{errors.reference.message}</span>} */}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <select
                            className="form-control form-select"
                            {...register('department')}
                            value={defaultValue} // Use 'value' for controlled components
                            onChange={(e) => setValue('department', e.target.value)} // Handle change
                          >
                            <option value="">Department</option>
                            <option value="sales">Sales</option>
                            <option value="technical_support">Technical</option>
                            {/* <option value="Accounts">Accounts</option>
                            <option value="Purchasing">Purchasing</option> */}
                          </select>
                          {/* {errors.department && <span>{errors.department.message}</span>} */}
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form_group">
                          <textarea
                            className="form-control"
                            rows="5"
                            placeholder="Message*"
                            {...register('message', { required: 'Message is required' })}
                          ></textarea>
                          {errors.message && <span style={{ color: 'red' }}>{errors.message.message}</span>}
                        </div>
                      </div>
                    </div>
                    <div className="login_btn">
                      <button className={`button2 ${loading ? 'disabled' : ''}`} type="submit" disabled={loading}>
                        {loading ? ('Loading...') : ('Submit')}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
