import React from "react";
import { useNavigate } from "react-router-dom";
import pathObj from "../services/Config";

function NotFound() {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/"); // Navigate to the homepage
  };

  return (
    <div className="NotFound">
      <img
        src={`${pathObj?.s3ImagePath}/404-error.png`}
        alt="Error"
        className="NotFound__image"
      />
        <button className="button1" onClick={handleGoHome}>
          Go to home page
        </button>
    </div>
  );
}

export default NotFound;
