import { axiosWrapper } from '../../src/helpers/axiosWrapper'

export const CommonService = {
  getAll,
  getAllTesting,
  getAllWithBody,
  getAllWidgetBody,
  getAllWidgetBodyFile,
  getAllWithBodyFile,
  getAllWithBodyWithToken,
  getAllWithBodyAndRequestParam,
  getListing,
  getListingWithToken,
  getById,
  create,
  update,
  delete: _delete,
  getByParams
}

function getAll(requestBaseUrl = '', params = '') {
  const url = params !== '' ? `${requestBaseUrl}?${params}` : requestBaseUrl

  return axiosWrapper.post(url, {})
}

function getAllTesting(requestBaseUrl = '', params = {}) {
  return axiosWrapper.postTesting(requestBaseUrl, params)
}

function getAllWithBody(requestBaseUrl = '', params = {}) {
  return axiosWrapper.post(requestBaseUrl, params)
}

function getAllWithBodyFile(requestBaseUrl = '', params = {}) {
  return axiosWrapper.postFile(requestBaseUrl, params)
}

function getAllWithBodyWithToken(requestBaseUrl = '', params = {}, token = '') {
  return axiosWrapper.postToken(requestBaseUrl, params, token)
}

function getAllWithBodyAndRequestParam(requestBaseUrl = '', params = {}, requestParam = '') {
  const url = requestParam !== '' ? `${requestBaseUrl}?${requestParam}` : requestBaseUrl

  return axiosWrapper.post(url, params)
}

function getListingWithToken(requestBaseUrl = '', token = '') {
  return axiosWrapper.getToken(requestBaseUrl,token)
}

function getListing(requestBaseUrl = '') {
  return axiosWrapper.get(requestBaseUrl)
}

function getById(requestBaseUrl = '', id = '') {
  return axiosWrapper.get(`${requestBaseUrl}/${id}`)
}

function getByParams(requestBaseUrl = '', requestParam = '') {
  const url = requestParam !== '' ? `${requestBaseUrl}?${requestParam}` : requestBaseUrl

  return axiosWrapper.get(url)
}

function create(requestBaseUrl = '', params = '') {
  return axiosWrapper.post(requestBaseUrl, params)
}

function update(requestBaseUrl = '', id, params) {
  return axiosWrapper.put(`${requestBaseUrl}/${id}`, params)
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(requestBaseUrl = '') {
  return axiosWrapper.delete(`${requestBaseUrl}`)
}

function getAllWidgetBody(requestBaseUrl = '', params = {}) {
  return axiosWrapper.post(requestBaseUrl, params)
}

function getAllWidgetBodyFile(requestBaseUrl = '', params = {}) {
  return axiosWrapper.post(requestBaseUrl, params)
}
