import React, { useEffect, useRef, useState } from 'react';
import { CommonService } from '../../../src/services/common';
import pathObj from '../../../src/services/Config';
import LandingCarouselSlider from '../../components/shared/CarouselSlider';
import { Link } from 'react-router-dom';
import { errorHandling } from '../../helpers/common';
// import { Helmet } from 'react-helmet';

const Index = () => {
  const [topCategories, setTopCategories] = useState(null);
  const hasFetched = useRef(false);
  const token = localStorage.getItem("Token");
  useEffect(() => {
    const fetchFooterInfo = async () => {
      try {
        const data = await CommonService.getListing(`${pathObj.baseURL}${pathObj.getTopCategories}`);
        setTopCategories(data?.data);
      } catch (error) {
        errorHandling(error);
        // console.error("Error fetching footer info:", error);
      }
    };
    if (!hasFetched.current) {
      fetchFooterInfo();
      hasFetched.current = true;
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <LandingCarouselSlider />

      {topCategories &&
        <div className="top_category pad_t80">
          <div className="container">
            {/* <Helmet>
              <meta charSet="utf-8" />
              <title>My Title</title>
              <link rel="canonical" href="http://mysite.com/example" />
            </Helmet> */}
            <h2>New Top Categories</h2>
            <ul>
              {topCategories ? (
                topCategories.map((category) => (
                  <li key={category.id}>
                    <a href={`/categories/key-blanks/${category.slug}`}>
                      <div className="tcImg"><img src={category.image || `${pathObj?.s3ImagePath}/no-image.jpg`} alt={category.Name} /></div>

                      <div className="tcName"
                        style={{ cursor: "pointer", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "100px", display: "inline-block" }}
                        title={category.Name} // Show full name on hover
                      >
                        {category.Name}</div>
                    </a>
                  </li>
                ))
              ) : (
                <p>Loading categories...</p>
              )}
            </ul>
          </div>
        </div>
      }
      <div className="featured_wrap pad_t80 pad_b75">

        <div className="container">

          <div className="featured_row">

            <div className="featured_sidebar">

              <ul>
                <li>
                  <Link to={token ? "/quick-order" : "/login"} onClick={scrollToTop}>
                    <div className="icon"><img src={`${pathObj?.s3ImagePath}/quick-order-icon.svg`} /></div>
                    <div className="text">
                      <h4>Quick Order</h4>
                      <p>Easy and fast ordering option shows all price breaks and includes live search facility using full or part code references.</p>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to={token ? "/favourite-products" : "/login"} onClick={scrollToTop}>
                    <div className="icon"><img src={`${pathObj?.s3ImagePath}/favourites-icon.svg`} /></div>
                    <div className="text">
                      <h4>Favourites</h4>
                      <p>Search and store items for future reference and ordering.</p>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to={token ? "https://linktr.ee/davenportburgess" : "/login"} onClick={scrollToTop}>
                    <div className="icon"><img src={`${pathObj?.s3ImagePath}/links-icon.svg`} /></div>
                    <div className="text">
                      <h4>Links</h4>
                      <p>Browse useful links including social media and our new YouTube channel!</p>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to={token ? "/order-history" : "/login"} onClick={scrollToTop}>
                    <div className="icon"><img src={`${pathObj?.s3ImagePath}/order-history-icon.svg`} /></div>
                    <div className="text">
                      <h4>Order History</h4>
                      <p>View full order history for up to fifty orders including part details, pricing and deliveries. Also includes an easy ‘Re-order’ link</p>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/news/all-news" onClick={scrollToTop}>
                    <div className="icon"><img src={`${pathObj?.s3ImagePath}/news-icon.svg`} /></div>
                    <div className="text">
                      <h4>News</h4>
                      <p>Check out the latest news, including Latest Deals, and other helpful information.</p>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to={token ? "/account-balance" : "/login"} onClick={scrollToTop}>
                    <div className="icon"><img src={`${pathObj?.s3ImagePath}/payment-icon.svg`} /></div>
                    <div className="text">
                      <h4>Payment</h4>
                      <p>View account balance, choose payment options and make payments.</p>
                    </div>
                  </Link>
                </li>
              </ul>

            </div>

            <div className="featured_bx">

              <div className="pkbx">

                <figure><img src={`${pathObj?.s3ImagePath}/personalised-keys-img.jpg`} /></figure>
                <figcaption>
                  <h3>Personalised Keys</h3>
                  <p>Read about our coining services</p>
                  <div className="rnbtn"><Link to="/news/all-news/13/PERSONALISED-KEYS" onClick={scrollToTop}>Shop Now <img src={`${pathObj?.s3ImagePath}/arrow-icon.svg`} /></Link></div>
                </figcaption>

              </div>
              <div className="pkbx">

                <figure><img src={`${pathObj?.s3ImagePath}/Licensed+Range+Updated+Image.jpg`} /></figure>
                <figcaption>
                  <h3>Licensed Items</h3>
                  <p>Browse our latest ranges</p>
                  {/* <div className="rnbtn"><a href="https://d151gqn7auphjd.cloudfront.net/search/licensed">Shop Now <img src={`${pathObj?.s3ImagePath}/arrow-icon.svg`} /></a></div> */}
                  <div className="rnbtn"><Link to= {`${pathObj?.frontUrl}/search/licensed`} onClick={scrollToTop}>Shop Now <img src={`${pathObj?.s3ImagePath}/arrow-icon.svg`} /></Link></div>
                </figcaption>

              </div>
              <div className="pkbx">

                <figure><img src={`${pathObj?.s3ImagePath}/loop-fobs-img.jpg`} /></figure>
                <figcaption>
                  <h3>Loop fobs</h3>
                  <p>Browse our latest Loop fobs</p>
                  <div className="rnbtn"><Link to={`${pathObj?.frontUrl}/products/accessories/loop-fobs`}  onClick={scrollToTop}>Shop Now <img src={`${pathObj?.s3ImagePath}/arrow-icon.svg`} /></Link></div>
                </figcaption>
              </div>
              <div className="pkbx">

                <figure><img src={`${pathObj?.s3ImagePath}/key-machines-img.jpg`} /></figure>
                <figcaption>
                  <h3>Key machines</h3>
                  <p>Browse our latest Key machines</p>
                  <div className="rnbtn"><Link to={`${pathObj?.frontUrl}/categories/key-cutting/key-machines`}  onClick={scrollToTop}>Shop Now <img src={`${pathObj?.s3ImagePath}/arrow-icon.svg`} /></Link></div>
                </figcaption>

              </div>

            </div>

          </div>

        </div>

      </div>

    </>
  )
}

export default Index
