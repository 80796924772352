import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from "react-hot-toast";
import pathObj from '../../services/Config';
import { CommonService } from '../../services/common';
import { errorHandling } from '../../helpers/common';

const ResetPassword = () => {
  const location = useLocation();
  const email = location?.search.split('?email=').pop();
  const decodedEmail = email ? decodeURIComponent(email) : null;
  const token = location.pathname.split('/').pop();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = {
      ...data,
      email: decodedEmail || data.email, // Use decodedEmail if available, otherwise use the form input
      token,
    };

    try {
      const response = await CommonService.getAllWithBody(`${pathObj.baseURL}${pathObj.resetPassword}`, formData);
      if (response?.status === 200 ){
      toast.success(response?.message || 'Password updated successfully.');
      } else if(response?.status === 202){
        toast.error(response?.message);
      }
      reset(); // Reset the form on successful submission
      navigate('/login');
    } catch (error) {
      toast.error(error?.response?.data?.errors?.[0]?.title || 'Something went wrong');
      setLoading(false);
      errorHandling(error);
    } finally {
      setLoading(false);
    }
  };

  const password = watch('password', '');

  return (
    <div className="login_register">
      <div className="container">
        <div className="lrinner d-flex flex-wrap">
          <div className="login_form">
            <h1>Reset Password</h1>
            <div className="common_form">
              <form onSubmit={handleSubmit(onSubmit)}>
                {decodedEmail ? (
                  <div className="form_group">
                    <span>Email:</span>
                    <input
                      className="form-control"
                      type="email"
                      readOnly
                      defaultValue={decodedEmail}
                    />
                  </div>
                ) : (
                  <div className="form_group">
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Email"
                      {...register('email', {
                        required: 'Email is required',
                        pattern: {
                          value: /^\S+@\S+$/i,
                          message: 'Enter a valid email address',
                        },
                      })}
                    />
                    {errors.email && <span style={{ color: '#eb3434' }}>{errors.email.message}</span>}
                  </div>
                )}

                <div className="form_group">
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Password"
                    {...register('password', {
                      required: 'Password is required',
                      minLength: {
                        value: 8,
                        message: 'Password must be at least 8 characters long',
                      },
                    })}
                  />
                  {errors.password && <span style={{ color: '#eb3434' }}>{errors.password.message}</span>}
                </div>

                <div className="form_group">
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Confirm Password"
                    {...register('confirmPassword', {
                      validate: (value) => value === password || 'The passwords do not match',
                    })}
                  />
                  {errors.confirmPassword && <span style={{ color: '#eb3434' }}>{errors.confirmPassword.message}</span>}
                </div>
                <button className={`button2 ${loading ? 'disabled' : ''}`} type="submit" disabled={loading}>
                  {loading ? ('Loading...') : ('Reset Password')}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
