import React, { createContext, useState } from 'react';
import axios from 'axios';
import Config from '../services/Config';
import { useNavigate } from "react-router-dom";
import { encryptData } from '../helpers/common';
import toast from "react-hot-toast";
import authConfig from '../services/auth'

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const signIn = async (params, errorCallback) => {
    axios
      .post(`${Config.baseURL}${Config.logIn}`, params)
      .then(async response => {
        if (params.rememberMe) {
          window.localStorage.setItem(authConfig.storageTokenKeyName, encryptData(response.data.token));
        }
        const userData = response?.data?.data;
        console.log("🚀 ~ signIn ~ userData:", userData)
        toast.success(response?.data?.message || 'Login successfully');
        setUser(userData);
        window.localStorage.setItem('userData', encryptData(JSON.stringify(userData)));
        window.localStorage.setItem('userEmail', params.email);
        window.localStorage.setItem(authConfig.storageTokenKeyName, response?.data?.data?.token);
        if (params.rememberMe) {
          window.localStorage.setItem('userInfo', encryptData(JSON.stringify(params)));
        } else {
          window.localStorage.removeItem('userInfo');
        }
        if(userData?.first_time_login == 0){
          navigate(`/reset-password/${userData?.reset_token}?email=${userData?.email}`);
        }else{
          navigate("/");
        }
        
        // window.location.reload();
      })
        
      .catch(err => {
        // console.log("🚀 ~ signIn ~ err:", err);
        toast.error(`${err?.response?.data?.message}`);
        errorCallback(err);
      });
  }

  const signOut = () => {
    const token = localStorage.getItem("Token");

    setUser(null);
    window.localStorage.removeItem('userData');
    window.localStorage.removeItem('Token');
    window.localStorage.removeItem('cartItems');
    window.localStorage.removeItem('cartCount');
    window.localStorage.removeItem('checkoutItemIds');
    window.localStorage.removeItem('categoriesName');

    (async () => {
      try {
        const response = await axios.post(
          `${Config.baseURL}${Config.logOut}`,
          null,  // Passing null as the request payload since it's a POST request
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        toast.success(response?.data?.message);
        window.location.reload();
        navigate("/login");
      } catch (error) {
        console.log("error:", error);
      }
    })();
  };

  return (
    <AuthContext.Provider value={{ user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider as default };
