import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import MyAccountSideBar from './MyAccountSideBar';
import UpdateAccountDetails from './UpdateAccountDetails';
import pathObj from '../../services/Config';
import { CommonService } from '../../services/common';
import debounce from 'lodash.debounce';
import { decryptData, errorHandling } from '../../helpers/common';

const AccountDetails = () => {
  const userInfo = window.localStorage.getItem('userData');
  const userD = decryptData(userInfo);
  const compData = JSON.parse(userD);
  const customerId = compData?.customer_id;
  const [billingDetails, setBillingDetails] = useState({})
  const [countries, setCountries] = useState([]);
  const { register, handleSubmit, setValue, formState: { errors }, } = useForm();
  const fetchBillingAccountDetails = async () => {
    try {
      const payload = { customer_id: customerId, }
      const url = `${pathObj.baseURL}${pathObj.getBillingAccountDetails}`;
      const response = await CommonService.getAllWithBodyWithToken(url, payload);
      if (response?.success) {
        setBillingDetails(response?.data);
        resetFormWithBillingDetails(response?.data);
      } else {
        console.error('Failed to fetch Billing Details:', response?.message);
      }
    } catch (error) {
      console.error('Error fetching Billing Details:', error);
      errorHandling(error);
    }
  };

  const debouncedFetchBillingAccountData = debounce(fetchBillingAccountDetails, 300); // Adjust the delay as needed

  useEffect(() => {
    debouncedFetchBillingAccountData();
  }, [customerId]);

  const resetFormWithBillingDetails = (data) => {
    setValue('accountNumber', data?.CardCode || '');
    setValue('name', data?.CardName || '');
    setValue('address', data?.Street || '');
    setValue('city', data?.City || '');
    setValue('country', data?.Country || '');
    setValue('country', data?.County || '');
    setValue('postCode', data?.ZipCode || '');
    setValue('telephone', data?.Phone1 || '');
    setValue('email', data?.email || '');
  };

  const fetchCountries = async () => {
    try {
      const url = `${pathObj.baseURL}${pathObj.getCountries}`;
      const response = await CommonService.getListing(url);
      if (response?.success) {
        setCountries(response?.data); // Assuming response.data contains the list of countries
      } else {
        console.error('Failed to fetch countries:', response?.message);
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const debouncedFetchCountriesData = debounce(fetchCountries, 300); // Adjust the delay as needed

  useEffect(() => {
    debouncedFetchCountriesData();
  }, [customerId]);



  const onSubmitBillingDetails = (data) => {
    console.log('Billing Details Submitted:', data);
    // Handle form submission logic here
  };

  return (
    <div className="cetogry_listing">
      <div className="container">
        <div className="clinner d-flex flex-wrap align-items-start">
          <MyAccountSideBar />

          <div className="common_right_block">
            <div className="contact_details account_details">
              <div className="common_form">
                <div className="row gx-6">
                  <div className="col-xl-6">
                    <h1 className="mb-4">Billing Account Details</h1>
                    <p>Please ring davenport office and contact our account/customer service team to request invoice address change.</p>
                    <form onSubmit={handleSubmit(onSubmitBillingDetails)}>
                      <div className="form_group">
                        <input
                          className="form-control"
                          type="text"
                          readOnly="true"
                          placeholder="Account number"
                          {...register('accountNumber', { required: 'Account number is required' })}
                        />
                        {errors.accountNumber && <span style={{ color: '#eb3434' }}>{errors.accountNumber.message}</span>}
                      </div>
                      <div className="form_group">
                        <input
                          className="form-control"
                          type="text"
                          readOnly="true"
                          placeholder="Name"
                          {...register('name', { required: 'Name is required' })}
                        />
                        {errors.name && <span style={{ color: '#eb3434' }}>{errors.name.message}</span>}
                      </div>
                      <div className="form_group">
                        <input
                          className="form-control"
                          type="text"
                          readOnly="true"
                          placeholder="Address"
                          {...register('address', { required: 'Address is required' })}
                        />
                        {errors.address && <span style={{ color: '#eb3434' }}>{errors.address.message}</span>}
                      </div>
                      <div className="form_group">
                        <input
                          className="form-control"
                          type="text"
                          readOnly="true"
                          placeholder="Town/City"
                          {...register('city', { required: 'Town/City is required' })}
                        />
                        {errors.city && <span style={{ color: '#eb3434' }}>{errors.city.message}</span>}
                      </div>
                      {/* <div className="form_group">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Country"
                          {...register('country', { required: 'Country is required' })}
                        />
                        {errors.country && <span style={{ color: '#eb3434' }}>{errors.country.message}</span>}
                      </div> */}
                      <div className="form_group">
                        <select
                          className="form-control"
                          {...register('country', { required: true })}
                          value={countries.find(country => country?.Code === billingDetails?.Country)?.Code || ''}
                          disabled
                        >
                          <option value="">Select a country</option>
                          {countries?.map(country => (
                            <option key={country.Code} value={country.Code}>
                              {country.Name}
                            </option>
                          ))}
                        </select>
                        {errors.country && <span style={{ color: '#eb3434' }}>Country is required</span>}
                      </div>
                      <div className="form_group">
                        <input
                          className="form-control"
                          type="text"
                          readOnly="true"
                          placeholder="Post Code"
                          {...register('postCode', { required: 'Post Code is required' })}
                        />
                        {errors.postCode && <span style={{ color: '#eb3434' }}>{errors.postCode.message}</span>}
                      </div>
                      <div className="form_group">
                        <input
                          className="form-control"
                          type="text"
                          readOnly="true"
                          placeholder="Telephone"
                          {...register('telephone', { required: 'Telephone is required' })}
                        />
                        {errors.telephone && <span style={{ color: '#eb3434' }}>{errors.telephone.message}</span>}
                      </div>
                      <div className="form_group">
                        <input
                          className="form-control"
                          type="text"
                          readOnly="true"
                          placeholder="Email"
                          {...register('email', {
                            required: 'Email is required',
                            pattern: {
                              value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                              message: 'Invalid email address',
                            },

                          })}
                        />
                        {errors.email && <span style={{ color: '#eb3434' }}>{errors.email.message}</span>}
                      </div>
                      {/* <div className="form_group">
                        <input className="button2" type="submit"  readOnly="true" value="Request Invoice Address Change" />
                      </div> */}
                    </form>
                  </div>
                  {/* <UpdateAccountDetails /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
