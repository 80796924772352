import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import MyAccountSideBar from './MyAccountSideBar';
import pathObj from '../../services/Config';
import { CommonService } from '../../services/common';
import toast from 'react-hot-toast';
import { decryptData, errorHandling } from '../../helpers/common';

const ChangePassword = () => {
  const userInfo = window.localStorage.getItem('userData');
  const userD = decryptData(userInfo);
  const compData = JSON.parse(userD);
  const customerId = compData?.customer_id;
  const token = localStorage.getItem("Token");
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);
    const formData = {
      ...data,
      token,
      customerId,
    };

    try {
      const response = await CommonService.getAllWithBodyWithToken(`${pathObj.baseURL}${pathObj.changePassword}`, formData);
      toast.success(response?.message || 'Password updated successfully.');
      reset();
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Something went wrong');
      setLoading(false);
      errorHandling(error);
      // if (error?.response?.status === 422) {
      //   signOut();
      //   window.location.href = '/login';
      // }
    } finally {
      setLoading(false);
    }
  };

  // Watch the new password field to validate the confirm password field
  const newPassword = watch('newPassword', '');

  return (
    <div className="cetogry_listing">
      <div className="container">
        <div className="clinner d-flex flex-wrap align-items-start">
        <MyAccountSideBar />

          <div className="common_right_block">
            <div className="contact_details">
              <h1>Change Password</h1>
              <p>
                To change your password, enter a new password and confirm the new password below.
              </p>

              <div className="common_form pt-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row gx-6">
                    <div className="col-lg-6">
                      <div className="form_group">
                        <input
                          // className={`form-control ${errors.oldPassword ? 'is-invalid' : ''}`}
                           className="form-control"
                          type="password"
                          placeholder="Old Password"
                          {...register('oldPassword', { required: 'Old Password is required' })}
                        />
                        {errors.oldPassword && (
                          <div className="invalid-feedback">{errors.oldPassword.message}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form_group">
                        <input
                          // className={`form-control ${errors.newPassword ? 'is-invalid' : ''}`}
                           className="form-control"
                          type="password"
                          placeholder="New Password"
                          {...register('newPassword', {
                            required: 'New Password is required',
                            minLength: {
                              value: 8,
                              message: 'New Password must be at least 8 characters long',
                            },
                          })}
                        />
                        {errors.newPassword && (
                          <div className="invalid-feedback">{errors.newPassword.message}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form_group">
                        <input
                          // className={`form-control ${errors.confirmNewPassword ? 'is-invalid' : ''}`}
                           className="form-control"
                          type="password"
                          placeholder="Confirm New Password"
                          {...register('newPassword_confirmation', {
                            required: 'Please confirm your new password',
                            validate: (value) =>
                              value === newPassword || 'Passwords do not match',
                          })}
                        />
                        {errors.confirmNewPassword && (
                          <div className="invalid-feedback">
                            {errors.confirmNewPassword.message}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <button className={`button2 ${loading ? 'disabled' : ''}`} type="submit" disabled={loading}>
                  {loading ? ('Loading...') : ('Update')}
                </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
