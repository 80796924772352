import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import Config from '../../services/Config';
import toast from "react-hot-toast";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { errorHandling } from '../../helpers/common';

const AccountApplicationForm = () => {
  const { control, handleSubmit, watch, reset, register, setValue, formState: { errors } } = useForm();
  const [activeTab, setActiveTab] = useState('limitedCompany');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    reset();
  }, [activeTab]);

  const onSubmit = async (data) => {
    setLoading(true);
    const business_type = activeTab
    const company = [
      {
        name: data?.director1,
        postCode: data?.companyPostCode || "",
        companyRegistrationNumber: data?.companyRegNo || "",
      },
      {
        name: data.director2 || "",
      }
    ];

    const formData = {
      ...data,
      company,
      business_type,
    };

    try {
      const response = await axios.post(`${Config.baseURL}${Config.accountApplication}`, formData);
      toast.success(response?.data?.message || 'Save user application form successfully');
      reset();
      navigate('/login')
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setLoading(false);
      errorHandling(error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const servicesOffered = watch('services_offered') || [];
  const password = watch('password');

  return (
    <>
      <Helmet>
        <title>Account Application</title>
        <meta name="description" content="Account application form" />
      </Helmet>
      <div className="login_register">
        <div className="container">
          <div className="lrinner">
            <h1 className="mb-3">Account Application</h1>
            <div className="cart_recent_items pdTbs account_application pt-4">
              <p className="fs-6 mb-2">Please select the type of business</p>

              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === 'limitedCompany' ? 'active' : ''}`}
                    onClick={() => setActiveTab('limitedCompany')}
                    type="button"
                  >
                    Limited Company
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`nav-link ${activeTab === 'soleProprietor' ? 'active' : ''}`}
                    onClick={() => setActiveTab('soleProprietor')}
                    type="button"
                  >
                    Sole Proprietor
                  </button>
                </li>
              </ul>

              <div className="tab-content">
                {(activeTab === 'limitedCompany' || activeTab === 'soleProprietor') && (
                  <div className="tab-pane fade show active" id="lc_tab">
                    <div className="common_form pt-3">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row gx-6 gy-4">
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <h4>Please select the type of business</h4>
                              <p className="fs-6">Please provide your company details</p>
                            </div>

                            <div className="form_group">
                              <input
                                // className={`form-control ${errors.CardName ? 'is-invalid' : ''}`}
                                className="form-control"
                                placeholder="Trading Title*"
                                {...register('CardName', { required: true })}
                              />
                              {errors.CardName && <span style={{ color: '#eb3434' }}>Trading Title is required</span>}
                            </div>

                            <div className="form_group">
                              <input className="form-control" type="text" placeholder="Invoice Address" {...register('AddressName')} />
                            </div>
                            <div className="form_group">
                              <input className="form-control" type="text" placeholder="Town/City" {...register('City')} />
                            </div>
                            <div className="form_group">
                              <input className="form-control" type="text" placeholder="Post Code" {...register('ZipCode')} />
                            </div>
                            {/* <div className="form_group">
                              <input
                                // className={`form-control ${errors.ZipCode ? 'is-invalid' : ''}`}
                                 className="form-control"
                                type="text"
                                placeholder="Post Code"
                                maxLength={10}
                                {...register('ZipCode', {
                                  validate: {
                                    isValidPostcode: value =>
                                      value === '' || /^([A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}|GIR 0AA)$/.test(value) || 'Invalid postcode'
                                  }
                                })}
                              />
                              {errors.ZipCode && <span style={{ color: '#eb3434' }}>{errors.ZipCode.message}</span>}
                            </div> */}

                            <div className="form_group">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Phone"
                                {...register('Phone1', {
                                  required: 'Phone number is required',
                                  validate: {
                                    isNumeric: value => /^[0-9]*$/.test(value) || 'Only numeric values are allowed',
                                    minLength: value => value.length >= 10 || 'Phone number must be at least 10 digits long',
                                    maxLength: value => value.length <= 15 || 'Phone number cannot exceed 15 digits'
                                  }
                                })}
                                onInput={(e) => e.target.value = e.target.value.slice(0, 15)} // Limit to 15 characters
                              />
                              {errors.Phone1 && <span style={{ color: '#eb3434' }}>{errors.Phone1.message}</span>}
                            </div>

                            <div className="form_group">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Mobile"
                                {...register('Phone2', {
                                  required: true,
                                  validate: value => /^[0-9]*$/.test(value) || 'Only numeric values are allowed',
                                  minLength: value => value.length >= 10 || 'Phone number must be at least 10 digits long',
                                  maxLength: value => value.length <= 15 || 'Phone number cannot exceed 15 digits'
                                })}
                                onInput={(e) => e.target.value = e.target.value.slice(0, 15)} // Limit to 15 characters
                              />
                              {errors.Phone2 && <span style={{ color: '#eb3434' }}>{errors.Phone2.message || 'Mobile number is required'}</span>}
                            </div>
                            {/* <div className="form_group">
                            <input className="form-control" type="text" placeholder="Fax" {...register('Fax')} />
                          </div> */}
                            <div className="form_group">
                              <input className="form-control" type="text" placeholder="Email" {...register('EmailAddress')} />
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div>
                              <h4>Current Services Offered</h4>
                              <p className="fs-6">Please select all that apply, and at least one.</p>
                            </div>

                            <div className="row">

                              {errors.services_offered && <span style={{ color: '#eb3434' }}>{errors.services_offered.message}</span>}
                              {["Auto Locksmith", "Auto Spares/Supplies", "Builder Merchants", "Cash & Carry", "Dry Cleaners", "Engraver", "Hardware & DIY", "Ironmonger", "Key Cutter", "Locksmith", "Newsagent", "Other", "Property Management", "School/Local Authority", "Security", "Shoe Repairs", "Transponder Duplication", "Watch Repairs"].map(service => (
                                <div className="col-lg-6" key={service}>
                                  <div className="form-check custom_check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      {...register('services_offered', {
                                        validate: (value) => value.length > 0 || 'Please select at least one service',
                                      })}
                                      value={service}
                                      checked={servicesOffered.includes(service)}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        if (e.target.checked) {
                                          setValue('services_offered', [...servicesOffered, value], { shouldValidate: true });
                                        } else {
                                          setValue('services_offered', servicesOffered.filter((v) => v !== value), { shouldValidate: true });
                                        }
                                      }}
                                    />
                                    <label className="form-check-label">{service}</label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div className="row gx-6 gy-4">
                          {activeTab === 'limitedCompany' && (
                            <div className="col-lg-6">
                              <h4>Limited Company Details</h4>
                              <div className="form_group">
                                <input
                                  // className={`form-control ${errors.director1 ? 'is-invalid' : ''}`}
                                  className="form-control"
                                  placeholder="Director 1*"
                                  {...register('director1', { required: true })}
                                />
                                {errors.director1 && <span style={{ color: '#eb3434' }}>Director 1 is required</span>}
                              </div>
                              <div className="form_group">
                                <input className="form-control" type="text" placeholder="Director 2" {...register('director2')} />
                              </div>
                              <div className="form_group">
                                <input className="form-control" type="text" placeholder="Post Code" {...register('companyPostCode')} />
                              </div>
                              {/* <div className="form_group">
                                <input
                                  // className={`form-control ${errors.ZipCode ? 'is-invalid' : ''}`}
                                  className="form-control"
                                  type="text"
                                  placeholder="Post Code"
                                  maxLength={10}
                                  {...register('companyPostCode', {
                                    validate: {
                                      isValidPostcode: value =>
                                        value === '' || /^([A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}|GIR 0AA)$/.test(value) || 'Invalid postcode',
                                    }
                                  })}
                                />
                                {errors.companyPostCode && <span style={{ color: '#eb3434' }}>{errors.companyPostCode.message}</span>}
                              </div> */}
                              <div className="form_group">
                                <input className="form-control" type="text" placeholder="Company Reg. No" {...register('companyRegNo')} />
                              </div>
                            </div>
                          )}
                          <div className="col-lg-6">
                            <h4>Delivery Address</h4>
                            <p className="fs-6">Please provide your delivery address if it differs from the main address above.</p>
                            <div className="form_group">
                              <input className="form-control" type="text" placeholder="Address" {...register('deliveryAddress')} />
                            </div>
                            <div className="form_group">
                              <input className="form-control" type="text" placeholder="City" {...register('deliveryCity')} />
                            </div>
                            <div className="form_group">
                              <input className="form-control" type="text" placeholder="Post Code" {...register('deliveryPostCode')} />
                            </div>
                            {/* <div className="form_group">
                              <input
                                // className={`form-control ${errors.ZipCode ? 'is-invalid' : ''}`}
                                className="form-control"
                                type="text"
                                placeholder="Post Code"
                                maxLength={10}
                                {...register('deliveryPostCode', {
                                  validate: {
                                    isValidPostcode: value =>
                                      /^([A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}|GIR 0AA)$/.test(value) || 'Invalid Delivery postcode',
                                  }
                                })} />
                              {errors.deliveryPostCode && <span style={{ color: '#eb3434' }}>{errors.deliveryPostCode.message}</span>}
                            </div> */}
                          </div>
                        </div>
                        {activeTab === 'soleProprietor' && (
                          <>
                            <div className="row gx-6 gy-4">
                              <div className="col-lg-6">
                                <h4>Sole Proprietor / Partnership Details</h4>
                                <div className="form_group">
                                  <input
                                    type="text"
                                    // className={`form-control ${errors.names ? 'is-invalid' : ''}`}
                                    className="form-control"
                                    placeholder="Names*"
                                    {...register('names', { required: true })}
                                  />
                                  {errors.names && <span style={{ color: '#eb3434' }}>Name is required</span>}
                                </div>
                                <div className="form_group">
                                  <input type="text" className="form-control" placeholder="Private Address" {...register('city')} />
                                </div>
                                <div className="form_group">
                                  <input className="form-control" type="text" placeholder="Post Code" {...register('postCode')} />
                                </div>
                                {/* <div className="form_group">
                                  <input
                                    // className={`form-control ${errors.postCode ? 'is-invalid' : ''}`}
                                    className="form-control"
                                    type="text"
                                    placeholder="Post Code"
                                    maxLength={10}
                                    {...register('postCode', {
                                      validate: {
                                        isValidPostcode: value =>
                                          /^([A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}|GIR 0AA)$/.test(value) || 'Invalid Delivery postcode',
                                      }
                                    })} />
                                  {errors.postCode && <span style={{ color: '#eb3434' }}>{errors.postCode.message}</span>}
                                </div> */}

                                {/* <div className="form_group">
                                  <input type="text" className="form-control" placeholder="Post Code" {...register('postCode')} />
                                </div> */}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="row gx-6 gy-4">
                          <div className="col-lg-12">
                            <h4>Terms of Account</h4>
                            <Controller
                              name="termsOfAccount"
                              control={control}
                              defaultValue="30DayAccount"
                              render={({ field }) => (
                                <div className="form-check form-check-inline custom_check custom_radio">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="30DayAccount"
                                    {...field}
                                    defaultChecked
                                  />
                                  <label className="form-check-label">30 Day Account</label>
                                </div>
                              )}
                            />
                          </div>

                          <div className="col-lg-6">
                            <h4>User Details</h4>
                            <div className="form_group">
                              <input
                                type="email"
                                // className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                className="form-control"
                                placeholder="Email"
                                {...register('email', { required: true })}
                              />
                              {errors.email && <span style={{ color: '#eb3434' }}>Email is required</span>}
                            </div>
                            <div className="form_group">
                              <input
                                type="password"
                                // className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                className="form-control"
                                placeholder="Password"
                                {...register('password', { required: true })}
                              />
                              {errors.password && <span style={{ color: '#eb3434' }}>Password is required</span>}
                            </div>
                            <div className="form_group">
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Confirm Password"
                                {...register('confirmPassword', {
                                  required: true,
                                  validate: (value) => value === password || 'Passwords do not match',
                                })}
                              />
                              {errors.confirmPassword && (
                                <span style={{ color: '#eb3434' }}>
                                  {errors.confirmPassword.type === 'required'
                                    ? 'Confirm password is required'
                                    : errors.confirmPassword.message}
                                </span>
                              )}
                            </div>
                          </div>
                          {/* <div className="col-lg-6">
                          <h4>Trade References</h4>
                          <div className="form_group">
                            <input className="form-control" type="text" placeholder="Trade Ref 1" {...register('tradeRef1')} />
                          </div>
                          <div className="form_group">
                            <input className="form-control" type="text" placeholder="Trade Ref 2" {...register('tradeRef2')} />
                          </div>
                        </div>

                        <div className="col-lg-6">
                          <h4>Applicant Details</h4>
                          <div className="form_group">
                            <input className="form-control" type="text" placeholder="Name" {...register('applicantName')} />
                          </div>
                          <div className="form_group">
                            <input className="form-control" type="text" placeholder="Position" {...register('applicantPosition')} />
                          </div>
                        </div> */}
                        </div>

                        <div className="col-lg-12">
                          <h4>Terms & Conditions</h4>
                          <div className="form-check custom_check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              {...register('termsAccepted', { required: true })}
                            />
                            <label className="form-check-label">I accept the Terms & Conditions</label><br />
                            {errors.termsAccepted && <span style={{ color: '#eb3434' }}>You must accept the terms and conditions</span>}
                          </div>
                        </div>
                        <div className="login_btn mt-4 pt-3">
                          <button className={`button2 ${loading ? 'disabled' : ''}`} type="submit" disabled={loading}>
                            {loading ? ('Loading...') : ('Send Application')}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default AccountApplicationForm;
