import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import pathObj from '../../services/Config';
import { CommonService } from '../../services/common';
import { Helmet } from "react-helmet";
import debounce from "lodash.debounce";
import { errorHandling, formattedName } from "../../helpers/common";
import { CartContext } from "../../context/cartContext";

const CategoriesListing = () => {
  const { getFilterString } = useContext(CartContext);
  const { category, subCategory } = useParams();
  const [errorText, setErrorText] = useState('');
  const categoryName = category ? formattedName(category) : null;
  const subCategoryName = subCategory ? formattedName(subCategory) : null;
  const [activeItem, setActiveItem] = useState(category);
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(true); // New state for loading

  const fetchCategoryData = async () => {
    setLoading(true); // Set loading to true before fetching data
    try {
      const categoryParam = `category=${category}`;
      const subCategoryParam = subCategory ? `&subcategory=${subCategory}` : '';
      const url = `${pathObj.baseURL}${pathObj.getCategoryDetails}?${categoryParam}${subCategoryParam}`;

      const data = await CommonService.getListing(url);
      setCategoryData(data?.data);
    } catch (error) {
      if (error?.response?.data?.success === false) {
        setErrorText(error?.response?.data?.message);
        setCategoryData([]);
      }
      errorHandling(error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const debouncedFetchCategoryData = debounce(fetchCategoryData, 300); // Adjust the delay as needed
  useEffect(() => {
    debouncedFetchCategoryData(category, subCategory);
  }, [category, subCategory]);


  useEffect(() => {
    setActiveItem(subCategoryName);  // Ensure activeItem is set whenever `subCategoryName` changes
  }, [subCategoryName]);
  console.log("🚀 ~ CategoriesListing ~ subCategoryName:", subCategoryName, "||", categoryName)
  const handleSubCategoryChildClick = (category, subCategory, child, productFilterString) => {
    // getFilterString(productFilterString)
    localStorage.setItem('productFilterString',productFilterString)
  };
  

  return (
    <>
      <Helmet>
        <title>{subCategoryName || categoryName || "Default Product Name"} | Davenport Burgess</title>
        <meta name="description" content="Category page" />
      </Helmet>
      <div className="cetogry_listing" style={{ minHeight: 'calc(100vh - 300px)' }}>
        <div className="container">
          <nav aria-label="breadcrumb" >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">
                  <img src={`${pathObj?.s3ImagePath}/home-icon.svg`} alt="" />
                </a>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                <Link to={`/categories/${category}`}>
                  {categoryName == 'Mplupvc' ? 'MPL/UPVC' : categoryName}
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {subCategoryName}
              </li>
            </ol>
          </nav>

          <div className="clinner d-flex flex-wrap align-items-start pt-4">
            <div className="clSidebar">
              <ul>
                <li>
                  <Link to="#" className={activeItem === subCategoryName ? "active" : ""}>
                    {(subCategoryName == 'Mplupvc' || categoryName == 'Mplupvc') ? 'MPL/UPVC' : categoryName}
                    <svg
                      width="8"
                      height="17"
                      viewBox="0 0 8 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.799998 0.673455C0.586665 0.673455 0.386666 0.754434 0.234666 0.903332C-0.0773329 1.20896 -0.0773329 1.70529 0.234666 2.01093L6.06932 7.72652C6.27465 7.92766 6.39199 8.21239 6.39199 8.51019C6.39199 8.80799 6.27465 9.09272 6.06932 9.29386L0.234666 15.0095C0.0800001 15.161 0 15.3621 0 15.5633C0 15.7644 0.0773335 15.9655 0.234666 16.117C0.391999 16.2686 0.586665 16.3469 0.799998 16.3469C1.01333 16.3469 1.21333 16.2659 1.36533 16.117L7.19998 10.4015C7.71465 9.89729 7.99998 9.22333 7.99998 8.51019C7.99998 7.79705 7.71465 7.12309 7.19998 6.61892L1.36533 0.903332C1.21333 0.754434 1.01333 0.673455 0.799998 0.673455Z"
                        fill="currentcolor"
                      />
                    </svg>
                  </Link>
                </li>
                {categoryData.map((item) => {
                  return (
                    <li key={item.id}>
                      <div className="productThumb" >
                        <Link
                          to={item?.child_count  > 0 ? `/categories/${category}/${item?.slug}` : `/products/${category}/${item?.slug}`}
                          onClick={() => handleSubCategoryChildClick(category, subCategory, item?.slug,item?.product_filter_string)}
                          className={
                            activeItem?.trim().toLowerCase() === item?.Name?.trim().toLowerCase()
                              ? "active"
                              : ""
                          }
                        >
                          {item.Name}
                          <svg
                            width="8"
                            height="17"
                            viewBox="0 0 8 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.799998 0.673455C0.586665 0.673455 0.386666 0.754434 0.234666 0.903332C-0.0773329 1.20896 -0.0773329 1.70529 0.234666 2.01093L6.06932 7.72652C6.27465 7.92766 6.39199 8.21239 6.39199 8.51019C6.39199 8.80799 6.27465 9.09272 6.06932 9.29386L0.234666 15.0095C0.0800001 15.161 0 15.3621 0 15.5633C0 15.7644 0.0773335 15.9655 0.234666 16.117C0.391999 16.2686 0.586665 16.3469 0.799998 16.3469C1.01333 16.3469 1.21333 16.2659 1.36533 16.117L7.19998 10.4015C7.71465 9.89729 7.99998 9.22333 7.99998 8.51019C7.99998 7.79705 7.71465 7.12309 7.19998 6.61892L1.36533 0.903332C1.21333 0.754434 1.01333 0.673455 0.799998 0.673455Z"
                              fill="currentcolor"
                            />
                          </svg>
                        </Link>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="clproduct">
              {loading ? (
                <div className="loader"></div>
              ) : (
                <>
                  <h1>{subCategoryName}</h1>
                  <ul>
                    {categoryData.length > 0 ? (
                      categoryData.map((item) => {
                        // const url = (subCategory && item?.slug)
                        //   ? `/products/${category}/${subCategory}/${item?.slug}`
                        //   : (subCategory)
                        //     ? `/products/${category}/${subCategory}/${item?.slug}`
                        //     : `/categories/${category}/${item?.slug}`;
                        return (
                          <li key={item.id} style={{ minHeight: '207px' }}>
                            <Link
                              onClick={() => handleSubCategoryChildClick(category, subCategory, item?.slug, item?.product_filter_string)}
                              // to={subCategory ? `/products/${category}/${subCategory}/${item?.slug}` : `/products/${category}/${item?.slug}`}
                              to={item?.child_count > 0 
                                ? `/categories/${category}/${item?.slug}` 
                                : `/products/${category}${subCategory ? `/${subCategory}` : ''}/${item?.slug}`}
                            >
                              <figure >
                                <img src={item.image || `${pathObj?.s3ImagePath}/no-image.jpg`} alt={item.Name} />
                              </figure>
                              <div className="categoryname_row">
                                <div className="category_name"
                                  style={{ cursor: "pointer", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "150px", display: "inline-block" }}
                                  title={item.Name} // Show full name on hover
                                >
                                  {item.Name}</div>
                                {!(category === "key-blanks" || category === "key-cutting" || category === "accessories" || category === "padlocks" || category === "locks" || category === "hardware" || category === "engraving" || category === "electronic-access" || category === "zone" || category === "mplupvc" || category === "new-products" || category === "offers") ? '' : (
                                  <div className="category_qty">{item?.Count}</div>
                                )}
                              </div>
                            </Link>
                          </li>
                        )
                      })
                    ) : (
                      <div className="notFound_record">
                          <h4
                            className="not_found_category">
                            {errorText && errorText}
                          </h4>
                      </div>
                      
                    )}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoriesListing;
