import React, { useContext, useEffect, useState } from 'react'
import pathObj from '../services/Config'
import MyAccountSideBar from '../components/MyAccount/MyAccountSideBar'
import { CommonService } from '../services/common';
import { decryptData, errorHandling, UpdatedDecimalDigits } from '../helpers/common';
import toast from 'react-hot-toast';
import Swal from "sweetalert2";
import { CartContext } from '../context/cartContext';
import debounce from 'lodash.debounce';
import getSymbolFromCurrency from 'currency-symbol-map';

const FavouriteProducts = () => {
  const userInfo = window.localStorage.getItem('userData');
  const userD = decryptData(userInfo);
  const compData = JSON.parse(userD);
  const currencyName = compData?.Currency
  const currencySymbol = getSymbolFromCurrency(currencyName);
  const customerId = compData?.customer_id;
  const [favorite, setFavoriteData] = useState([]);
  const [loading, setLoading] = useState(true);
  const Currency = compData?.Currency;
  const PriceListNum = compData?.PriceListNum;
  const { removeFromCart, addToCartCount } = useContext(CartContext);
  const [quantities, setQuantities] = useState({});
  const [priceDropdownVisible, setPriceDropdownVisible] = useState({});
  const [priceBreakDown, setPriceBreakDown] = useState('');
  const [errorText, setErrorText] = useState('Products not found.');
  const [selectedPriceBreakdown, setSelectedPriceBreakdown] = useState({});
  const fetchFavoriteData = async () => {
    setLoading(true);
    const url = `${pathObj.baseURL}${pathObj.getFavouriteItems}`;
    const payload = {
      customer_id: customerId,
      Currency: Currency,
      PriceListNum: PriceListNum,
    };

    try {
      const response = await CommonService.getAllWithBodyWithToken(url, payload);
      if (response.success === true) {
        setFavoriteData(response?.data);
        setLoading(false);
      } else {
        setErrorText(response?.data?.message);
        setLoading(false);
      }
    } catch (error) {
      setErrorText(error?.response?.data?.message);
      setLoading(false);
      errorHandling(error);
    }
  };

  const debouncedFavoriteProducts = debounce(fetchFavoriteData, 300);

  useEffect(() => {
    debouncedFavoriteProducts();
  }, [customerId, Currency, PriceListNum]);

  const handleRemoveItem = async (itemId, productId) => {
    const confirm = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });

    if (confirm?.isConfirmed) {
      try {
        const url = `${pathObj.baseURL}${pathObj.deleteFavouriteItem}?id=${itemId}`;
        await CommonService.delete(url);
        setFavoriteData(prev => prev.filter(item => item.favorite_item_id !== itemId));
        // fetchFavoriteData();
        // fetchFavoriteData(prev => prev.filter(item => item?.cart_item_id !== itemId));
        removeFromCart(productId);
        // debouncedFavoriteProducts();
      } catch (error) {
        console.error("Error deleting item:", error);
        Swal.fire('Error!', 'There was an error deleting the item.', 'error');
      }
    }
  };

  const handleQuantityChange = (itemCode, type, customQuantity = null) => {
    setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      let currentQuantity = newQuantities[itemCode] || 1;
      currentQuantity = parseInt(currentQuantity);

      if (customQuantity !== null) {
        currentQuantity = parseInt(customQuantity);
      } else {
        if (type === "plus") {
          currentQuantity += 1;
        } else if (type === "minus" && currentQuantity > 1) {
          currentQuantity -= 1;
        }
      }

      newQuantities[itemCode] = currentQuantity;

      if (customQuantity === null) {
        setPriceDropdownVisible((prevVisible) => ({
          ...prevVisible,
          [itemCode]: currentQuantity > 1,
        }));
      }

      return newQuantities;
    });
  };

  const togglePriceDropdown = (itemCode) => {
    if (!priceDropdownVisible[itemCode]) {      
      setPriceDropdownVisible((prevVisible) => ({
        ...prevVisible,
        [itemCode]: !prevVisible[itemCode],
      }));
    }

  };

  const handlePriceBreakdownClick = (itemCode, quantity, priceBreakDownId, price) => {
    setPriceBreakDown(priceBreakDownId)
       // Store the selected price and quantity for this product in the state
       setSelectedPriceBreakdown((prevBreakdowns) => ({
        ...prevBreakdowns,
        [itemCode]: {
          quantity,
          price, // Use the passed price
          priceBreakDownId,
        },
      }));
    handleQuantityChange(itemCode, null, quantity);
  };

  const handleAddToCart = async (product) => {
    const quantity = quantities[product?.ItemCode] || 1;
    // Use the selected price breakdown for this product, if available
    const selectedBreakdown = selectedPriceBreakdown[product?.ItemCode] || {};
    const priceBDownIdToUse = selectedBreakdown.priceBreakDownId || priceBreakDown; // Fallback to general breakdown if needed
    
    const product_price_id = product?.ProductPrice?.[0]?.id ? product?.ProductPrice?.[0]?.id : '';
    
    const url = `${pathObj.baseURL}${pathObj.addCart}`;
    const payload = {
      customer_id: customerId,
      product_id: product?.id,
      quantity: selectedBreakdown.quantity || quantity, // Use the selected quantity
      price_break_down_id: priceBDownIdToUse, // Send the selected breakdown ID
      product_price_id,
      Csprice: selectedBreakdown.price ? selectedBreakdown.price :  product?.Csprice > 0 ? product?.Csprice : 0,
      price: selectedBreakdown.price ? selectedBreakdown.price : product?.Csprice > 0 ? 0 : selectedBreakdown.price || product?.MainProductPrice, 
      product_itemcode: product?.ItemCode,
      customer_cardcode: compData?.CardCode,
    };

    try {
      const response = await CommonService.getAllWithBodyWithToken(url, payload);
      if (response.success) {
        toast.success(response.message || `${product?.ItemCode} added to cart  successfully.!`);
        addToCartCount(product);
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error("Error adding product to cart:", error);
    }
  };

  return (
    <div className="cetogry_listing">
      <div className="container">
        <div className="clinner d-flex flex-wrap align-items-start">
          <MyAccountSideBar />
          <div className="common_right_block">
            <div className="contact_details position-relative">
              <h1>Favourite Products</h1>
              <div className="pt-4">
                <div className="common_table fpTbl table-responsive">
                  <table>
                    <thead>
                      <tr>
                        <th>Item No.</th>
                        <th className="description">Description</th>
                        <th className="text-center">QTY in Basket</th>
                        <th className="text-center">Industry Reference</th>
                        <th className="text-center">Customer Reference</th>
                        <th className="text-center">Unit Price</th>
                        <th className="text-center remove">Remove Favourite</th>
                        <th className="text-center">Action</th>
                      </tr>
                    </thead>
                    {loading ? (
                      <div className="loader productListLoader"></div>
                    ) : (
                      <>
                        <tbody>
                          {favorite.length > 0 ? (
                            favorite.map(product => {
                              return (
                                <tr key={product.id}>
                                  <td>{product.ItemCode}</td>
                                  <td className="description">{product.U_V33_WebDescription || product?.ItemName}</td>
                                  <td className="text-center">
                                    <div className="fav_qty">
                                      <>
                                        <div className="add_qty">
                                          <div className="qty_plusminus"
                                            onClick={() => togglePriceDropdown(product?.ItemCode)}
                                            style={{ cursor: "pointer" }}>
                                            <button
                                              type="button"
                                              data-type="minus"
                                              onClick={() =>
                                                handleQuantityChange(product.ItemCode, "minus")
                                              }
                                            >
                                              <img src={`${pathObj?.s3ImagePath}/minus-icon.svg`} alt="" />
                                            </button>
                                            <input
                                              type="text"
                                              value={quantities[product?.ItemCode] || 1}
                                              readOnly
                                            />
                                            <button
                                              type="button"
                                              data-type="plus"
                                              onClick={() =>
                                                handleQuantityChange(product?.ItemCode, "plus")
                                              }
                                            >
                                              <img src={`${pathObj?.s3ImagePath}/plus-icon.svg`} alt="" />
                                            </button>
                                          </div>
                                        </div>
                                        <div
                                          className="price_dropdow"
                                          style={{
                                            display:
                                              priceDropdownVisible[product?.ItemCode] &&
                                                product?.PricesBreakdown?.length > 0
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          <div className="more_qty">
                                            {product?.PricesBreakdown?.map((priceBreakdown, index) => {
                                              return (
                                                <div
                                                  className="more_qty_row"
                                                  key={priceBreakdown.id}
                                                  // onClick={() => handlePriceBreakdownClick(product?.ItemCode, priceBreakdown?.Quantity, priceBreakdown?.id)}
                                                  onClick={() =>
                                                    handlePriceBreakdownClick(
                                                      product?.ItemCode,
                                                      priceBreakdown?.Quantity,
                                                      priceBreakdown?.id,
                                                      priceBreakdown?.SpecialPrice // Pass the price here
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <label>
                                                    {priceBreakdown?.Quantity}qty
                                                  </label>
                                                  <span>
                                                    {currencySymbol}{UpdatedDecimalDigits(priceBreakdown?.SpecialPrice)}
                                                  </span>
                                                </div>
                                              )
                                            })}
                                          </div>
                                        </div>

                                      </>
                                    </div>
                                  </td>
                                  <td className="text-center"></td>
                                  <td className="text-center"></td>
                                  <td className="text-center">{currencySymbol}{product.MainProductPrice}</td>
                                  <td className="text-center remove">
                                    <a href="#" onClick={() => handleRemoveItem(product?.favorite_item_id, product.id)}>
                                      <img src={`${pathObj?.s3ImagePath}/close-icon2.svg`} alt="remove" />
                                    </a>
                                  </td>
                                  <td className="text-center">
                                    <button className="button6" onClick={() => handleAddToCart(product)}>
                                      Add to cart
                                    </button>
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td colSpan="8" className="text-center">{errorText ? errorText : 'Favourite products not found.'}</td>
                            </tr>
                          )}
                        </tbody>
                      </>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavouriteProducts;
