import React, { useContext, useEffect, useRef, useState } from 'react';
import pathObj from '../services/Config';
import { checkImage, decryptData, encryptData, errorHandling, UpdatedDecimalDigits } from '../helpers/common';
import { CommonService, currencyIcon } from '../services/common';
import { Link, useNavigate } from 'react-router-dom';
import { CartContext } from '../context/cartContext';
import { useForm } from 'react-hook-form';
import toast from "react-hot-toast";
import axios from 'axios';
import dropin from 'braintree-web-drop-in';
import AddNewAddress from './AddAddress';
import debounce from 'lodash.debounce';
import Swal from 'sweetalert2';
import md5 from "md5";
import getSymbolFromCurrency from 'currency-symbol-map';

const CheckOut = () => {
    const userInfo = window.localStorage.getItem('userData');
    const userD = decryptData(userInfo);
    const compData = JSON.parse(userD);
    const currencyName = compData?.Currency
    const currencySymbol = getSymbolFromCurrency(currencyName);
    const payTermsGrp = compData?.PayTermsGrpCode
    const customerId = compData?.customer_id;
    const [orderCartDetails, setOrderCartDetails] = useState({ items: [], deliveryAdress: [] });
    const [loading, setLoading] = useState(false);
    const checkoutItemIds = localStorage.getItem('checkoutItemIds');
    const checkoutItemCodes = localStorage.getItem('checkoutItemCodes');
    const [selectedAddress, setSelectedAddress] = useState('');    
    const [selectedOption, setSelectedOption] = useState('');
    const { setDeliveryAddress, clearCart } = useContext(CartContext);
    const [modalIsOpen, setModalIsOpen] = useState(false); // For controlling modal state
    const [imageStatus, setImageStatus] = useState({});
    const [deliveryOptions, setDeliveryOptions] = useState([]);
    const change_address = localStorage.getItem('address')
    const changeAddress = JSON.parse(change_address)
    const navigate = useNavigate();
    // const currency = currencyIcon();
    const [poReference, setPoReference] = useState(''); // State to hold the PO Reference
    const [error, setError] = useState(''); // State to handle the validation error
    const favicon = window.localStorage.getItem('favicon');
    const event = new Date();  // Get the current date and time
    
    const dayOptions = { weekday: 'long', timeZone: 'Europe/London' };  // Options for getting the day name

    // Options for getting the time
    const timeOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false, // 24-hour format
        timeZone: 'Europe/London'
    };

    const ukDay = event.toLocaleString('en-GB', dayOptions); 
    const ukTime = event.toLocaleString('en-GB', timeOptions);
    const [ukHour, ukMinute] = ukTime.split(':').map(Number);

    // Validation function for Customer PO Reference
    const validatePOReference = () => {
        if (!poReference.trim()) {
            setError('Customer PO Reference is required');
            return false;
        }
        setError('');
        return true;
    };


    // Form Hook
    const { reset } = useForm();

    // Open the modal
    const openModal = () => setModalIsOpen(true);

    // Close the modal
    const closeModal = () => {
        setModalIsOpen(false);
        reset();
    }

    // Brain tree state
    const [clientToken, setClientToken] = useState(null);
    const [instance, setInstance] = useState(null);

    const fetchOrderCartData = async () => {
        
        try {
            const customer_id = customerId;
            const product_ids = checkoutItemIds;
            const productItemCodes = checkoutItemCodes;
            const product_itemcode = productItemCodes;
            const customer_cardcode = compData?.CardCode;
            const url = `${pathObj.baseURL}${pathObj.getCheckoutDetails}`;
            const payload = { customer_id, product_ids, product_itemcode, customer_cardcode };
            const data = await CommonService.getAllWithBodyWithToken(url, payload);

            const deliveryAddresses = data?.data?.deliveryAdress || [];

            // Find the address with AddressName "MAIN"
            const mainAddress = deliveryAddresses?.find(address => address.AddressName === "MAIN");

            // Set the default selected address to "MAIN" or the first address if "MAIN" isn't found
            if (mainAddress) {
                setSelectedAddress(mainAddress?.id);
            } else if (deliveryAddresses?.length > 0) {
                setSelectedAddress(deliveryAddresses[0].id);
            }
            setDeliveryAddress(data?.data?.deliveryAdress)
            setOrderCartDetails(data?.data || { items: [], deliveryAdress: [] });
            setLoading(false);
        } catch (error) {
            console.error('Error fetching page data:', error);
            errorHandling(error);
        }
    };

    const debouncedFetchOrderCartData = debounce(fetchOrderCartData, 100);

    useEffect(() => {     
        setLoading(true);   
        debouncedFetchOrderCartData(customerId, checkoutItemIds, checkoutItemCodes);
    }, [customerId, checkoutItemIds, checkoutItemCodes]);

    const [countriesId, setSelectedCountries] = useState('')
    useEffect(() => {
        // Fetch the initial delivery options for the default selected address
        if (selectedAddress) {
            const fetchDeliveryOptions = async () => {
                try {
                    const customer_id = customerId;
                    const address_id = selectedAddress;
                    const country_code = countriesId
                    const url = `${pathObj.baseURL}${pathObj.getDeliveryOptions}`;
                    const payload = { customer_id, address_id, country_code };
                    const data = await CommonService.getAllWithBodyWithToken(url, payload);                

                    if (data?.success) {
                        const options = data?.data || [];

                        // Set the delivery options
                        setDeliveryOptions(options);

                        // Find the default option and set it as selected
                        const defaultOption = options.find(option => option.default_options);

                        if (defaultOption) {
                            setSelectedOption(defaultOption);
                        }
                    } else {
                        setDeliveryOptions([]);
                    }
                } catch (error) {
                    console.error('Error fetching delivery options:', error);
                    setDeliveryOptions([]);
                }
            };

            fetchDeliveryOptions();
        }
    }, [selectedAddress, countriesId]);

    // Combine items with the same ItemCode and sum their quantities and totals
    const aggregatedCartDetails = orderCartDetails?.items?.reduce((acc, item) => {
        const existingItem = acc.find(cartItem => cartItem?.ItemCode === item?.ItemCode);
        if (existingItem) {
            existingItem.quantity += item.quantity;
            existingItem.total += item.total;
        } else {
            acc.push({ ...item });
        }
        return acc;
    }, []);

    const loadImages = async () => {
        const status = { ...imageStatus }; // Copy the current image status
        for (const product of orderCartDetails?.items) {
          const pictureCheck = await checkImage(product?.Picture);
          const vectorPictureCheck = await checkImage(product?.vectorPicture);
    
          // Check if the picture URL works, if not check vectorPicture, if both fail use the no-image.jpg
          if (pictureCheck?.status === "ok") {
            status[product?.ItemCode] = pictureCheck?.path;
          } else if (vectorPictureCheck?.status === "ok") {
            status[product?.ItemCode] = vectorPictureCheck?.path;
          } else {
            status[product?.ItemCode] = `${pathObj?.s3ImagePath}/no-image.jpg`;
          }
    
          // Update image status for the current product as soon as it's ready
          setImageStatus((prevStatus) => ({ ...prevStatus, [product?.ItemCode]: status[product?.ItemCode] }));
        }
      };
    
      const debouncedFetchLoadImages = debounce(loadImages, 300); // Adjust the delay as needed
      useEffect(() => {
        if (orderCartDetails?.items?.length > 0) {
          debouncedFetchLoadImages();
        }
      }, [orderCartDetails?.items]);

    useEffect(() => {
        const fetchBraintreeToken = async () => {
            try {
                const token = localStorage.getItem("Token"); // Get the token from localStorage
                const response = await axios.get(`${pathObj?.baseURL}${pathObj?.brainTreeToken}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // Adding Bearer token correctly
                    }
                });
                setClientToken(response?.data?.token);
            } catch (error) {
                console.error('Error fetching Braintree token', error);
            }
        };

        fetchBraintreeToken();
    }, []);

    useEffect(() => {
        if (clientToken) {
            dropin.create({
                authorization: clientToken,
                container: '#dropin-container'
            }, (error, dropinInstance) => {
                if (error) {
                    // console.error('Error creating Brain tree Drop-in UI', error);
                } else {
                    setInstance(dropinInstance);
                }
            });
        }
    }, [clientToken]);



    const subtotal = aggregatedCartDetails?.reduce((acc, item) => acc + Number(item.total), 0);
    const vatPercentage = orderCartDetails?.vat || 0;
    const deliveryCharge = subtotal > selectedOption?.U_V33_OrderValue ? 0 : selectedOption?.U_V33_DeliveryCharge ? Number(selectedOption?.U_V33_DeliveryCharge) : 0;
    // Corrected includingVat calculation to apply VAT on subtotal + deliveryCharge

    const [deliveryChargedOptions, setDeliveryChargedOptions] = useState(deliveryCharge);

    useEffect(() => {
      setDeliveryChargedOptions(deliveryCharge);
    }, [deliveryCharge]);  // Only update when deliveryCharge changes

    const handleAddressChange = (newAddress) => {
        setSelectedCountries(newAddress.Country)
        setSelectedAddress(newAddress.id);
        // setDeliveryChargedOptions(0)
        const temp = [...orderCartDetails?.deliveryAdress];

        const existingAddressIndex = temp.findIndex(address => address.id === newAddress.id);

        if (existingAddressIndex !== -1) {
            const updatedAddresses = [...temp];
            updatedAddresses[existingAddressIndex] = {
                ...updatedAddresses[existingAddressIndex],
                ...newAddress
            };
            setOrderCartDetails(prev => ({ ...prev, deliveryAdress: updatedAddresses }));
        } else {
            setOrderCartDetails(prev => ({
                ...prev,
                deliveryAdress: [...prev.deliveryAdress, newAddress], ...temp
            }));
        }


    };
    
    const totalBeforeVat = subtotal + deliveryChargedOptions;
    const includingVat = (totalBeforeVat * (vatPercentage / 100)); 
    const accuratePrice = selectedOption?.U_V33_OrderValue < subtotal
    ? (subtotal + parseFloat(includingVat))
    : (subtotal + (selectedOption?.U_V33_DeliveryCharge || 0) + parseFloat(includingVat));




    const createOrderPayload = (orderCartDetails, customerId, selectedOption, poReference, accuratePrice) => {
        const { items: orderItems } = orderCartDetails;

        // Helper function to create payload for both single and multiple items
        const createPayload = (items) => items.map(item => ({
            price_break_down_id: item?.product_price_breakdown_id || null,
            product_price_id: item?.product_price_id,
            quantity: item?.quantity,
            product_id: item?.id,
        }));

        // Build the payload
        return {
            customer_id: customerId,
            product_items: createPayload(orderItems),
            delivery_option: selectedOption?.id,
            change_address: orderCartDetails?.deliveryAdress,
            NumAtCard: poReference,
            amount: accuratePrice, // Include the accuratePrice if needed
        };
    };

    const handleProceedToPayment = async () => {
        if (validatePOReference()) {
            setLoading(true);
            if (!orderCartDetails || !orderCartDetails.items || orderCartDetails.items.length === 0) {
                toast.error('Your cart is empty. Please add items to proceed to payment.');
                return;
            }
            
            if(subtotal < selectedOption.U_V33_OrderValue &&  (selectedOption.U_V33_MinimumSpendValue != null && subtotal > selectedOption.U_V33_MinimumSpendValue)){
                const confirm = await Swal.fire({
                    title: "Avoid Delivery Charges ?",
                    text: `Add ${currencySymbol}${UpdatedDecimalDigits(selectedOption.U_V33_OrderValue - subtotal)} more to your Order for free shipping.`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Continue with order',
                    cancelButtonText: 'Edit order',
                });
                if (!confirm.isConfirmed) { 
                    setLoading(false);
                    return; 
                }
            }
            
            // try {
            //     const payload = createOrderPayload(orderCartDetails, customerId, selectedOption, poReference, accuratePrice);
            //     let paymentSuccess = false;

            //     if (payTermsGrp === 17 || payTermsGrp !== 17) {
            //         if (instance) {
            //             try {
            //                 const { nonce } = await instance.requestPaymentMethod();
            //                 const token = localStorage.getItem("Token");

            //                 const paymentResponse = await axios.post(`${pathObj.baseURL}${pathObj.brainTreeCheckout}`, {
            //                     ...payload,
            //                     nonce,
            //                 }, {
            //                     headers: {
            //                         'Content-Type': 'application/json',
            //                         'Authorization': `Bearer ${token}`
            //                     }
            //                 });

            //                 if (paymentResponse?.data?.success) {
            //                     new Promise((resolve, reject) => {
            //                         toast.success(paymentResponse.data.message || 'Your payment is being processed..');
            //                         paymentSuccess = true;
            //                         clearCart();
            //                         localStorage.removeItem('address');
            //                         setLoading(false);
            //                         setTimeout(() => {
            //                             navigate('/thank-you');
            //                         }, 1000);
            //                     });
            //                 } else {
            //                     console.log("test error");
            //                     toast.error(paymentResponse.data.message || 'Payment failed. Please try again.');
            //                     setLoading(false);
            //                 }
            //             } catch (paymentError) {
            //                 // console.log("paymentError");
            //                 // console.log(paymentError);
            //                 // toast.error(paymentError?.response?.data?.data?.delivery_option?.[0] || paymentError?.message || 'Payment failed.');
            //                 toast.error(paymentError?.response?.data?.message || paymentError?.message || 'Payment failed.');
            //                 setLoading(false);
            //                 errorHandling(paymentError);
            //             }
            //         }
            //     }
            // } catch (error) {
            //     toast.error(error?.response?.data?.data?.delivery_option?.[0] || error?.message || 'Failed to process the payment. Please check the required fields.');
            //     setLoading(false);
            //     errorHandling(error);
            // }

            try {
                // console.log("orderCartDetails = ", orderCartDetails, "customerId = ", customerId, "selectedOption = ", selectedOption, "poReference = ", poReference, "accuratePrice = ", accuratePrice)
                const payload = createOrderPayload(orderCartDetails, customerId, selectedOption, poReference, accuratePrice);
                
                let paymentSuccess = false;
                
                if (payTermsGrp === 17 || payTermsGrp !== 17) {
                    
                    
                    try {

                        const token = localStorage.getItem("Token");
                        const paymentResponse = await axios.post(`${pathObj.baseURL}${pathObj.brainTreeCheckout}`, {
                            ...payload,
                        }, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            }
                        });
                        
                        if (paymentResponse?.data?.success) {

                            const formData = {
                                testMode: pathObj.worldpayPaymentMode, // 100 for test mode
                                instId: pathObj.worldpayInstId, // Worldpay institution ID
                                cartId: encryptData(JSON.stringify(payload?.product_items)), 
                                amount: payload?.amount, 
                                currency: currencyName, 
                                MC_myText: paymentResponse?.data?.order_id,
                                MC_myCs: paymentResponse?.data?.customer_id,
                                MC_callback: `${pathObj.baseURL}${pathObj.worldpayCallback}`,
                                MC_returnurl: `${pathObj.frontUrl}/thank-you`, 
                                MC_cancelurl: `${pathObj.frontUrl}/checkout`,
                                signature: md5(`${pathObj.worldpayPassword}:${currencyName}:${payload?.amount}:${pathObj.worldpayInstId}`),
                                signatureField: `${currencyName}:${payload?.amount}:${pathObj.worldpayInstId}`,
                                password: pathObj.worldpayPassword,
                            };
                            
                            const form = document.createElement('form');
                            form.action = pathObj.worldpayPaymentUrl;
                            form.method = 'POST';

                            Object.keys(formData).forEach((key) => {
                            const input = document.createElement('input');
                            input.type = 'hidden';
                            input.name = key;
                            input.value = formData[key];
                            form.appendChild(input);
                            });
                            document.body.appendChild(form);
                            form.submit();

                        } else {
                            toast.error(paymentResponse.data.message || 'Payment failed. Please try again.');
                            setLoading(false);
                        }
                        
                        

                    } catch (paymentError) {
                        toast.error(paymentError?.response?.data?.message || paymentError?.message || 'Payment failed.');
                        setLoading(false);
                        errorHandling(paymentError);
                    }
                }
            } catch (error) {
                toast.error(error?.response?.data?.data?.delivery_option?.[0] || error?.message || 'Failed to process the payment. Please check the required fields.');
                setLoading(false);
                errorHandling(error);
            }
        }
    };

    const handleShipping = (optionValue) => {
        if((optionValue.id === 3 && ukDay === 'Friday' && (ukHour > 16 || (ukHour === 16 && ukMinute >= 30))) || 
            (optionValue.id === 1 && (ukHour > 15 || (ukHour === 15 && ukMinute >= 30))) ||
            (optionValue.id === 7 && ukDay === 'Friday' && (ukHour > 16 || (ukHour === 16 && ukMinute >= 30))) || 
            (optionValue.id === 7 && (ukHour > 15 || (ukHour === 15 && ukMinute >= 30))) 
        ) {
           Swal.fire({
                title: "Shipping Alert!",
                text: "Your order is placed outside of business hours and will be shipped on the next working day.",
                icon: 'warning',
                confirmButtonText: 'Okay', 
            });
        }
        // console.log("🚀 ~ handleShippig ~ optionValue:", optionValue) 
        setSelectedOption(optionValue)       
    };

    const handleAddToAccount = async () => {
        if (validatePOReference()) {
            setLoading(true);

            if(subtotal < selectedOption.U_V33_OrderValue &&  (selectedOption.U_V33_MinimumSpendValue != null && subtotal > selectedOption.U_V33_MinimumSpendValue)){
                const confirm = await Swal.fire({
                    title: "Avoid Delivery Charges ?",
                    text: `Add ${currencySymbol}${UpdatedDecimalDigits(selectedOption.U_V33_OrderValue - subtotal)} more to your Order for free shipping.`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Continue with order',
                    cancelButtonText: 'Edit order',
                });
                if (!confirm.isConfirmed) { 
                    setLoading(false);
                    return; 
                }
            }

            const confirm = await Swal.fire({
                title: "Order Submission Confirmation?",
                // text: "You want to use your credit for the order?",
                iconHtml: `<img src="https://d151gqn7auphjd.cloudfront.net/images/favicon-icon.svg" alt="Site Icon" style="width:50px; height:50px;">`, // Use backticks for template literals
                customClass: {
                    icon: 'custom-icon'
                },
                showCancelButton: true,
                confirmButtonText: 'Yes, Proceed',
                cancelButtonText: 'No, Cancel',
            });
                       
            if (confirm.isConfirmed) {
                try {
                    const payload = createOrderPayload(orderCartDetails, customerId, selectedOption, poReference, accuratePrice);
                    const url = `${pathObj.baseURL}${pathObj.addToAccount}`;
                    await CommonService.getAllWithBodyWithToken(url, payload);
                    toast.success('Your Order has been successfully submitted.');
                    clearCart();
                    localStorage.removeItem('address'); // Assuming getListing will handle the payload
                    setLoading(false);
                    setTimeout(() => {
                        // navigate('/thank-you');
                        navigate('/thank-you', { state: { message: 'Your Order has been successfully submitted.' } });
                    }, 1000);
                    // Swal.fire('Success!', 'Your item has been added to the account.', 'success');
                } catch (error) {
                    console.error("Error adding item to account:", error);
                    setLoading(false);
                    Swal.fire('Error!', 'There was an error adding the item to the account.', 'error');
                }
            }else{
                setLoading(false);
                return; 
            }
        }
    };

    return (
        <>
            {loading && (<div className='loaderOuter'><div className="loader"></div></div>)}
            <div className="checkout_wrap">
                <div className="container">
                    <div className="clinner">
                        <h1>Order Summary</h1>
                        <div className="d-flex flex-wrap">
                            <div className="checkout_cart">
                                <div className="cart_tbl">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th className="product_thumbnail">Product</th>
                                                <th className="product_name">&nbsp;</th>
                                                <th className="product_price">Price</th>
                                                <th className="product_quantity text-center">Quantity</th>
                                                <th className="product_subtotal text-end">Subtotal</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {aggregatedCartDetails.map(item => (
                                                <tr key={item.id}>
                                                    <td className="product_thumbnail">
                                                        {/* {imageStatus[item?.ItemCode] ? (
                                                            <div className="loader"></div> // Show loader until image status is resolved
                                                        ) : ( */}
                                                            <a href="#">
                                                                <img
                                                                src={imageStatus[item?.ItemCode] || item?.Picture || item?.vectorPicture || `${pathObj?.s3ImagePath}/no-image.jpg`}
                                                                onError={(e) => {
                                                                e.target.onerror = null;
                                                                e.target.src = `${pathObj?.s3ImagePath}/no-image.jpg`;
                                                                }}
                                                                alt={item.ItemName}
                                                                />
                                                            </a>
                                                        {/* )} */}
                                                    </td>
                                                    <td className="product_name">
                                                        <p>{item?.ItemCode}</p>
                                                        <p>{item?.U_V33_WebProductName}</p>
                                                        {item?.ItemName && (
                                                            <div className="variant">
                                                                <span>Variant:</span> {item?.U_V33_WebProductName}
                                                            </div>
                                                        )}
                                                        {item?.ForeignName && (
                                                            <div className="variant">
                                                                <span>Original Manufacture:</span> {item?.ForeignName}
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className="product_price" data-title="Price">{currencySymbol}{item?.Csprice > 0 ? item?.Csprice : item?.MinProductPrice ? item?.MinProductPrice : item?.Price}</td>
                                                    <td className="product_quantity text-center" data-title="Quantity">{item?.quantity}</td>
                                                    <td className="product_subtotal text-end" data-title="Subtotal">{currencySymbol}{UpdatedDecimalDigits(item?.total)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="gotocart_btn">
                                    <Link to={`/cart`} className="button1">Go To Cart</Link>
                                </div>
                            </div>
                            <div className="checkout_right">
                                <h3>Delivery Address</h3>
                                <div className="common_form">
                                    <div className="form_group">
                                        <select
                                            className="form-control form-select"
                                            value={selectedAddress}
                                            onChange={(e) => setSelectedAddress(e.target.value)}
                                        >
                                            {orderCartDetails.deliveryAdress
                                            .filter(address => 
                                                address && // Ensure the address is not null
                                                Object.values(address).some(value => value !== null && value !== 'null' && value !== '') // Ensure at least one value is valid
                                            )
                                            .map(address => {
                                                // Build an array of address components, filtering out invalid values
                                                const addressComponents = [
                                                    address?.Street,
                                                    address?.StreetNo,
                                                    address?.City,
                                                    address?.County,
                                                    address?.Country !== 'null' ? address?.Country : '',
                                                    address?.Block,
                                                    address?.ZipCode !== 'null' ? address?.ZipCode : ''
                                                ].filter(component => component); // Filter out null, undefined, and empty strings

                                                // Join valid components with a comma
                                                const formattedAddress = addressComponents.join(', ');

                                                return (
                                                    <option key={address?.id} value={address?.id}>
                                                        {formattedAddress}
                                                    </option>
                                                );
                                            })}

                                        </select>
                                    </div>
                                </div>

                                <div className="addnewaddress">
                                    <a href="#" onClick={openModal}>Change Address</a>
                                </div>
                                <AddNewAddress show={modalIsOpen} hide={closeModal} openModal={openModal} onAddressChange={handleAddressChange} selectedAddress={selectedAddress} />
                                {/* {deliveryOptions.length > 0 && */}
                                {deliveryOptions.length > 0  &&
                                    <div className="delivery_options">
                                        <h3>Delivery Options</h3>
                                        <p style={{ fontSize: '16px', color: '#eb3434' }}>The option you have selected will result in additional delivery charges of <span style={{ fontSize: '16px', fontWeight: 'bold', color: '#000' }}>{currencySymbol}{UpdatedDecimalDigits(deliveryChargedOptions)}</span></p>
                                        <ul>
                                            {deliveryOptions.map(option => {
                                                return (
                                                    <li key={option.id} >
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="radio"
                                                                value={option.id}
                                                                id={option.id}
                                                                checked={selectedOption?.id === option.id}
                                                                onChange={() => handleShipping(option)}
                                                                // disabled={
                                                                //     (option.id === 3 && ukDay === 'Friday' && (ukHour > 10 || (ukHour === 10 && ukMinute >= 30))) ||
                                                                //     (option.id === 1 && (ukHour >= 10))
                                                                // }
                                                            />
                                                            <label
                                                                className={selectedOption?.id === option.id ? 'form-check-label active' : 'form-check-label'}
                                                                htmlFor={option?.id}
                                                            >
                                                                <span>{option?.Name}</span>
                                                                <b>{option?.U_V33_DeliveryCharge ? `${currencySymbol}${UpdatedDecimalDigits(option.U_V33_DeliveryCharge)}` : ''}</b>
                                                            </label>
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                }
                                <div className="common_form">
                                    <div className="form_group">
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Customer PO Reference*"
                                            name="NumAtCard"
                                            value={poReference}
                                            onChange={(e) => setPoReference(e.target.value)}
                                        />
                                        {error && <p style={{ color: '#eb3434' }}>{error}</p>}
                                    </div>
                                </div>
                                <div className="summary_bx">
                                    <h3>Summary</h3>
                                    <ul>
                                        {/* <li>
                                        <label>Apply Coupons:</label>
                                        <span><input type="text" placeholder="Enter coupon code" /></span>
                                    </li> */}
                                        <li>
                                            <label>Subtotal:</label>
                                            <span>{currencySymbol}{UpdatedDecimalDigits(subtotal)}</span>
                                        </li>
                                        <li>
                                            <label>Delivery Charge: 
                                                <br />
                                                {deliveryCharge === 0 && <span style={{fontSize:'12px' , fontWeight:'normal', fontStyle:'italic', color: '#eb3434'}}>
                                                If the total amount exceeds {currencySymbol} {selectedOption.U_V33_OrderValue}, delivery is free. 
                                                </span>
                                                }</label>
                                            <span>{currencySymbol}{UpdatedDecimalDigits(deliveryChargedOptions)}</span>
                                        </li>
                                        <li>
                                            <label>VAT({orderCartDetails?.vat})% : </label>
                                            <span>{currencySymbol}{UpdatedDecimalDigits(includingVat)}</span>
                                        </li>
                                        <li>
                                            <label>Total:</label>
                                            <span>
                                                {currencySymbol}{UpdatedDecimalDigits(accuratePrice)}
                                            </span>
                                        </li>
                                    </ul>
                                    {/* <div id="dropin-container"></div> */}

                                    <div className="btn_row pb-1">
                                        {/* {payTermsGrp === 17 && <a href="#" className="button3">Add To Account</a>} */}
                                        {payTermsGrp !== 17 &&
                                            <Link
                                                className="button2"
                                                onClick={() => handleAddToAccount()}
                                            >
                                                Add To Account
                                            </Link>
                                        }
                                        <Link
                                            className="button2"
                                            onClick={() => handleProceedToPayment()}
                                        >
                                            Proceed To Payment
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default CheckOut;
