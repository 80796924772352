import React from 'react';
import { useForm } from 'react-hook-form';

const Register = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
      <div className="login_register">
        <div className="container">
          <div className="lrinner d-flex flex-wrap">
            <h1 className="mb-2">Register New Web Account</h1>
            <div className="common_form pt-4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row gx-6">
                  <div className="col-lg-6">
                    <div className="form_group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Account Number"
                        {...register('accountNumber', { required: 'Account Number is required' })}
                      />
                      {errors.accountNumber && <span className="error">{errors.accountNumber.message}</span>}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form_group register_content">
                      <p>Please enter your Davenport Burgess account number and your postcode to retrieve your account details.</p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form_group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Post Code"
                        {...register('postCode', { required: 'Post Code is required' })}
                      />
                      {errors.postCode && <span className="error">{errors.postCode.message}</span>}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form_group register_content">
                      <p>Your account number can be found on your latest statement, invoice, or delivery note.</p>
                    </div>
                  </div>
                </div>
                <div className="login_btn">
                  <input className="button2" type="submit" value="Find My Details" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Register;
