import React, { useEffect, useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { CommonService } from "../../services/common";
import pathObj from "../../services/Config";
import NewsSideBar from "./NewsSideBar"; // Import the new component
import { monthMapping } from "../../helpers/common";
import { Helmet } from "react-helmet";
const News = () => {
  const { type, month } = useParams();
  const [newsSideBar, setNewsSideBar] = useState([]);
  const [pageData, setPageData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const monthMapData = monthMapping();

  useEffect(() => {
    const fetchSideBarData = async () => {
      try {
        const data = await CommonService.getListing(
          `${pathObj.baseURL}${pathObj?.newsCategories}`
        );
        setNewsSideBar(data?.data);
      } catch (error) {
        console.error("Error fetching sidebar data:", error);
      }
    };

    fetchSideBarData();
    
    switch (type) {
      case "Company_News":
        handleSidebarClick("");
        break;
      case "exhibitions":
        handleSidebarClick(2);
        break;
      case "all-news":
        handleSidebarClick("all");
        break;
      default:
        handleSidebarClick(type);
        break;
    }
  }, [type]);

  const handleSidebarClick = async (id, isArchive = false) => {
    setLoading(true); // Start the loader
    try {
      let url;
      if (isArchive) {
        if (id.includes('-')) {
          const [year, monthName] = id.split('-');

          const monthMap = monthMapData
          const month = monthMap[monthName || month];

          url = `${pathObj.baseURL}${pathObj?.getNewsList}?year=${year}&month=${month}`;
        } else  {
          url = `${pathObj.baseURL}${pathObj?.getNewsList}?year=${id}`;
        }
      } else if (id === "all" ) {
        url = `${pathObj.baseURL}${pathObj?.getNewsList}?type=`;
      } 
      else {
        url = `${pathObj.baseURL}${pathObj?.getNewsList}?type=${id}`;
      }

      const response = await CommonService.getListing(url);

      if (response.success) {
        const data = response.data;
        setPageData(data)
        if (isArchive) {
          if (id.includes('-')) {
            const [year, monthName] = id.split('-');
            const monthData = data[year]?.[monthName];

            setPageData(Array.isArray(monthData) ? monthData : []);
          } else {
            setPageData({ [id]: data[id] || {} });
          }
        } else {
          setPageData(Array.isArray(data) ? data : []);
        }
      } else {
        setPageData([]);
      }
    } catch (error) {
      console.error("Error fetching page data:", error);
      setPageData([]);
    } finally {
      setLoading(false); // Stop the loader once data is fetched
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageData?.meta_title}</title>
        <meta name="description" content={pageData?.meta_description} />
      </Helmet>
    <div className="cetogry_listing">
      <div className="container">
        <div className="clinner d-flex flex-wrap align-items-start">
          <NewsSideBar
            newsSideBar={newsSideBar}
            handleSidebarClick={handleSidebarClick}
            type={type}
            selectedMonth={month}
          />
          {loading ? (
            <div className="loader"></div>
          ) : (
            <div className="common_right_block">
              <div className="news_article_list">
                <ul>
                  {Array.isArray(pageData) && pageData.length > 0 ? (
                    pageData.map((newsItem, index) => {
                      const slug = `/news/${type || "all"}/${newsItem.id}/${newsItem.slug.toUpperCase().replace(/ /g, "-")}`;

                      return (
                        <li key={newsItem.id}>
                          <div className="nal_img">
                            <Link to={slug}>
                              <img
                                src={newsItem.image || `${pathObj?.s3ImagePath}/no-image.jpg`}
                                alt={newsItem.title}
                              />
                            </Link>
                          </div>
                          <div className="nal_content">
                            <h3>{newsItem.title}</h3>
                            <p dangerouslySetInnerHTML={{ __html: newsItem.short_content }}></p>
                            <Link to={slug} className="button1">
                              Read More
                            </Link>
                          </div>
                        </li>
                      );
                    })
                  ) : typeof pageData === 'object' && Object.keys(pageData).length > 0 ? (
                    Object.entries(pageData).map(([year, monthsData]) => (
                      <li key={year}>
                        {Object.entries(monthsData).map(([monthName, newsArray]) => (
                          <div key={monthName}>
                            <ul>
                              {Array.isArray(newsArray) && newsArray.length > 0 ? (
                                newsArray.map((newsItem, index) => {
                                  const slug = `/news/${type || "all"}/${newsItem.id}/${newsItem.slug.toUpperCase().replace(/ /g, "-")}`;

                                  return (
                                    <li key={newsItem.id}>
                                      <div className="nal_img">
                                        <Link to={slug}>
                                          <img
                                            src={newsItem.image || `${pathObj?.s3ImagePath}/no-image.jpg`}
                                            alt={newsItem.title}
                                          />
                                        </Link>
                                      </div>
                                      <div className="nal_content">
                                        <h3>{newsItem.title}</h3>
                                        {newsItem.short_content ? (
                                          <p dangerouslySetInnerHTML={{ __html: newsItem.short_content }}></p>
                                        ) : (
                                          <p>No summary available.</p>
                                        )}
                                        <Link to={slug} className="button1">
                                          Read More
                                        </Link>
                                      </div>
                                    </li>
                                  );
                                })
                              ) : (
                                <li>No news available for this month.</li>
                              )}
                            </ul>
                          </div>
                        ))}
                      </li>
                    ))
                  ) : (
                    <li>News not found.</li>
                  )}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
    
    </>
  );
};

export default News;
