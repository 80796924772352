// ** Third Party Imports
import axios from 'axios'
import { decryptData } from './common'

// import authConfig from '../../src/services/auth'

// import { decryptData } from '../../src/helpers/common'

export const axiosWrapper = {
  get,
  postFile,
  post,
  postToken,
  getToken,
  postTesting,
  put,
  delete: _delete
}

function get(url) {
  // const storedToken = window.localStorage.getItem(authConfig.storageTokenKeyName)!
  // const decodedToken = decryptData(storedToken)
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  }

  return axios(url, requestOptions).then(handleResponse)
}

function getToken(url,body) {
  const Authorization = localStorage.getItem("Token");
  console.log("🚀 ~ getToken ~ Authorization:", Authorization)
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${Authorization}` }
  }

  return axios(url, JSON.stringify(body), requestOptions).then(handleResponse)
}

function postFile(url, body) {
  // const storedToken = window.localStorage.getItem(authConfig.storageTokenKeyName)!
  // const decodedToken = decryptData(storedToken)
  const requestOptions = {
    headers: { 'Content-Type': 'multipart/form-data', }
  }

  return axios.post(url, body, requestOptions).then(handleResponse)
}

function post(url, body) {
  // const storedToken = window.localStorage.getItem(authConfig.storageTokenKeyName)!
  // const decodedToken = decryptData(storedToken)
  const requestOptions = {
    headers: { 'Content-Type': 'application/json',}
  }

  return axios.post(url, JSON.stringify(body), requestOptions).then(handleResponse)
}
function postToken(url, body) {
  const Authorization = localStorage.getItem("Token");
  const requestOptions = {
    headers: { 'Content-Type': 'application/json','Authorization': `Bearer ${Authorization}` }
  }

  return axios.post(url, JSON.stringify(body), requestOptions).then(handleResponse)
}

function postTesting(url, body) {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json' }
  }

  return axios.post(url, JSON.stringify(body), requestOptions).then(handleResponse)
}

function put(url, body) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  }

  return axios(url, requestOptions).then(handleResponse)
}

// prefixed with underscored because delete is a reserved word in javascript
// function _delete(url) {
//   const requestOptions = {
//     method: 'DELETE'
//   }

//   return axios(url, requestOptions).then(handleResponse)
// }

function _delete(url) {
  const Authorization = localStorage.getItem("Token"); // Get the token
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Authorization': `Bearer ${Authorization}` } // Include the token in headers
  }

  return axios(url, requestOptions).then(handleResponse)
}

// helper functions

function handleResponse(response) {
  if (response.status === 200) {
    return response.data
  } else {
    return response.message
  }
}
