import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const MyAccountSideBar = () => {
    const location = useLocation();
    const pathCheck = location?.pathname;

    return (
        <div className="clSidebar common_sidebar">
            <ul>
                <li>
                    <Link 
                        to="/account-details" 
                        className={pathCheck === "/account-details" ? "active" : ""}
                    >
                        Account Details
                    </Link>
                </li>
                <li>
                    <Link 
                        to="/change-password" 
                        className={pathCheck === "/change-password" ? "active" : ""}
                    >
                        Change Password
                    </Link>
                </li>
                <li>
                    <Link 
                        to="/order-history" 
                        className={pathCheck === "/order-history" ? "active" : ""}
                    >
                        Order History
                    </Link>
                </li>
                <li>
                    <Link 
                        to="/favourite-products" 
                        className={pathCheck === "/favourite-products" ? "active" : ""}
                    >
                        Favourite Products
                    </Link>
                </li>
                {/* <li>
                    <Link 
                        to="/amend-account" 
                        className={pathCheck === "/amend-account" ? "active" : ""}
                    >
                        Amend Account
                    </Link>
                </li> */}
                {/* <li>
                    <Link 
                        to="/account-balance" 
                        className={pathCheck === "/account-balance" ? "active" : ""}
                    >
                        Account Balance / Pay Bill
                    </Link>
                </li> */}
            </ul>
        </div>
    );
}

export default MyAccountSideBar;
