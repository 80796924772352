import React from 'react';
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import LandingPage from "../../src/pages/LandingPage";
import AccountApplication from '../components/auth/AccountApplication';
import ForgotPassword from '../components/auth/ForgotPassword';
import Login from '../components/auth/Login';
import ResetPassword from '../components/auth/ResetPassword';
import CheckOut from '../pages/CheckOut';
import CommonLayoutInfo from '../pages/CommonLayoutUsefulInfo';
import CategoriesListing from '../pages/Listing/CategoriesListing';
import ProductListing from '../pages/Listing/ProductListing';
import ContactForm from '../pages/Contact/ContactForm';
import ContactDetails from '../pages/Contact/ContactDetails';
import AboutUs from '../pages/AboutUs';
import Catalogues from '../pages/Catalogues';
import OrderHistory from '../pages/Details/OrderHistory';
import News from '../pages/News-Technical/News';
import Personalisedkeys from '../pages/Personalised-keys';
import Register from '../components/auth/Register';
import TransferAccount from '../components/auth/TransferAccount';
import NewsDetails from '../pages/News-Technical/NewsDetails';
import ProductDetails from '../pages/Details/ProductDetails';
import FavouriteProducts from '../pages/Favourite-products';
import ChangePassword from '../components/MyAccount/ChangePassword';
import AccountDetails from '../components/MyAccount/AccountDetails';
import AmendAccount from '../components/MyAccount/AmendAccount';
import AccountBalance from '../components/MyAccount/AccountBalance';
import NotFound from '../pages/Notfound';
import LoopFobs from '../pages/LoopFobs';
import Cart from '../pages/ProductCart/Cart';
import SearchResult from '../pages/Listing/SearchResult';
import GroupProductDetails from '../pages/Details/GroupProductDetails';
import ThankYouPage from '../pages/ThankYouPage';
import QuickOrder from '../pages/ProductCart/QuickOrder';
import NewProduct from '../pages/Listing/NewProduct';
const PublicRoute = () => {
    const token = localStorage.getItem("Token");
    const Navigate = useNavigate();
    const location = useLocation();
    const ResetPasswordToken = location.pathname.split('/').pop();
    console.log("token", ResetPasswordToken)
    return (
        <>

            <Routes>

                {/* Public routes */}
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                {/* <Route path="/reset-password/:token" element={token && ResetPasswordToken? <ResetPassword /> : <Login />} /> */}
                <Route path="/account_application" element={<AccountApplication />} />
                <Route path="/contact_us" element={<ContactForm />} />
                <Route path="/technical_support" element={<ContactForm />} />
                <Route path="/sales" element={<ContactForm />} />
                <Route path="/contact_details" element={<ContactDetails />} />
                <Route path="/about_us" element={<AboutUs />} />
                {/* <Route path="/catalogues" element={<Catalogues />} /> */}

                <Route path="/news/:type" element={<News />} />
                <Route path="/news/:type/:month" element={<News />} />
                <Route path="/news/:type/:newsId/:name" element={<NewsDetails />} />
                <Route path="/personalised-keys" element={<Personalisedkeys />} />
                <Route path="/transfer-account" element={<TransferAccount />} />
                <Route path="/register-account" element={<Register />} />
                <Route path="/search/:text" element={<SearchResult />} />
                <Route path="/new-products" element={<NewProduct />} />


                {/* Dynamic route for CommonLayoutInfo */}
                <Route path="/:pageType" element={<CommonLayoutInfo />} />
                <Route path="/loop-fobs" element={<LoopFobs />} />
                <Route path="/products/:category/:subCategory/:child" element={<ProductListing />} />
                <Route path="/products/:category/:subCategory" element={<ProductListing />} />
                <Route path="/products/:itemCode" element={<ProductDetails />} />
                <Route path="/group-products/:itemCode" element={<GroupProductDetails />} />
                <Route path="/categories/:category/:subCategory" element={<CategoriesListing />} />
                <Route path="/categories/:category" element={<CategoriesListing />} />
               

                {/* Redirect all unknown paths to NotFound */}
                <Route path="/404-not-found" element={<NotFound />} />


                {/* Protected route for Cart */}
                <Route path="/cart" element={token ? <Cart /> : <Login />} />
                <Route path="/quick-order" element={token ? <QuickOrder /> : <Login />} />
                <Route path="/change-password" element={token ? <ChangePassword /> : <Login />} />
                <Route path="/account-details" element={token ? <AccountDetails /> : <Login />} />
                <Route path="/account-balance" element={token ? <AccountBalance /> : <Login />} />
                <Route path="/amend-account" element={token ? <AmendAccount /> : <Login />} />
                <Route path="/checkout" element={token ? <CheckOut /> : <Login />} />
                <Route path="/order-history" element={token ? <OrderHistory /> : <Login />} />
                <Route path="/favourite-products" element={token ? <FavouriteProducts /> : <Login />} />
                <Route path="/thank-you" element={token ? <ThankYouPage /> : <Login />} />

            </Routes>

        </>
    )
}

export default PublicRoute
