import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import MyAccountSideBar from './MyAccountSideBar';
import pathObj from '../../services/Config';
import { CommonService } from '../../services/common';
import { decryptData, errorHandling } from '../../helpers/common';
import toast from 'react-hot-toast';
import debounce from 'lodash.debounce';

const AmendAccount = () => {
  const userInfo = window.localStorage.getItem('userData');
  const userD = decryptData(userInfo);
  const compData = JSON.parse(userD);
  const customerId = compData?.customer_id;
  const [countries, setCountries] = useState([]);

  const fetchCountries = async () => {
    try {
      const url = `${pathObj.baseURL}${pathObj.getCountries}`;
      const response = await CommonService.getListing(url);
      if (response?.success) {
        setCountries(response?.data); // Assuming response.data contains the list of countries
      } else {
        console.error('Failed to fetch countries:', response?.message);
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const debouncedFetchCountriesData = debounce(fetchCountries, 300); // Adjust the delay as needed

  useEffect(() => {
    debouncedFetchCountriesData();
  }, [customerId]);
  

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const payload = {
        customer_id: customerId,
        ...data // Spread the updated form data
      };
      const url = `${pathObj.baseURL}${pathObj.addAmendAccount}`;
      const response = await CommonService.getAllWithBodyWithToken(url, payload);

      if (response?.success) {
        toast.success(response?.message || 'We have successfully submitted your details');
        reset();
      } else {
        toast.error('Something went wrong', response?.message);
      }
    } catch (error) {
      console.error('Error saving address:', error);
      errorHandling(error)
    }
  };

  return (
    <>
      <div className="cetogry_listing">
        <div className="container">
          <div className="clinner d-flex flex-wrap align-items-start">
            <MyAccountSideBar />
            <div className="common_right_block">
              <div className="contact_details">
                <h1>Amend Account</h1>
                <p>Please fill in the form below with your amended account details to request a change.</p>
                <div className="common_form pt-4">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row gx-6">
                      <div className="col-lg-6">
                        <div className="form_group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Name"
                            {...register('name', { required: 'Name is required' })}
                          />
                          {errors.name && <span style={{ color: '#eb3434' }}>{errors.name.message}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Address"
                            {...register('address', { required: 'Address is required' })}
                          />
                          {errors.address && <span style={{ color: '#eb3434' }}>{errors.address.message}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Town/City"
                            {...register('city', { required: 'Town/City is required' })}
                          />
                          {errors.city && <span style={{ color: '#eb3434' }}>{errors.city.message}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6">
                      <div className="form_group">
                      <select
                         className="form-control form-select"
                        placeholder="Country"
                        {...register('Country', { required: true })}
                      >
                        <option value="">Select a country</option>
                        {countries?.map(country => (
                          <option key={country.Code} value={country.Name}>
                            {country.Name}
                          </option>
                        ))}
                      </select>
                      {errors.Country && <span style={{ color: '#eb3434' }}>Country is required</span>}
                    </div>
                      </div>
                      <div className="col-lg-6">
                      <div className="form_group">
                      <input
                       className="form-control"
                        // className={`form-control ${errors.ZipCode ? 'is-invalid' : ''}`}
                        type="text"
                        placeholder="Post Code"
                        maxLength={10}
                        {...register('postcode', {
                          required: 'Postcode is required',
                          validate: {
                            isValidPostcode: value =>
                              value === '' || /^([A-Z]{1,2}\d[A-Z\d]? \d[A-Z]{2}|GIR 0AA)$/.test(value) || 'Invalid postcode'
                          }
                        })}
                      />
                      {errors.postcode && <span style={{ color: '#eb3434' }}>{errors.postcode.message}</span>}
                    </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Telephone"
                            {...register('telephone', {
                              required: 'Telephone is required',
                              pattern: {
                                value: /^[0-9]{10,}$/,
                                message: 'Invalid telephone number',
                              },
                            })}
                          />
                          {errors.telephone && <span style={{ color: '#eb3434' }}>{errors.telephone.message}</span>}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form_group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Email"
                            {...register('email', {
                              required: 'Email is required',
                              pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                message: 'Invalid email address',
                              },
                            })}
                          />
                          {errors.email && <span style={{ color: '#eb3434' }}>{errors.email.message}</span>}
                        </div>
                      </div>
                    </div>
                    <div className="login_btn">
                      <input className="button2" type="submit" value="Submit" />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AmendAccount;
