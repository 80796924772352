import React from 'react';
import { useForm } from 'react-hook-form';

const TransferAccount = () => {
  const { register, handleSubmit,watch, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    console.log(data);
  };

  return (
      <div className="login_register">
        <div className="container">
          <div className="lrinner d-flex flex-wrap">
            <h1 className="mb-2">Transfer Account</h1>
            <p className="fs-6">
              To transfer your account please, provide your account number and temporary password, along with your email, and a new password.
            </p>
            <div className="common_form pt-4">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row gx-6">
                  <div className="col-lg-6">
                    <div className="form_group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Account Number"
                        {...register('accountNumber', { required: 'Account Number is required' })}
                      />
                      {errors.accountNumber && <span className="error">{errors.accountNumber.message}</span>}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form_group">
                      <input
                        className="form-control"
                        type="email"
                        placeholder="Email Address"
                        {...register('email', { required: 'Email Address is required', pattern: { value: /^\S+@\S+$/i, message: 'Invalid email address' } })}
                      />
                      {errors.email && <span className="error">{errors.email.message}</span>}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form_group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="First Name"
                        {...register('firstName', { required: 'First Name is required' })}
                      />
                      {errors.firstName && <span className="error">{errors.firstName.message}</span>}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form_group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Last Name"
                        {...register('lastName', { required: 'Last Name is required' })}
                      />
                      {errors.lastName && <span className="error">{errors.lastName.message}</span>}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form_group">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Old Password"
                        {...register('oldPassword', { required: 'Old Password is required' })}
                      />
                      {errors.oldPassword && <span className="error">{errors.oldPassword.message}</span>}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form_group">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="New Password"
                        {...register('newPassword', { required: 'New Password is required', minLength: { value: 6, message: 'Password must be at least 6 characters' } })}
                      />
                      {errors.newPassword && <span className="error">{errors.newPassword.message}</span>}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form_group">
                      <input
                        className="form-control"
                        type="password"
                        placeholder="Confirm Password"
                        {...register('confirmPassword', {
                          required: 'Confirm Password is required',
                          validate: value => value === watch('newPassword') || 'Passwords do not match'
                        })}
                      />
                      {errors.confirmPassword && <span className="error">{errors.confirmPassword.message}</span>}
                    </div>
                  </div>
                </div>
                <div className="login_btn">
                  <input className="button2" type="submit" value="Transfer Account" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
  );
};

export default TransferAccount;
