import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import dropin from 'braintree-web-drop-in';
import { Modal, Button } from 'react-bootstrap';
import pathObj from '../../services/Config';
import { CommonService } from '../../services/common';
import toast from 'react-hot-toast';
import getSymbolFromCurrency from 'currency-symbol-map';
import { decryptData } from '../../helpers/common';

const BraintreePaymentModal = ({ show, hide, selectedInvoices, selectedInvoicesAmount, customerId, reset, setSelectedInvoices, setTotalDebit, setSelectedInvoicesAmount, setTotalCredit }) => {
  const [clientToken, setClientToken] = useState(null);
  const [braintreeInstance, setBraintreeInstance] = useState(null);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const dropinContainerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const userInfo = window.localStorage.getItem('userData');
  const userD = decryptData(userInfo);
  const compData = JSON.parse(userD);
  const currencyName = compData?.Currency
  const currencySymbol = getSymbolFromCurrency(currencyName);
  // Fetch Braintree token from the Laravel backend
  useEffect(() => {
    const fetchBraintreeToken = async () => {
      try {
        const token = localStorage.getItem('Token'); // Get token from localStorage if needed
        const response = await axios.get(`${pathObj?.baseURL}${pathObj?.brainTreeToken}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setClientToken(response.data.token);
      } catch (error) {
        console.error('Error fetching Braintree token:', error);
      }
    };
    fetchBraintreeToken();
  }, []);

  // Initialize Braintree Drop-In UI when modal is opened and token is available
  useEffect(() => {
    if (show && clientToken && dropinContainerRef.current) {
      dropin.create({
        authorization: clientToken,
        container: dropinContainerRef.current,
      }, (error, instance) => {
        if (error) {
          console.error('Braintree Drop-In error:', error);
        } else {
          setBraintreeInstance(instance);
        }
      });
    }
  }, [show, clientToken]);

  // Handle Payment Submission
  const handlePayment = async () => {
    setLoading(true);
    if (!braintreeInstance) {
      console.error('Braintree instance not ready.');
      return;
    }

    setPaymentProcessing(true);

    braintreeInstance.requestPaymentMethod(async (error, payload) => {
      if (error) {
        console.error('Error requesting payment method:', error);
        setPaymentProcessing(false);
        return;
      }

      const url = `${pathObj.baseURL}${pathObj.SaveOutstandingInvoices}`;
      const dataPayload = {
        customer_id: customerId,
        amount: selectedInvoicesAmount,
        order_ids: selectedInvoices,
        nonce: payload.nonce,
      };

      try {
        // Send payload to the backend
        const response = await CommonService.getAllWithBodyWithToken(url, dataPayload);
        console.log(response);
        if (response?.success) {
          toast.success(response?.message || 'Your payment is being processed.');
          hide(); // Close the modal on success
          reset();
          setSelectedInvoices([]); // Clear selected invoices
          setTotalDebit(0); // Reset total debit
          setTotalCredit(0); // Reset total credit
          setSelectedInvoicesAmount(0); // Reset selected invoices amount
          setLoading(false);
          setTimeout(() => {
            window.location.reload();
        }, 3000);
         
        } else {
          toast.error(response?.data?.message || 'Payment failed.');
          setLoading(false);
        }
      } catch (error) {
        // toast.error(error?.response?.data?.errors?.[0]?.message || error?.response?.data?.message || 'Something went wrong. Please try again.');

        toast.error(error?.response?.data?.message || 'Something went wrong. Please try again.');

        setLoading(false);
      } finally {
        setPaymentProcessing(false); // End the processing state
        setLoading(false);
      }
    });
  };

  return (
    <>

      <Modal show={show} onHide={hide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Complete Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="form-check custom_check">
          <label className="form-check-label" htmlFor="selectedInvoices">
            Pay Selected invoices: {currencySymbol}
          </label>
          <input
            type="text"
            value={selectedInvoicesAmount.toFixed(2)}
            className="form-control"
            readOnly
          />
        </div> */}
          <span> Pay Selected invoices: <strong>{currencySymbol}{selectedInvoicesAmount}</strong> </span>
          {/* Braintree Drop-In UI Container */}
          <div ref={dropinContainerRef}></div>
          {paymentProcessing && (<div className='loaderOuter'><div className="loader"></div></div>)}
          {/* {paymentProcessing && <p>Processing payment, please wait...</p>} */}

        </Modal.Body>
        <Modal.Footer>
          <div class="btn_row pb-1">
            {/* <a class="button2" onClick={hide}>Close</a> */}
            <a class="button2" onClick={handlePayment}>Proceed To Payment</a>
          </div>
          {/* <Button className='button2' onClick={hide}>Close</Button>
        <Button
          className='button2'
          onClick={handlePayment}
          disabled={!braintreeInstance || paymentProcessing}
        >
          {paymentProcessing ? 'Processing...' : 'Confirm Payment'}
        </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BraintreePaymentModal;
