import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { decryptData } from '../helpers/common';
import pathObj from '../services/Config';
import { CommonService } from '../services/common';
import debounce from 'lodash.debounce';
import { CartContext } from '../context/cartContext';
import toast from 'react-hot-toast';

const AddNewAddress = ({ show, hide,onAddressChange,selectedAddress }) => {
  const userInfo = window.localStorage.getItem('userData');
  const userD = decryptData(userInfo);
  const compData = JSON.parse(userD);
  const customerId = compData?.customer_id;
  const [countries, setCountries] = useState([]);
  const [changeAddress, setChangeAddress] = useState('');
  const { control, register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
  const { deliveredAddress } = useContext(CartContext);


  // Close the modal
  const closeModal = () => {
    hide(); // Call the hide function passed from the parent component
  };

  const fetchCountries = async () => {
    try {
      const url = `${pathObj.baseURL}${pathObj.getCountries}`;
      const response = await CommonService.getListing(url);
      if (response?.success) {
        setCountries(response?.data); // Assuming response.data contains the list of countries
      } else {
        console.error('Failed to fetch countries:', response?.message);
      }
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const debouncedFetchCountriesData = debounce(fetchCountries, 300); // Adjust the delay as needed

  useEffect(() => {
    debouncedFetchCountriesData();
  }, [customerId]);



  const onSubmit = async (data) => {

    if (data) {
      const params = {...data,id:selectedAddress}
      onAddressChange(params);
      toast.success('Change address successfully');
      closeModal(); // Close modal on successful save
    }
  };

  useEffect(() => {
    if (deliveredAddress) {
      // Pre-fill the form fields using setValue from react-hook-form
      setValue('Street', deliveredAddress?.Street || '');
      setValue('StreetNo', deliveredAddress?.StreetNo || '');
      setValue('Block', deliveredAddress?.Block || '');
      setValue('City', deliveredAddress?.City || '');
      setValue('County', deliveredAddress?.County || '');
      setValue('ZipCode', deliveredAddress?.ZipCode || '');
      setValue('Country', deliveredAddress?.Country || '');
    }
  }, [deliveredAddress, setValue]);


  return (
    <>
      <Modal show={show} onHide={closeModal} className='changeAddressModal'>
        <Modal.Body>
          <div className="lrinner">
            <div className="common_form">
              <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-3">
                    <h4>Change Address</h4>
                    <p className="fs-6">Please provide below details</p>
                  </div>
                  <div className="form_group" style={{ display: 'none' }}>
                    <input className="form-control" type="text" placeholder="Address" {...register('Address')} />
                  </div>
                  <div className="form_group">
                    <textarea
                      // className={`form-control ${errors.Street ? 'is-invalid' : ''}`}
                      className="form-control"
                      placeholder="Street*"
                      {...register('Street', { required: true })}
                    />
                    {errors.Street && <span style={{ color: '#eb3434' }}>Street is required</span>}
                  </div>

                  <div className="form_group">
                    <input className="form-control" type="text" placeholder="Street Number" {...register('StreetNo')} />
                  </div>
                  <div className="form_group">
                    <input className="form-control" type="text" placeholder="Block" {...register('Block')} />
                  </div>
                  <div className="form_group">
                    <input className="form-control" type="text" placeholder="Town/City" {...register('City')} />
                  </div>
                  <div className="form_group">
                    <input className="form-control" type="text" placeholder="County" {...register('County')} />
                  </div>
                  <div className="form_group">
                    <input className="form-control" type="text" placeholder="Post Code" {...register('ZipCode')} />
                  </div>
                  {/* <div className="form_group">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Post Code"
                        maxLength={10}
                        {...register('ZipCode')}
                      />
                      {errors.ZipCode && <span style={{ color: '#eb3434' }}>{errors.ZipCode.message}</span>}
                    </div> */}
                  <div className="form_group">
                    <select
                      // className={`form-control form-select ${errors.Country ? 'is-invalid' : ''}`}
                      className="form-control form-select"
                      placeholder="Country"
                      {...register('Country', { required: true })}
                    >
                      <option value="">Select a country*</option>
                      {countries?.map(country => (
                        <option key={country.Code} value={country.Code}>
                          {country.Name}
                        </option>
                      ))}
                    </select>
                    {errors.Country && <span style={{ color: '#eb3434' }}>Country is required</span>}
                  </div>
                  <div className="form_group" style={{ display: 'none' }}>
                    <h6>Address Type</h6>
                    <Controller
                      name="AddressType"
                      control={control}
                      defaultValue="bo_shipTo"
                      render={({ field }) => (
                        <div className="form-check form-check-inline custom_check custom_radio">
                          <input
                            className="form-check-input"
                            type="radio"
                            value="bo_shipTo"
                            {...field}
                            defaultChecked
                          />
                          <label className="form-check-label">Bo Ship To</label>
                        </div>
                      )}
                    />
                  </div>
                <div className='d-flex flex-wrap gap-3'>
                  <button type="submit" className="button2">Confirm</button>
                  <button type="button" onClick={closeModal} className="button1">Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddNewAddress;
