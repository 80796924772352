import React, { useContext, useEffect, useRef, useState } from 'react';
import pathObj from '../../services/Config';
import { checkImage, decryptData, errorHandling, UpdatedDecimalDigits } from '../../helpers/common';
import { CommonService } from '../../services/common';
import { Link, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import { CartContext } from "../../context/cartContext";
import RecentpurchaseItem from './RecentpurchaseItem';
import toast from 'react-hot-toast';
import debounce from 'lodash.debounce';
import QuickSearch from '../Listing/QuickSearch';
import getSymbolFromCurrency from 'currency-symbol-map';
const QuickOrder = () => {
  const userInfo = window.localStorage.getItem('userData');
  const userD = decryptData(userInfo);
  const compData = JSON.parse(userD);
  const customerId = compData?.customer_id;
  const currencyName = compData?.Currency
  const currencySymbol = getSymbolFromCurrency(currencyName);
  const [cartDetails, setCartDetails] = useState([]);
  const [vatData, setVatData] = useState('');
  const [recentPurchasesData, setRecentPurchasesData] = useState([]);
  const category = localStorage.getItem('categoriesName');
  const [quantities, setQuantities] = useState({});
  const [imageStatus, setImageStatus] = useState({});
  const [priceBreakDown, setPriceBreakDown] = useState('');
  const [priceDropdownVisible, setPriceDropdownVisible] = useState({});
  const { removeFromCart } = useContext(CartContext);
  const [wishlistStatus, setWishlistStatus] = useState({});
  const Currency = compData?.Currency;
  const PriceListNum = compData?.PriceListNum;
  const { setCountCart } = useContext(CartContext);
  const [isCartEmpty, setIsCartEmpty] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const handleCheckout = () => {
    const isEmpty = cartDetails?.length === 0 || cartDetails?.every(item => (quantities[item?.ItemCode] || item?.quantity) === 0);

    if (isEmpty) {
      setIsCartEmpty(true);
      toast.error('Your cart is empty. Please add products to your cart before proceeding to checkout.')
    } else {
      navigate('/checkout');
    }
  };

  const fetchCartData = async () => {
    setLoading(true);
    try {
      const customer_id = customerId;
      const urlCart = `${pathObj.baseURL}${pathObj.getCartDetails}`;
      const payloadCart = { customer_id, category, Currency, PriceListNum };

      // Fetch cart details
      const cartData = await CommonService.getAllWithBodyWithToken(urlCart, payloadCart);
      setVatData(cartData?.vat)
      setCartDetails(cartData?.data || []);
      setCountCart(cartData?.count)
      // addToCartCount(cartData?.data)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching page data:", error);
      errorHandling(error);
    }
  };

  const debouncedFetchCartData = debounce(fetchCartData, 300); // Adjust the delay as needed

  useEffect(() => {
    debouncedFetchCartData(customerId);
    // Dependency array includes all variables that should trigger the API call
  }, [customerId]);

  useEffect(() => {
    if (cartDetails.length > 0) {
      const itemIds = cartDetails.map(item => item.id).join(',');
      localStorage.setItem('checkoutItemIds', itemIds);
    }
  }, [cartDetails]);

  const handleRemoveItem = async (itemId, productId) => {
    const confirm = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    });

    if (confirm.isConfirmed) {
      try {
        const url = `${pathObj.baseURL}${pathObj.deleteIndividualItem}?id=${itemId}`;
        // const payload = { itemId, customerId };
        await CommonService.delete(url);
        setCartDetails(prev => prev.filter(item => item.cart_item_id !== itemId));
        removeFromCart(productId);
        // Swal.fire('Deleted!', 'Your item has been deleted.', 'success');
      } catch (error) {
        console.error("Error deleting item:", error);
        Swal.fire('Error!', 'There was an error deleting the item.', 'error');
      }
    }
  };

  const togglePriceDropdown = (itemCode) => {
    if (!priceDropdownVisible[itemCode]) {
      setPriceDropdownVisible((prevVisible) => ({
        ...prevVisible,
        [itemCode]: !prevVisible[itemCode],
      }));
    }
  };

  const handlePriceBreakdownClick = (itemCode, quantity, priceBreakDownId, price) => {
    setPriceBreakDown(priceBreakDownId);
    const item = cartDetails.find(it => it.ItemCode === itemCode);
    handleQuantityChange(item, null, quantity, priceBreakDownId, price);
  };
  
  const handleQuantityChange = async (item, type, customQuantity = null, customPriceBreakdownId = null, breakDownPrice=null) => {
    const itemCode = item?.ItemCode;
    let updatedQuantities;
    let updatedPriceBreakdownId = customPriceBreakdownId || priceBreakDown;

    // Update the quantities in the state
    await setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      let currentQuantity = newQuantities[itemCode] || cartDetails?.find(it => it?.ItemCode === itemCode)?.quantity || 1;
      currentQuantity = parseInt(currentQuantity, 10);

      // Handle custom quantity or increment/decrement logic
      if (customQuantity !== null) {
        currentQuantity = parseInt(customQuantity, 10);
      } else if (type === "plus") {
        currentQuantity += 1;
      } else if (type === "minus" && currentQuantity > 1) {
        currentQuantity -= 1;
      }

      newQuantities[itemCode] = currentQuantity;
      updatedQuantities = newQuantities;

      // Show price dropdown if the quantity is updated manually
      setPriceDropdownVisible((prevVisible) => ({
        ...prevVisible,
        [itemCode]: currentQuantity > 1,
      }));

      return newQuantities;
    });

    const product = cartDetails.find(it => it.ItemCode === itemCode);
    if (product) {
      const quantity = updatedQuantities[itemCode];

      // Find the applicable price breakdown
      const applicableBreakdowns = product.PricesBreakdown.filter(breakdown => quantity >= breakdown.Quantity);
      const priceBreakdown = applicableBreakdowns[applicableBreakdowns.length - 1] || {};

      const priceBDownIdToUse = priceBreakdown?.id || '';
      const priceToUse = priceBreakdown?.SpecialPrice || product?.Price;
      const product_price_id = product?.product_price_id || '';

      const payload = {
        customer_id: customerId,
        product_id: product?.id,
        quantity,
        price_break_down_id: priceBDownIdToUse,
        product_price_id,
        action: 'updateCartQuantity',
        Csprice: breakDownPrice ? breakDownPrice : product?.Csprice > 0 && !priceToUse ? product?.Csprice : priceToUse,
        price: breakDownPrice ? breakDownPrice : product?.Csprice > 0 && !priceToUse ? product?.Csprice : priceToUse,
        product_itemcode: product?.ItemCode,
        customer_cardcode: compData?.CardCode,
        // price: priceToUse,
      };

      setTimeout(async () => {
        try {
        const response = await CommonService.getAllWithBodyWithToken(`${pathObj.baseURL}${pathObj.addCart}`, payload);
        if (response.success) {
          toast.success('Quantity updated successfully');
          setPriceBreakDown(priceBDownIdToUse);
          fetchCartData();
        } else {
          toast.error('Failed to update quantity');
        }
      } catch (error) {
        console.error("Error updating quantity:", error);
      }
    }, 2000);
    }
  };

  const handleProductDetailsClick = (ItemCode) => {
    window.location.href = `/products/${ItemCode}`;
  };

  const handleWishlistToggle = async (productItemCode) => {
    const currentStatus = wishlistStatus[productItemCode] || false; // Get current status of the product
    const url = `${pathObj.baseURL}/saveFavoriteItem`; // Update with your API endpoint
    const payload = {
      customer_id: customerId,
      product_id: productItemCode,
      status: currentStatus ? 0 : 1, // Toggle wishlist status
    };

    try {
      const response = await CommonService.getAllWithBodyWithToken(url, payload);
      if (response.success) {
        // Update the wishlist status for the specific product
        setWishlistStatus((prevStatus) => ({
          ...prevStatus,
          [productItemCode]: !currentStatus, // Toggle the wishlist status
        }));
        toast.success(response?.message || "Wishlist updated successfully!");
        setTimeout(() => {
          fetchCartData();
        }, 300);
      } else {
        toast.error(response.message || "Failed to update wishlist.");
      }
    } catch (error) {
      console.error("Error updating wishlist:", error);
      toast.error("An error occurred while updating the wishlist.");
      errorHandling(error);
    }
  };

  const loadImages = async () => {
    const status = { ...imageStatus }; // Copy the current image status
    for (const product of cartDetails) {
      const pictureCheck = await checkImage(product?.Picture);
      const vectorPictureCheck = await checkImage(product?.vectorPicture);

      // Check if the picture URL works, if not check vectorPicture, if both fail use the no-image.jpg
      if (pictureCheck?.status === "ok") {
        status[product?.ItemCode] = pictureCheck?.path;
      } else if (vectorPictureCheck?.status === "ok") {
        status[product?.ItemCode] = vectorPictureCheck?.path;
      } else {
        status[product?.ItemCode] = `${pathObj?.s3ImagePath}/no-image.jpg`;
      }

      // Update image status for the current product as soon as it's ready
      setImageStatus((prevStatus) => ({ ...prevStatus, [product?.ItemCode]: status[product?.ItemCode] }));
    }
  };


  const debouncedFetchLoadImages = debounce(loadImages, 300); // Adjust the delay as needed
  useEffect(() => {
    if (cartDetails?.length > 0) {
      debouncedFetchLoadImages();
    }
  }, [cartDetails]);



  const subtotal = cartDetails.reduce((acc, item) => acc + ((item.Csprice || item.price) * (quantities[item?.ItemCode] || item.quantity)), 0);
  const vat = subtotal * vatData / 100; // Calculate 20% of subtotal
  const total = subtotal + vat; // Total is subtotal plus VAT


  return (
    <>
      <div className="checkout_wrap">
        <div className="container">
          <div className="clinner">
            <h1>Quick Order</h1>
            <div className="d-flex flex-wrap">
              <div className="quick_cart_left mb-md-5" style={{ position: 'relative', width: '100%' }}>
                <div className="cart_tbl">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="product_thumbnail">Product</th>
                        <th className="product_name">&nbsp;</th>
                        <th className="product_description">Description</th>
                        <th className="product_price">Price</th>
                        <th className="product_quantity text-center">Quantity</th>
                        <th className="product_subtotal text-end">Subtotal</th>
                        <th className="product_remove text-end">&nbsp;</th>
                      </tr>
                    </thead>

                    <tbody>
                      {loading ? (
                        <div className="loader cart_loader"></div>
                      ) : (
                        <>
                          {cartDetails.length > 0 ? (
                            cartDetails.map((item, index) => (
                              <tr key={index}>
                                <td className="product_thumbnail">
                                  {/* {!imageStatus[item?.ItemCode] ? (
                                    <div className="loader"></div> // Show loader until image status is resolved
                                  ) : ( */}
                                  <a href="#">
                                    <img
                                      src={imageStatus[item?.ItemCode] || item?.Picture || item?.vectorPicture || `${pathObj?.s3ImagePath}/no-image.jpg`}
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = `${pathObj?.s3ImagePath}/no-image.jpg`;
                                      }}
                                      alt={item.ItemName} /></a>
                                  {/* )} */}
                                </td>
                                <td className="product_name">
                                  <div className="favorite_icon">
                                    <img
                                      src={`${pathObj?.s3ImagePath}/wishlist-icon${wishlistStatus[item?.id] ? '-red' : ''}.svg`} // Check status from wishlistStatus state
                                      alt="Wishlist Icon"
                                      onClick={() => handleWishlistToggle(item?.id)}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </div>
                                  <h4><a href="#">{item.ItemCode}</a></h4>
                                  <p>{item.ItemName}</p>
                                  {item.AttributesList && Object.keys(item?.AttributesList).map(attrKey => (
                                    <div className="variant" key={attrKey}>
                                      <span>{item?.AttributesList[attrKey].filter_label}:</span> {item?.AttributesList[attrKey].filter_label_data.join(', ')}
                                    </div>
                                  ))}
                                </td>
                                <td className="product_description" data-title="Description">
                                  {item.U_V33_WebDescription || item?.ItemName}
                                  {/* {item.U_V33_WebDescription || "No description available"} */}
                                </td>
                                <td className="product_price" data-title="Price">{currencySymbol}{item?.Csprice > 0 ? item?.Csprice : item?.MinProductPrice ? item?.MinProductPrice : item?.price}</td>
                                <td className="product_quantity text-center" data-title="Quantity">
                                  <div className="cart_qty">
                                    <div className="qty_plusminus" 
                                    onClick={() => togglePriceDropdown(item?.ItemCode)}
                                      style={{ cursor: "pointer" }}>
                                      <button
                                        type="button"
                                        data-type="minus"
                                        onClick={() => handleQuantityChange(item, "minus")}
                                        // disabled={quantities[item?.ItemCode] <= 1}
                                        disabled={item.quantity <= 1}
                                      >
                                        <img
                                          src={`${pathObj?.s3ImagePath}/minus-icon.svg`}
                                          style={{ opacity: item.quantity <= 1 ? 0.5 : 1 }}
                                          alt=""
                                        />
                                      </button>
                                      {/* <input
                                        style={{ width: '35px' }}
                                        type="text"
                                        value={quantities[item?.ItemCode] || item.quantity}
                                        readOnly
                                      /> */}
                                      <input
                                        type="text"
                                        style={{ width: '40px' }}
                                        value={quantities[item?.ItemCode] || item.quantity}
                                        onChange={(e) => handleQuantityChange(item, "input", e.target.value)}
                                        onKeyDown={(e) => {
                                          if (e.key === '-' || e.key === 'e') {
                                            e.preventDefault();
                                          }
                                        }}
                                      />

                                      <button
                                        type="button"
                                        data-type="plus"
                                        onClick={() => handleQuantityChange(item, "plus")}
                                      >
                                        <img
                                          src={`${pathObj?.s3ImagePath}/plus-icon.svg`}
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  </div>
                                  <div className="price_dropdow"
                                    style={{
                                      display:
                                        priceDropdownVisible[item?.ItemCode] &&
                                          item?.PricesBreakdown?.length > 0
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <div className="more_qty">
                                      {item?.PricesBreakdown?.map((priceBreakdown) => {
                                        return (
                                          <div
                                            className="more_qty_row"
                                            key={priceBreakdown.id}
                                            onClick={() => handlePriceBreakdownClick(item?.ItemCode, priceBreakdown?.Quantity, priceBreakdown?.id, priceBreakdown?.SpecialPrice)}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <label>
                                              {priceBreakdown?.Quantity}qty
                                            </label>
                                            <span>
                                              {currencySymbol}{UpdatedDecimalDigits(priceBreakdown?.SpecialPrice)}
                                            </span>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                </td>
                                <td className="product_subtotal text-end" data-title="Subtotal">
                                  {/* {currencySymbol}{(item.Price * quantities[item?.ItemCode] || item.quantity).toFixed(2)} */}
                                  {currencySymbol}{UpdatedDecimalDigits(item.total)}
                                </td>
                                <td className="product_remove text-end" data-title="Remove">
                                  <a href="#" onClick={() => handleRemoveItem(item.cart_item_id, item.id)}>
                                    <img src={`${pathObj?.s3ImagePath}/close-icon2.svg`} alt="remove" />
                                  </a>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7" className="text-center">Your cart is empty</td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <QuickSearch fetchCartData={fetchCartData} />

              <div className="cart_summary">
                <div className="summary_bx">
                  <h3>Summary</h3>
                  <ul>
                    <li>
                      <label>Subtotal:</label>
                      <span>{currencySymbol}{UpdatedDecimalDigits(subtotal)}</span>
                    </li>
                    <li>
                      <label>VAT({vatData})%:</label>
                      <span>{currencySymbol}{UpdatedDecimalDigits(vat)}</span>
                    </li>
                    <li>
                      <label>Total:</label>
                      <span>{currencySymbol}{UpdatedDecimalDigits(total)}</span>
                    </li>
                  </ul>
                  <div className="btn_row pb-1">
                    <button
                      onClick={handleCheckout}
                      className='button2'
                    >
                      Proceed To Checkout
                    </button>
                  </div>
                </div>
              </div>
              <RecentpurchaseItem />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickOrder;
