import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from "react-hot-toast";
import pathObj from '../../services/Config';
import { CommonService } from '../../services/common';
import { useNavigate } from 'react-router-dom';
import { errorHandling } from '../../helpers/common';

const ForgotPassword = () => {
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const onSubmit = async (data) => {
        setLoading(true);
        try {
            const response = await CommonService.getAllWithBody(`${pathObj.baseURL}${pathObj.forgotPassword}`, data);
            toast.success(response?.message || 'We have emailed you password reset link.');
            reset(); // Reset the form on successful submission
            navigate('/login');
        } catch (error) {
            // Handle error appropriately
            toast.error(error?.response?.data?.errors?.[0]?.message || 'Something went wrong. Please try again.' || error?.message);
            setLoading(false);
            errorHandling(error);
        } finally {
            setLoading(false);
        }

    };

    return (
        <div className="login_register">
            <div className="container">
                <div className="lrinner d-flex flex-wrap">
                    <div className="login_form">
                        <h1>Forgot Password</h1>
                        <p>This will reset your account password and email you a new password.</p><br />
                        <div className="common_form">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form_group">
                                    <input
                                        className="form-control"
                                        type="email"
                                        placeholder="Email"
                                        {...register('email', { required: 'Email is required' })}
                                    />
                                    {errors.email && <span style={{ color: '#eb3434' }}>{errors.email.message}</span>}
                                </div>
                                <button className={`button2 ${loading ? 'disabled' : ''}`} type="submit" disabled={loading}>
                                    {loading ? ('Loading...') : ('Submit')}
                                </button>

                            </form>
                        </div>
                        <div className="login_bottom_content pad_t30">
                            <h3>Need help?</h3>
                            <p>If you need further assistance, please contact support.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
